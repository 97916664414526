import * as React from 'react';

import clsx from 'clsx';

import { BrandsSection } from '@app/pages/StaticPages/AboutUs/AboutUs';
import {
	Benefit,
	Benefits,
	benefitsList,
	BenefitWrap,
	INTRO_I,
	INTRO_II,
	OPPORTUNITIES,
} from '@app/pages/StaticPages/Careers/text';

import '@app/scss/pages/documents-pages.scss';

const IntroSection: React.FC = () => (
	<div className="careers__intro-block documentation__section">
		<img src="https://www.onlinevacationcenter.com/img/ovc-building-3.jpg" alt="OVC" />
		<div>
			<p>{INTRO_I}</p>
			<p>{INTRO_II}</p>
		</div>
	</div>
);

const BenefitsSection: React.FC = () => (
	<div className="documentation__section">
		<h2 className="documentation__title text-size_soft-large content-valign_center">
			Our Benefits
		</h2>
		<ul className="careers__benefits-block layout-container">
			{
				benefitsList.map((item: Benefits) => (
					<li
						className={clsx('col-12', item.className)}
						key={item.title}
					>
						<div className="careers__benefits-block__card">
							<div className="careers__benefits-block__card__title">
								<img src={item.icon} alt={item.title} />
								<p className="font-weight_bold text-size_small">{item.title}</p>
							</div>
							<div className="careers__benefits-block__card__content">
								{
									item.benefits.map((wrap: BenefitWrap) => (
										<ul key={wrap.id}>
											{
												wrap.list.map((item: Benefit) => (
													<li key={item.id}>
														{item.value}
													</li>
												))
											}
										</ul>
									))
								}
							</div>
						</div>
					</li>
				))
			}
		</ul>
	</div>
);

const OpportunitiesSection: React.FC = () => (
	<div className="documentation__section">
		<h2 className="documentation__title text-size_soft-large content-valign_center">
			Our Opportunities
		</h2>
		<p>{OPPORTUNITIES}</p>
	</div>
);

export const Careers = () => (
	<div className="documentation careers font-family_primary font-weight_regular text-size_extra-small">
		<h2 className="documentation__title text-size_soft-large content-valign_center">
			Life at Online Vacation Center
		</h2>

		<IntroSection />
		<BenefitsSection />
		<BrandsSection />
		<OpportunitiesSection />
	</div>
);
