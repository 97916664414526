import { v4 } from 'uuid';

import { IListElement, ListElementType } from '@app/components/DropList/DropList';

export const financials: Array<IListElement> = [
	{
		title: 'ONVC Audited Financial Statements & Notes - May 1, 2023',
		text: 'https://www.onlinevacationcenter.com/ir/documents/fy-2022-ovch-financials.pdf',
		id: v4(),
		type: ListElementType.PDF,
	},
	{
		title: 'ONVC Audited Financial Statements & Notes - September 20, 2022',
		text: 'https://www.onlinevacationcenter.com/ir/documents/fy-2021-ovch-financials.pdf',
		id: v4(),
		type: ListElementType.PDF,
	},
	{
		title: 'ONVC Audited Financial Statements & Notes - April 1, 2020',
		text: 'https://www.onlinevacationcenter.com/ir/documents/fy-2019-ovch-financials.pdf',
		id: v4(),
		type: ListElementType.PDF,
	},
	{
		title: 'ONVC Audited Financial Statements & Notes - April 12, 2019',
		text: 'https://www.onlinevacationcenter.com/ir/documents/fy-2018-ovch-financials.pdf',
		id: v4(),
		type: ListElementType.PDF,
	},
	{
		title: 'ONVC Audited Financial Statements & Notes - April 30, 2018',
		text: 'https://www.onlinevacationcenter.com/ir/documents/fy-2017-ovch-financials.pdf',
		id: v4(),
		type: ListElementType.PDF,
	},
	{
		title: 'ONVC Audited Financial Statements & Notes - April 25, 2017',
		text: 'https://www.onlinevacationcenter.com/ir/documents/fy-2016-ovch-financials.pdf',
		id: v4(),
		type: ListElementType.PDF,
	},
	{
		title: 'ONVC Audited Financial Statements & Notes - April 18, 2016',
		text: 'https://www.onlinevacationcenter.com/ir/documents/release_20160418.pdf',
		id: v4(),
		type: ListElementType.PDF,
	},
	{
		title: 'ONVC Audited Financial Statements & Notes - April 29, 2015',
		text: 'https://www.onlinevacationcenter.com/ir/documents/fy-2014-ovch-financials.pdf',
		id: v4(),
		type: ListElementType.PDF,
	},
	{
		title: 'ONVC Audited Financial Statements & Notes - June 20, 2014',
		text: 'https://www.onlinevacationcenter.com/ir/documents/release_062014.pdf',
		id: v4(),
		type: ListElementType.PDF,
	},
];
