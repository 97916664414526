import * as React from 'react';

import clsx from 'clsx';

import { TIcon } from '@app/objects/Utility';
import { Icon as IconFactory } from '@app/image/icon';

import '@app/scss/components/textfield.scss';

export interface TextFieldProps {
	value?: string | number;
	onChange?: (value: string) => void;

	onFocus?: React.FocusEventHandler<HTMLInputElement>;
	onBlur?: React.FocusEventHandler<HTMLInputElement>;

	title?: string; // This is placeholder / caption
	Prefix?: TIcon; // Icon that comes before input
	ClearIcon?: TIcon; // Custom 'clear text' action icon
	allowClear?: boolean; // Whether to show 'clear' icon or not
	disabled?: boolean;
	inputProps?: Record<string, string>;

	className?: string;
	style?: React.CSSProperties;

	text?: string; // This is an additional description text
	error?: boolean; // If true - component is marked as 'with error'
}

interface IconProps {
	Icon?: TIcon;
	active?: boolean;
}

const Icon: React.FC<IconProps> = (props: IconProps) => {
	return (
		<div className="text-field-container__component__icon-wrapper content-valign_center">
			{props.Icon
				? (
					<props.Icon
						className={clsx(
							'text-field-container__component__icon-wrapper__icon',
							props.active && 'text-field-container__component__icon-wrapper__icon_active',
						)}
					/>
				) : null}
		</div>
	);
};

export const TextField: React.FC<TextFieldProps> = (props: TextFieldProps) => {
	const [focused, setFocused] = React.useState<boolean>(() => false);
	const active = Boolean(focused || props.value);

	// This ref is used to focus input when user clicks on component
	const inputRef = React.useRef<HTMLInputElement>(null);

	const changeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
		props.onChange?.(event.currentTarget.value);
	};

	const focusHandler = (event: React.FocusEvent<HTMLInputElement>) => {
		setFocused(true);
		props.onFocus?.(event);
	};

	const blurHandler = (event: React.FocusEvent<HTMLInputElement>) => {
		setFocused(false);
		props.onBlur?.(event);
	};

	return (
		<div
			className={clsx('text-field-container', props.error && 'text-field-container_error')}
		>
			<div
				className={clsx(
					'text-field-container__component font-family_primary font-weight_regular text-size_medium',
					focused && 'text-field-container__component_focused',
				)}
				onClick={() => inputRef.current?.focus()}
			>
				<Icon Icon={props.Prefix} active={active} />
				<div className="text-field-container__component__input-group">
					<input
						value={props.value}
						onChange={changeHandler}

						onFocus={focusHandler}
						onBlur={blurHandler}

						className={clsx(
							'text-field-container__component__input-group__input',
							'font-family_primary font-weight_medium text-size_medium',
							props.className,
						)}
						style={props.style}

						disabled={props.disabled}
						ref={inputRef}
						{...props.inputProps}
					/>
					<label
						className={clsx(
							'text-field-container__component__input-group__placeholder',
							active && 'text-field-container__component__input-group__placeholder_active text-size_extra-small',
							focused && 'text-field-container__component__input-group__placeholder_focused text-size_extra-small',
						)}
					>
						{props.title}
					</label>
					{
						props.value && props.allowClear && props.ClearIcon
							? (
								<div
									className="text-field-container__component__input-group__clear-group"
									onClick={() => props.onChange?.('')}
								>
									<props.ClearIcon className="text-field-container__component__input-group__clear-group__icon" />
								</div>
							) : null
					}
				</div>
			</div>
			<div className="text-field-container__text font-family_primary font-weight_light text-size_extra-small">
				{props.text}
			</div>
		</div>
	);
};

TextField.defaultProps = {
	allowClear: true,
	ClearIcon: IconFactory.Clear,
};
