import * as React from 'react';

import clsx from 'clsx';

import '@app/scss/components/dropList.scss';

export enum ListElementType {
	Link,
	PDF,
}

export interface IListElement {
	title: string;
	text: React.ReactNode;
	id: string;
	type?: ListElementType;
}

interface DropListProps {
	title?: string;
	items: Array<IListElement>;
}

type DropItemProps = Omit<IListElement, 'id'>;
export const DropListItem: React.FC<DropItemProps> = (props: DropItemProps) => {
	const [visible, setVisible] = React.useState<boolean>(false);

	return (
		<li key={props.title} className={clsx('droplist__list-item', visible && 'action')}>
			<div
				onClick={() => {
					setVisible((state: boolean) => !state);
				}}
				className={clsx('droplist__list__title', visible && '_visible')}
			>
				{props.title}
			</div>
			<div className="droplist__text-block">
				{props.text}
			</div>
		</li>
	);
};

export const DropList: React.FC<DropListProps> = ({ title, items }: DropListProps) => (
	<div className="documentation__section droplist">
		{title && <h4 className="documentation__subtitle font-weight_bold">{title}</h4>}
		<ul className="droplist__list">
			{items.map((item: IListElement) => (
				<DropListItem
					key={item.id}
					text={item.text}
					title={item.title}
				/>
			))}
		</ul>
	</div>
);
