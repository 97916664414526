import * as React from 'react';

import { useEffect, useState } from 'react';

import '../../../scss/valueInclusive/valueinclusive.scss';

import { AxiosError, AxiosResponse } from 'axios';

import { httpModules } from '../../../helpers/http.module';

import { ClientFeedback } from './Slider/ClientFeedback';

import { SpecialOffers } from './Slider/SpecialOffers';

import { PersonalManager } from './Slider/PersonalManager';

import { TopPackges } from './Slider/TopPackges';

import { FeaturedDeatination } from './Slider/FeaturedDestination';

import { Subscribe } from '@app/pages/Forms/Subscribe';

export interface ITopPackages {
	specialsID: number;
	totalBookings: number;
	product: string;
	saildate: number;
	ship: string;
	supplierLogo: string;
	vipDealsCount: string;
	countryCityEmb: string;
	countryCityDeb: string;
	imagePath: string;
	imageName: string;
	image: string;
}

interface IValueInclusiveInfo {
	valueInclusives: Array<ITopPackages>;
}

export const ValueInclusive: React.FC = () => {
	const [topPackages, settopPackages] = useState<Array<ITopPackages>>([]);

	useEffect(() => {
		httpModules
			.get<IValueInclusiveInfo>('api/pages/v1/ValueInclusive')
			.then((response: AxiosResponse<IValueInclusiveInfo>) => {
				const merged: IValueInclusiveInfo = {
					valueInclusives: response.data.valueInclusives,
				};
				settopPackages(merged.valueInclusives);
			})
			.catch((error: AxiosError) => {
				console.log(error);
			});
	}, []);

	return (
		<div>
			<TopPackges TopPackages={topPackages} />
			<FeaturedDeatination />
			<SpecialOffers />
			<ClientFeedback />
			<PersonalManager />
			<Subscribe />
		</div>
	);
};
