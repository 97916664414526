/* eslint-disable max-len */
import * as React from 'react';
import { v4 } from 'uuid';

import { IListElement } from '@app/components/DropList/DropList';

const EDWARD_RUDNER = (
	<>
		<p>
			Edward B. Rudner is a Director of Online Vacation Center Holdings Corp. and also serves as its Chairman and CEO.
		</p>
		<p>
			Mr. Rudner has more than 30 years of experience in the travel industry. Previously, he served as the CFO and COO of Alamo-Rent-A-Car. Under his guidance, Alamo-Rent-A-Car increased its fleet size from 400 to 50,000 automobiles, and expanded from a Florida-based company into a national car rental agency. Following his tenure at Alamo, Mr. Rudner went on to become president and CEO of Certified Vacations, where he cultivated the business from 10,000 to 250,000 vacation packages per year. More recently, he served as the CEO of Renaissance Cruises where he expanded ship assets from $60 million to over $1 billion and increased revenues from $20 million to $500 million over a nine-year period until June of 2000.
		</p>
		<p>
			In October of 2000, Mr. Rudner founded Online Vacation Center Inc., which today is one of the largest cruise sellers in the United States.
		</p>
		<p>
			Mr. Rudner graduated cum laude with a bachelor of arts degree in history from the University of Massachusetts and currently resides in Fort Lauderdale, Florida.
		</p>
	</>
);
const STEPHEN_RUDNER = 'Stephen A. Rudner is a Director of Online Vacation Center Holdings Corp. and also serves as the Company\'s President. Mr. Rudner has previously served as the Chief Financial Officer, Director of Information Technology, and Director of Sales of Online Vacation Center, Inc. Mr. Rudner joined the Company in November of 2011 as the Manager of Accounting. Prior to joining Online Vacation Center, Mr. Rudner worked as a senior auditor for Deloitte & Touche LLP. Mr. Rudner holds a Master of Science degree in Accounting from the University of Florida and is a Certified Public Accountant in the state of Florida.';
const DANIEL_RUDNER = 'Daniel J. Rudner is President for the OVC franchise of Expedia Cruises™. Daniel is a veteran in the travel industry and has worked for various cruise lines and tour operators. Daniel holds a bachelor’s degree in Small Business Management and Entrepreneurship from Florida Atlantic University.';
const KURT_HOMFELT = 'R. Kurt Homfelt, President of Dunhill Vacations, Inc. joined Online Vacation Center Holdings Corp. from Miami, Florida based Club Med, most recently serving as its Director of National Sales. During his seven year tenure with the company, Mr. Homfelt was responsible for the creation and implementation of multiple nationwide sales and marketing initiatives as well as the development of their E-commerce platform. Mr. Homfelt also served as the Director of Travel and Entertainment Sales for First Data Corporation after spending 13 years at Certified Vacations, during which time he occupied a number of senior sales positions. Mr. Homfelt has a BS in Business Logistics from Pennsylvania State University.';
export const management: Array<IListElement> = [
	{
		title: 'Edward B. Rudner, Director, Chairman, and CEO of Online Vacation Center Holdings Corp.',
		text: EDWARD_RUDNER,
		id: v4(),
	},
	{
		title: 'Stephen A. Rudner, Director and President of Online Vacation Center Holdings Corp.',
		text: STEPHEN_RUDNER,
		id: v4(),
	},
	{
		title: 'Daniel J. Rudner – Director & President of Expedia Cruises, OVC',
		text: DANIEL_RUDNER,
		id: v4(),
	},
	{
		title: 'R. Kurt Homfelt, President of Dunhill Vacations',
		text: KURT_HOMFELT,
		id: v4(),
	},
];
