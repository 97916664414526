/* eslint-disable max-len */
import * as React from 'react';

import { LinkType, link } from '@app/pages/StaticPages/utils';
import { formatPhone } from '@app/services/phone';

const getPhoneLink = (tel: string) => (<a href={`tel:${tel}`}>{formatPhone(tel)}</a>);

const privacyEmail = link('privacy@onlinevacationcenter.com', LinkType.Mail);

export const INTRO = (
	<p>
		<div>
			Online Vacation Center (OVC) believes that privacy is the foundation of any successful business relationship,
			both on and offline. OVC only processes data to the extent it is necessary to run its business and to provide
			its goods and services to you. This Privacy Policy outlines what personal data we collect about you, how that
			data is collected, why it is collected, for what purposes it is used, and how it is stored.
			This policy governs the data processed through OVC or any of its affiliates, subsidiaries, agents, or assigns.
			Should you ever wish to know what data is collected about you or wish to have any of your personal data deleted,
			please send an email to {privacyEmail}.
		</div>
		<div>
			To be removed from our e-mail subscription, simply click on the link included in any promotional e-mail while
			connected to the Internet, or send an e-mail to {privacyEmail} and indicate that you no longer wish to receive
			an OVC subscription email.
		</div>
	</p>
);
const COLLECT_INFO = (
	<>
		<div>
			Online Vacation Center may request, collect, and safely store the following personal data you have
			provided to us only for the purposes of delivering our products and services to you:
		</div>

		<ul className="text-margin">
			<li>Name</li>
			<li>Address</li>
			<li>Telephone number</li>
			<li>Date of Birth</li>
			<li>Email Address</li>
			<li>Billing/Payment information</li>
		</ul>

		<div>
			Please note that this information (the <span className="font-weight_bold">"Personal Data"</span>)
			is only collected to the extent you provide it to OVC in order to benefit from and receive goods and
			services from OVC. By agreeing to this Privacy Policy and the Terms and Conditions and providing your
			Personal Data at the time of requesting the goods and services from OVC, you hereby acknowledge and agree
			that OVC is obtaining this Personal Data for the specific purposes set out herein.
		</div>

		<p className="text-margin">
			Outside of Personal Data that you have specifically provided to OVC, OVC may, from time to time, collect
			additional data from your access to its websites or your use and interaction with its emails
			(the <span className="font-weight_bold">"Usage Data"</span>). By using the websites and subscribing to the
			emails, you hereby acknowledge, agree, and consent to OVC'S processing of the following information, which
			could be used to directly or indirectly identify you.
		</p>
	</>
);
const TECHNOLOGY = `
Online Vacation Center logs IP addresses (a unique number assigned to every computer on the Internet) in order to 
improve security, analyze trends, and efficiently administer the Site to provide its customers with the best experience 
possible. We may track your movement within the Site, the pages from which you were referred, access times, and browser
types. We only use this information in aggregate and do not link it to you personally unless you explicitly consent to 
our email subscription service (the"Subscription") and become a subscriber. To gauge the effectiveness of our Site, we 
do collect some generic information about our visitors. Our web servers automatically recognize a visitor's domain 
name, the web page from which a visitor enters our Site, which pages a visitor visits on our Site, and how much time a 
visitor spends on each page. We may use anonymous tracking technologies to collect, store, and sometimes track 
information for statistical purposes and to improve the products and services we provide. We aggregate this information
and use it to evaluate and improve our Site. We store information that we collect through cookies, log files, clear 
gifs, and/or third party sources, for instance, to verify your address or update your information and to create a 
"profile" of your preferences. For our customers and subscribers, we tie your personally identifiable information that
you voluntarily disclose to us to information in the profile, in order to provide tailored promotions and marketing 
offers and to improve the content of the Site for you.
`;
const COOKIES = `
A cookie is a small text file that is stored on a user's computer for record-keeping purposes.
We use cookies on this Site. We use session ID cookies and persistent cookies. We use session cookies to make it easier
for you to navigate our Site. A session ID cookie expires when you close your browser. A persistent cookie remains on
your hard drive for an extended period of time. You can remove persistent cookies by following directions provided in
your Internet browser's "help" file. Persistent cookies are used to differentiate subscribers from non-subscribers to
customize the content of certain sections of the website and enable us to track and target the interests of our users
to enhance the experience on our Site. If you reject cookies, you may still use our Site, but your ability to use some
areas of our Site, such as promotions or surveys, may be limited. Some of our business partners
(e.g., vendors or advertisers) use cookies on our site. We have no access to or control over these cookies. 
This privacy statement covers the use of cookies by this Site only and does not cover the use of cookies
by any advertisers.
`;
const WEB_BEACONS = `
Web beacons are single pixel gifs. We use web beacons on our Site and in our Subscription emails. When we send emails to
subscribers, we may track information such as who opened the emails and who clicked the links. This allows us to measure
the performance of our email campaigns and to improve our features for specific segments of subscribers. Web beacons 
allow us to collect information about when you open the email, your IP address, your browser or email client type, and 
other similar details.
`;
const SWEEPSTAKES = `
From time to time we may provide you the opportunity to participate in sweepstakes or other promotions on our Site,
which might be sponsored or conducted by a third-party and if so, such sponsorship will be disclosed to you. If you 
participate, we will request certain personally identifiable information from you. Participation in these sweepstakes 
is completely voluntary and you therefore have a choice whether or not to disclose this information. If you voluntarily
disclose any personal information to participate, you are doing so with explicit consent to the contract governing such 
sweepstakes or promotions and the Terms and Conditions of this Site. We may share personal information with certain 
sponsors and ad partners that you have explicitly requested to receive information from. It will be clear at the point
of collection who is collecting the personal information and whose privacy statement will apply. By providing 
information for these purposes, you are consenting to the privacy statement of the third party disclosed to you.
`;
const SHARE = `
We may from time to time conduct a referral service to introduce people you know to our Site and services. If you choose
to use our referral service to tell a friend about our Site, we will ask you for your friend's name and e-mail address.
We will automatically send your friend a one-time e-mail inviting him or her to visit the Site and your friend may 
choose to opt-in to our Subscription or may ignore the email. If your friend ignores the email, they will not receive 
any more Subscription emails and their email address and name will be deleted from OVC's database. OVC anonymously 
stores this information for the purpose of tracking the success of our referral program.
`;
const SURVEYS = `
We may from time to time provide a survey for our subscribers to fill out providing us with personal demographic 
information to us. We encourage you to submit this information, so we can provide you a more personalized experience on 
our Site, but participation is entirely voluntary and the information provided is done so only with your consent and 
subject to our Terms and Conditions and this Privacy Policy.
`;
const EXTERNAL_LINKS = `
We encourage you to be aware of third-party links on our Site and when you leave our Site. Once you have clicked out of 
the OVC Site, the OVC Privacy Policy no longer applies. We encourage you to read the privacy statements of each and 
every website that you visit or provide personal information to. This privacy statement applies only to information 
collected by our Site.
`;
const PERSONAL_INFO = (
	<>
		<p>OVC'S Business Purposes</p>
		<p>
			OVC uses your Personal Data and your Usage Data in order to provide you with the goods and services you
			requested and/or purchased from OVC.
		</p>
		<p>Vendors</p>
		<p>
			OVC employs other companies and individuals to perform functions on our behalf, such as providing the
			goods and services requested. Specific examples might include fulfilling orders, sending subscription
			e-mails, updating the customer lists, analyzing data, providing marketing assistance, providing search
			results and links, and providing customer service. They have access to personal information needed to
			perform their functions, but may not use it for other purposes.
		</p>
	</>
);
const SUBSCRIPTION = (
	<p>
		If you wish to subscribe to our promotional email newsletter, please provide your name, zip code,
		and e-mail address. We will use this information to send the promotional offers that we think you will
		find valuable. If you would like to change your subscription status or unsubscribe, you may do so using the
		following link, {privacyEmail} or by emailing {privacyEmail}.
	</p>
);
const EXTERNAL_OFFERS = `
We share personally identifiable information with third parties in order to have them send you special offers and 
promotions that we think you might find of interest. We will also occasionally send you information on products, 
services, special deals, or promotions which we have found that are of interest to our readers. If you do not want 
Online Vacation Center to use the information you provide in order to inform you of products and services that might 
be of interest to you or to provide your information to third-party companies, you can make your preference known by 
emailing privacy@onlinevacationcenter.com. Your consent to this Privacy Policy followed by your submission of such 
information represents your agreement to that transfer. OVC will take all steps reasonably necessary to ensure that 
your data is treated securely and in accordance with this Privacy Policy and no transfer or your Personal Data will 
take place to an organization or a county unless there are adequate controls in place including the security of your 
data and other personal information.
`;
const CIRCUMSTANCES = `
If Online Vacation Center or any of its lines of businesses or affiliated companies, is sold or disposed of as a going 
concern, whether by merger, sale of assets or otherwise, the relevant customer database of Online Vacation Center 
Holdings, Inc. could, in whole or in part, be sold as part of that transaction. In the event Online Vacation Center 
goes through a business transition, such as a merger, acquisition by another company or sale of all or a portion of its 
assets, your personally identifiable information will likely be among the assets transferred. We also may have to 
release personally identifiable information when it is necessary to comply with judicial or other government subpoenas, 
warrants, orders, to enforce our Terms of Use, or to protect the rights, property, or safety of Online Vacation Center 
or others. This includes exchanging information with other companies and organizations for fraud protection.
`;
const CALIFORNIA_PRIVACY = (
	<p>
		If you are a California resident, California law may provide you with additional rights regarding our use of
		your personal information. To learn more about your California privacy rights, visit {link('https://www.onlinevacationcenter.com/CCPA.cfm', LinkType.External, 'Privacy Notice for California Residents')}.
		California's "Shine the Light" law (Civil Code Section § 1798.83) permits users of our website that are
		California residents to request certain information regarding our disclosure of personal information to third
		parties for their direct marketing purposes. To make such a request, please send an email to {privacyEmail}.
	</p>
);
const LEGAL_BASIS = `
OVC processes your Personal Data and the Usage Data in order to provide its goods and services to you. The information 
that you have explicitly provided to OVC is the minimum amount of information necessary in order for OVC to provide its 
goods and services to you under the Terms and Conditions, which is the contract governing your use of the Site and your 
purchases from OVC, the sale transaction, and OVC'S legitimate business purposes. Such information has been provided to 
OVC by you and has been given with your explicit consent. OVC only collects what is necessary in order to run its 
business and provide its customers with their requested goods and services. By agreeing to this Privacy Policy and the 
Terms and Conditions and providing your Personal Data at the time of requesting the goods and services from OVC, you 
hereby acknowledge and agree that OVC is obtaining this your Personal Data and the Usage Data for the specific purposes 
set out herein.
`;
const CHANGES = `
We reserve the right to modify, add or remove portions of this Privacy Policy at any time. The latest version of this 
Privacy Policy is contained on this Site and indicates the date that it went into effect. You are encouraged to review 
this privacy policy whenever you use this Site to make sure you always understand how your information is used. If 
changes are made to this privacy policy, those changes will be posted prominently on this website and other places we 
deem appropriate so that you are aware of what information we collect, how we use it, and under what circumstances, if 
any, we disclose it. A user is bound by any changes to the policy when she or he uses the site after those changes have 
been posted, explicitly consents to the Privacy Policy, or discloses her or his information to the Site subject to the 
Privacy Policy and Terms and Conditions.
`;
const UPDATE_ACCESS_FORGOTTEN = (
	<p>
		OVC aims to take reasonable steps to allow you to correct, amend, delete, or limit the use of your
		Personal Data. Whenever made possible, you can update your Personal Data by emailing { privacyEmail}.
		Should you ever wish to know what data is collected about you or wish to have any of your Personal Data deleted,
		please send an email to { privacyEmail} and OVC may provide this information to you. To be removed from our
		e-mail subscription, simply click on the link included in any promotional e-mail while connected to the Internet,
		or send an e-mail to { privacyEmail} and indicate that you no longer wish to receive an OVC subscription email.
	</p>
);
const PROTECTS = `
We have security procedures in the storage and disclosure of personal information, which you have given us in order to 
prevent unauthorized access. Our Site may also contain links to other Web Sites. Keep in mind that Online Vacation Center 
does not control and is not responsible for the privacy or security practices or the content of these or other Web Sites.
`;
const CONTACTS = (
	<>
		<ul className="contact-list">
			<li>Online Vacation Center</li>
			<li>2307 W Broward Blvd, Suite 400</li>
			<li>Fort Lauderdale, FL 33312-1417</li>
			<li>{getPhoneLink('8007809002')}</li>
		</ul>

		<p style={{ marginTop: 10 }}>E-mail: {privacyEmail}</p>
	</>
);

export const sections = [
	{
		title: 'INFORMATION WE COLLECT',
		description: COLLECT_INFO,
	},
	{
		title: 'Tracking Technology',
		description: TECHNOLOGY,
	},
	{
		title: 'Cookies',
		description: COOKIES,
	},
	{
		title: 'Web Beacons',
		description: WEB_BEACONS,
	},
	{
		title: 'Sweepstakes or Promotions',
		description: SWEEPSTAKES,
	},
	{
		title: 'Tell-A-Friend & Forward-To-A-Friend',
		description: SHARE,
	},
	{
		title: 'Surveys',
		description: SURVEYS,
	},
	{
		title: 'Third-Party Links',
		description: EXTERNAL_LINKS,
	},
	{
		title: 'USE OF PERSONAL INFORMATION',
		description: PERSONAL_INFO,
	},
	{
		title: 'Email Newsletter (Subscription)',
		description: SUBSCRIPTION,
	},
	{
		title: 'Third-Party Offers',
		description: EXTERNAL_OFFERS,
	},
	{
		title: 'Exceptional Circumstances',
		description: CIRCUMSTANCES,
	},
	{
		title: 'Your California Privacy Rights',
		description: CALIFORNIA_PRIVACY,
	},
	{
		title: 'LEGAL BASIS',
		description: LEGAL_BASIS,
	},
	{
		title: 'CHANGES TO OUR POLICY',
		description: CHANGES,
	},
	{
		title: 'RIGHT TO UPDATE, ACCESS, AND BE FORGOTTEN',
		description: UPDATE_ACCESS_FORGOTTEN,
	},
	{
		title: 'HOW ONLINE VACATION CENTER PROTECTS YOUR INFORMATION',
		description: PROTECTS,
	},
	{
		title: 'CONTACT US',
		description: CONTACTS,
	},
];
