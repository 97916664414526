import * as React from 'react';

import clsx from 'clsx';

import '@app/scss/components/expandableList.scss';

interface ExpandableListItemProps {
	key: number | string;
	content: React.ReactNode;
	className?: string;
}

interface ExpandableListProps {
	/**
	 * items - A complete set of items in list
	 */
	items: Array<ExpandableListItemProps>;
	/**
	 * count - how many items to display (items beyond count are truncated with '...')
	 */
	count?: number;
	className?: string;
}

const ExpandableListItem: React.FC<ExpandableListItemProps> = (props: ExpandableListItemProps) => (
	<li key={props.key} className={props.className}>
		<span>{props.content}</span>
	</li>
);

export const ExpandableList: React.FC<ExpandableListProps> = (props: ExpandableListProps) => {
	const [isShowFullList, setIsShowFullList] = React.useState<boolean>(false);
	const exceeds = props.items.length > props.count!;

	return (
		<div
			className={clsx('mobile-margin expandable-list__container', props.className)}
			onClick={() => setIsShowFullList(true)}
		>
			<ul className="expandable-list">
				{
					props.items.slice(0, props.count!).map((item: ExpandableListItemProps) => (
						<ExpandableListItem
							key={item.key}
							content={item.content}
						/>
					))
				}
				{
					exceeds && !isShowFullList
						? (
							<ExpandableListItem
								key="ellipsis"
								content="..."
								className="ellipsis"
							/>
						)
						: null
				}
				{
					exceeds && isShowFullList
						? (
							props.items.slice(props.count!).map((item: ExpandableListItemProps) => (
								<ExpandableListItem
									key={item.key}
									content={item.content}
								/>
							))
						)
						: null
				}
			</ul>
		</div>
	);
};

ExpandableList.defaultProps = {
	count: 3,
};
