import * as React from 'react';

import { Checkbox } from '@app/components/Checkbox/Checkbox';

import '@app/scss/components/selectableList.scss';

export type SelectableListValueType = number | string;

export interface SelectableListItemProps<TValue extends SelectableListValueType> {
	value: TValue;
	label: string;

	list?: Array<SelectableListItemProps<TValue>>;
}

export interface SelectableListProps<TValue extends SelectableListValueType> {
	value: Array<TValue>;
	onChange: (value: Array<TValue>) => void;

	list?: Array<SelectableListItemProps<TValue>>;
}

function isChecked<TValue extends SelectableListValueType>(list: Array<SelectableListItemProps<TValue>>, values: Array<TValue>): boolean {
	const ids = list.map((item: SelectableListItemProps<TValue>) => item.value);
	const children = values.filter((value: TValue) => ids.includes(value));

	return ids.length === children.length;
}

function isIndeterminated<TValue extends SelectableListValueType>(list: Array<SelectableListItemProps<TValue>>, values: Array<TValue>): boolean {
	const ids = list.map((item: SelectableListItemProps<TValue>) => item.value);
	const children = values.filter((value: TValue) => ids.includes(value));

	return children.length > 0 && children.length < ids.length;
}

export const SelectableList = <TValue extends SelectableListValueType>(props: SelectableListProps<TValue>) => {
	if (!props.list) return null;

	return (
		<ul className="selectable-list">
			{
				props.list.map((item: SelectableListItemProps<TValue>) => {
					const isParent = Boolean(item.list?.length);

					return (
						<li
							key={item.value}
							className="selectable-list__item"
						>
							{
								isParent ? (
									<>
										<Checkbox
											label={item.label}
											checked={isChecked(item.list!, props.value)}
											indeterminate={isIndeterminated(item.list!, props.value)}
											onChange={(checked: boolean) => {
												if (checked) {
													const set = new Set<TValue>(props.value);
													item.list!.forEach((item: SelectableListItemProps<TValue>) => set.add(item.value));

													props.onChange(Array.from(set));
												} else {
													const ids = item.list!.map((item: SelectableListItemProps<TValue>) => item.value);
													props.onChange(props.value.filter((value: TValue) => !ids.includes(value)));
												}
											}}
										/>
										<SelectableList
											value={props.value}
											onChange={props.onChange}
											list={item.list}
										/>
									</>
								) : (
									<Checkbox
										label={item.label}
										checked={props.value.includes(item.value)}
										onChange={
											(value: boolean) =>
												props.onChange(value
													? [...props.value, item.value]
													: props.value.filter((q: TValue) => q !== item.value))
										}
									/>
								)
							}
						</li>
					);
				})
			}
		</ul>
	);
};
