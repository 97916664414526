export type OdesseusFilterValue = number | string;

export enum OdesseusFilterKeys {
	DESTINATIONTYPE = 'destinationType',
	SIDD = 'sidd',
	SEARCHCRUISE= 'searchcruise',
	LANG = 'lang',
	PAGE = 'page',

	// Cruises
	DESTINATION = 'destinationId',
	DEPARTUREPORT = 'departurePortCode',
	CRUISELINE = 'cruiselineId',
	SHIP = 'shipId',
	DEPARTUREDATE = 'departureDateTime',
	DURATION = 'duration',
}

export function isOdesseusFilterKey(key: unknown | OdesseusFilterKeys): key is OdesseusFilterKeys {
	if (typeof key !== 'string') return false;

	return (Object.values(OdesseusFilterKeys) as Array<string>).includes(key);
}
