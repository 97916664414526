import * as React from 'react';

import clsx from 'clsx';

import { ActionMode, Button, ButtonVariant } from '@app/components/Button/Button';
import { cardsLeft, cardsRight } from '@app/pages/MainPage/CruiseLine/objects';

import '@app/scss/pages/main/cruiseLine.scss';

export interface CardItem {
	image: string;
	background?: string;
	className?: string;
	link?: string;

	children?: Array<CardItem>;
}

/**
 * Get a tree of cards components
 * @param list - current tree slice
 * @param maxDepth - maximal depth of tree traversal
 * @param curDepth - current depth of traversal
 */
const getCard = (list: Array<CardItem> | null, maxDepth: number = 5, curDepth: number = 0): React.ReactNode => {
	if (!list) return null;
	if (!list.length) return null;

	return (
		<>
			{
				list.map((card: CardItem) => {
					const children = Boolean(maxDepth > curDepth && card.children && card.children.length);
					const contents = children ? getCard(card.children!, maxDepth, curDepth + 1) : null;

					return card.link ? (
						<a
							href={card.link}
							target="_blank"
							rel="noreferrer"
							className={clsx('cruise-line__item', card.className)}
							style={{
								backgroundColor: card.background,
								backgroundImage: `url(${card.image})`,
								backgroundSize: card.background ? '' : 'cover',
							}}
							key={card.image}
						>
							{contents}
						</a>
					) : (
						<div
							className={clsx('cruise-line__item', card.className)}
							style={{
								backgroundColor: card.background,
								backgroundImage: `url(${card.image})`,
								backgroundSize: card.background ? '' : 'cover',
							}}
							key={card.image}
						>
							{contents}
						</div>
					);
				})
			}
		</>
	);
};

export const CruiseLine = () => {
	return (
		<div className="cruise-line cruise-line__container">
			<div className="cruise-line__column">
				<div className="cruise-line__item cruise-line__title-block">
					<div className="cruise-line__title font-family_primary condensed font-weight_semi-bold text-size_hard-large">
						<b>Shop by</b>
						<i className="font-family_decorative font-weight_regular"> Cruise&nbsp;Line</i>
					</div>
					<Button
						variant={ButtonVariant.Filled}
						action={ActionMode.ExternalLink}
						link="https://res.onlinevacationcenter.com/swift/cruise/cruiseline?siid=228902&lang=1"
					>
						View All Cruise Lines
					</Button>
				</div>
				{getCard(cardsLeft)}
			</div>
			<div className="cruise-line__column">
				{getCard(cardsRight)}
			</div>
		</div>
	);
};
