import * as React from 'react';

import moment from 'moment/moment';

import { Facet } from '@app/odesseus/types/Facet';
import {
	OdesseusFilterKeys,
} from '@app/odesseus/types/Filters';
import { Destination } from '@app/odesseus/types/Destination';
import { Port } from '@app/odesseus/types/Port';
import { Ship } from '@app/odesseus/types/Ship';
import { CruiseLine } from '@app/odesseus/types/CruiseLine';
import { Duration } from '@app/odesseus/types/Duration';
import { OdyManager } from '@app/odesseus/OdyManager';
import { Nullable } from '@app/objects/Utility';
import { Odesseus } from '@app/odesseus/useOdesseus';
import { Range } from '@app/objects/Filters/Range';
import { OdesseusSelectOption, OdesseusSelectOptionGroup } from '@app/odesseus/types/Filters/Odesseus';

interface IWithValue {
	value: string;
}

interface ServerDates {
	from: string;
	to?: string;
}

interface Context {
	lastChange: Nullable<OdesseusFilterKeys>;
	raw: Odesseus;

	setDestinations: React.Dispatch<React.SetStateAction<Array<OdesseusSelectOption>>>;
	setPorts: React.Dispatch<React.SetStateAction<Array<OdesseusSelectOption>>>;
	setCruiselines: React.Dispatch<React.SetStateAction<Array<OdesseusSelectOption>>>;
	setShips: React.Dispatch<React.SetStateAction<Array<OdesseusSelectOptionGroup>>>;
	setDates: React.Dispatch<React.SetStateAction<Array<OdesseusSelectOption<Range<number>>>>>;
	setDurations: React.Dispatch<React.SetStateAction<Array<OdesseusSelectOption<Range<number>>>>>;
}

// Limit received filter object to Range<number> fields only
function getRange(range: Range<number>): Range<number> {
	return {
		from: range.from,
		to: range.to,
	};
}

function sorted(list: Array<OdesseusSelectOptionGroup>) {
	return list.sort((a, b) => {
		if (a.name > b.name) return 1;

		return -1;
	});
}

function durationMessage(item: Duration): string {
	if (item.to === undefined) return `${item.from} and more nights`;

	return `${item.from} to ${item.to} nights`;
}

export class FacetChain {
	private readonly context: Context;

	public constructor(context: Context) {
		this.context = context;
	}

	public static spawn(context: Context): FacetChain {
		return new FacetChain(context);
	}

	public pass(facet: Facet): void {
		if (facet.key === this.context.lastChange) return;

		this.passDestination(facet);
		this.passPort(facet);
		this.passCruiseline(facet);
		this.passShip(facet);
		this.passDates(facet);
		this.passDuration(facet);
	}

	private passDestination(facet: Facet): void {
		if (facet.key !== OdesseusFilterKeys.DESTINATION) return;

		const ids = (facet.values as unknown as Array<IWithValue>)
			.map((q: IWithValue) => Number(q.value))
			.filter((q: number) => !Number.isNaN(q));
		const list: Array<OdesseusSelectOption> = this.context.raw.destinations
			.filter((item: Destination) => ids.includes(item.id))
			.map((item: Destination) => ({ value: item.id, name: item.name }));
		this.context.setDestinations(list);
	}

	private passPort(facet: Facet): void {
		if (facet.key !== OdesseusFilterKeys.DEPARTUREPORT) return;

		const codes = (facet.values as Array<unknown> as Array<IWithValue>)
			.map((item: IWithValue) => item.value);
		const list: Array<OdesseusSelectOption> = this.context.raw.ports
			.filter((item: Port) => codes.includes(item.code))
			.map((item: Port) => ({ value: item.code, name: item.name }));
		this.context.setPorts(list);
	}

	private passShip(facet: Facet): void {
		if (facet.key !== OdesseusFilterKeys.SHIP) return;

		const ids = (facet.values as unknown as Array<IWithValue>)
			.map((q: IWithValue) => Number(q.value))
			.filter((q: number) => !Number.isNaN(q));
		const groupList: Array<OdesseusSelectOptionGroup> = [];
		this.context.raw.ships
			.filter((item: Ship) => ids.includes(item.id))
			.map((item: Ship) => ({ parentId: Number(item.parentId), value: item.id, name: item.name }))
			.forEach((item) => {
				const parent = this.context.raw.cruiselines.find((q: CruiseLine) => q.id === item.parentId);
				if (!parent) return;

				let group = groupList.find((elem: OdesseusSelectOptionGroup) => elem.name === parent?.name);
				if (group) {
					group.options.push(item);
				} else {
					group = {
						name: parent.name,
						options: [item],
					};
					groupList.push(group);
				}
			});
		this.context.setShips(sorted(groupList));
	}

	private passCruiseline(facet: Facet): void {
		if (facet.key !== OdesseusFilterKeys.CRUISELINE) return;

		const ids = (facet.values as unknown as Array<IWithValue>)
			.map((q: IWithValue) => Number(q.value))
			.filter((q: number) => !Number.isNaN(q));
		const list: Array<OdesseusSelectOption> = this.context.raw.cruiselines
			.filter((item: CruiseLine) => ids.includes(item.id))
			.map((item: CruiseLine) => ({ value: item.id, name: item.name }));
		this.context.setCruiselines(list);
	}

	private passDuration(facet: Facet): void {
		if (facet.key !== OdesseusFilterKeys.DURATION) return;

		const raw: Array<Duration> = (facet.values as Array<unknown> as Array<Duration>);
		const list: Array<OdesseusSelectOption<Range<number>>> = raw
			.map((value: Duration) => ({
				value: value.from,
				name: durationMessage(value),
				raw: getRange(value),
			}));
		this.context.setDurations(list);
	}

	private passDates(facet: Facet): void {
		if (facet.key !== OdesseusFilterKeys.DEPARTUREDATE) return;

		const raw: Array<ServerDates> = (facet.values as Array<unknown> as Array<ServerDates>);
		const list: Array<OdesseusSelectOption<Range<number>>> = raw.map((value: ServerDates) => ({
			name: moment(value.from).format('MMMM YYYY'),
			value: OdyManager.toDateStamp(value.from),
			raw: {
				from: OdyManager.toDateStamp(value.from),
				to: value.to ? OdyManager.toDateStamp(value.to) : undefined,
			},
		}));
		this.context.setDates(list);
	}
}
