import * as React from 'react';

import clsx from 'clsx';

import '@app/scss/components/checkbox.scss';

export interface CheckboxProps {
	checked?: boolean;
	indeterminate?: boolean;
	onChange?: (value: boolean) => void;

	label?: React.ReactNode;
	disabled?: boolean;

	className?: string;
	containerClassName?: string;

	text?: string; // This is an additional description text
	error?: boolean; // If true - component is marked as 'with error'
}

export const Checkbox: React.FC<CheckboxProps> = (props: CheckboxProps) => {
	const id = React.useId();
	const ref = React.useRef<HTMLInputElement>(null);

	React.useEffect(() => {
		if (ref.current) {
			ref.current.indeterminate = Boolean(props.indeterminate);
		}
	}, [props.indeterminate]);

	return (
		<>
			<div className={clsx('content-halign_center checkbox', props.error && 'error-checkbox', props.containerClassName)}>
				<input
					type="checkbox"
					name={id}
					checked={props.checked}
					onChange={(event: React.ChangeEvent<HTMLInputElement>) => props.onChange?.(event.target.checked)}
					disabled={props.disabled}
					className={props.className}
					ref={ref}
				/>
				<label
					htmlFor={id}
					className="icon-margin"
					onClick={() => props.onChange?.(!props.checked)}
					role="presentation"
				>
					{props.label}
				</label>
			</div>
			{props.error && (
				<div className="checkbox__error-text font-family_primary font-weight_light text-size_extra-small">
					{props.text}
				</div>
			)}
		</>
	);
};
