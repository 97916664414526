import * as React from 'react';

import '@app/scss/pages/documents-pages.scss';
import { INTRO, sections } from '@app/pages/StaticPages/PrivacyPolicy/text';

export const PrivacyPolicy: React.FC = () => (
	<div className="documentation privacy-policy font-family_primary font-weight_regular text-size_extra-small">
		<h1 className="text-size_soft-large content-valign_center">
			Privacy Policy
		</h1>
		<p className="text-margin">
			Last Updated: December 26th, 2019
		</p>
		{INTRO}
		{
			sections.map((section) => (
				<>
					<h4 className="documentation__subtitle font-weight_bold text-size_normal">
						{section.title}
					</h4>
					<div className="documentation__text-block">
						{section.description}
					</div>
				</>
			))
		}
	</div>
);
