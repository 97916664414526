import { LinkType } from '@app/components/Header/Menu';
import { RoutePaths } from '@app/utilities/RoutePathVars';

export const vacationCenter = [
	{
		title: 'About Us',
		link: RoutePaths.aboutPage,
		type: LinkType.Internal,
	},
	{
		title: 'Privacy Policy',
		link: RoutePaths.privacyPolicy,
		type: LinkType.Internal,
	},
	{
		title: 'Terms of Use',
		link: RoutePaths.generalTerms,
		type: LinkType.Internal,
	},
];

export const travelResources = [
	{
		title: 'Personal Vacation Managers',
		link: RoutePaths.managers,
		type: LinkType.Internal,
	},
	{
		title: 'Travel Insurance',
		link: RoutePaths.travelInsurance,
		type: LinkType.Internal,
	},
	{
		title: 'Passport Requirements',
		link: RoutePaths.passportRequirements,
		type: LinkType.Internal,
	},
	{
		title: 'Shore Excursions',
		link: 'https://www.shoreexcursionsgroup.com/?ID=34&source=header',
		type: LinkType.External,
	},
	{
		title: 'Frequently Asked Questions',
		link: RoutePaths.faqs,
		type: LinkType.Internal,
	},
];

export const getInTouch = [
	{
		title: 'Investors',
		link: RoutePaths.investors,
		type: LinkType.Internal,
	},
	{
		title: 'Careers',
		link: RoutePaths.careers,
		type: LinkType.Internal,
	},
	{
		title: 'Contact',
		link: '/contact',
		type: LinkType.External,
	},
];
