import * as React from 'react';

import clsx from 'clsx';

import { Tag } from '@app/components/Tag/Tag';
import { IFeaturedCardInfo } from '@app/objects/FeaturedDeals';

import '@app/scss/components/infoCardDeal.scss';

import { Icon } from '@image/icon';

interface OwnProps extends IFeaturedCardInfo {
	isSkeleton: boolean;
}

export type FeaturedDealsCardProps = Omit<OwnProps, 'order' | 'removed' | 'id' | 'link'>

export const OVERLAY = 'linear-gradient(17.16deg, #000000 -7.42%, rgba(0, 0, 0, 0) 38.19%)';

export const FeaturedDealsCard: React.FC<FeaturedDealsCardProps> = (props: FeaturedDealsCardProps) => {
	return (
		<div className={clsx('card-deals font-family_primary', props.isSkeleton && 'skeleton')}>
			<div
				className="card-deals__top-block"
				style={{
					backgroundImage: `${OVERLAY}, url(${props.image})`,
				}}
			>
				<div className="card-deals__header-block">
					<Tag style={{ backgroundColor: '#FF8604' }} className="card-deals__tag text-size_medium">{props.tag}</Tag>
					{
						props.isSkeleton
							? <div className="card-deals__logo" />
							: <img src={props.logo} alt="Logo" className="card-deals__logo" />
					}
				</div>
				<div className="card-deals__footer-block">
					<h4 className="card-deals__title  condensed font-weight_bold text-size_medium">{props.title}</h4>
					<div className="card-deals__location-block">
						<p className="card-deals__location-block__text text-size_extra-small">{props.caption}</p>
					</div>
				</div>
				<Icon.RightArrow className="card-deals__hover-arrow" />
			</div>
			<div className="card-deals__bottom-block">
				<ul className="card-deals__description text-size_small">
					{props.inclusions?.slice(0, 5).map((item: string) => <li key={item}>{item}</li>)}
				</ul>
			</div>
		</div>
	);
};
