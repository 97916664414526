import * as React from 'react';
import { v4 } from 'uuid';
import { Settings } from 'react-slick';

import { Carousel } from '@app/components/Carousel/Carousel';
import { IFeaturedCardInfo } from '@app/objects/FeaturedDeals';
import { FeaturedDealsCard } from '@app/components/Cards/FeaturedDealsCard/FeaturedDealsCard';
import '@app/scss/pages/main/featuredDeals.scss';

interface IFeaturedDeals {
	featuredDeals?: Array<IFeaturedCardInfo>;
}

export const FEATURED_DEAL = 'featured_deal_id';

const item = [
	{
		tag: '',
		link: '',
		logo: '',
		title: '',
		caption: '',
		image: '',
		inclusions: ['', '', ''],
	},
];

const settings: Settings = {
	className: 'slider variable-width',
	variableWidth: true,
	infinite: true,
	speed: 1500,
	slidesToShow: 3,
	slidesToScroll: 3,
	arrows: false,
	responsive: [
		{
			breakpoint: 1735,
			settings: {
				infinite: false,
				slidesToShow: 3,
				slidesToScroll: 3,
			},
		},
		{
			breakpoint: 1700,
			settings: {
				infinite: true,
			},
		},
	],
};

export const FeaturedDeals: React.FC<IFeaturedDeals> = (props: IFeaturedDeals) => {
	const [isSkeleton, setIsSkeleton] = React.useState<boolean>(true);
	const list = props.featuredDeals?.length ? props.featuredDeals : Array(5).fill(item);
	const widthCardRef = React.useRef<HTMLDivElement>(null);

	React.useEffect(() => {
		if (props.featuredDeals?.length) setIsSkeleton(false);
	}, [props.featuredDeals]);

	return (
		<div className="carousel-container">
			<div className="carousel-container__center">
				<div className="col-12 col-xs-7 col-sm-5">
					<div className="carousel-container__title-block text-size_hard-large layout-container dense content-valign_center">
						<div className="carousel-container__title font-family_primary condensed font-weight_semi-bold">
							<b>Featured</b>
							<i className="font-family_decorative font-weight_regular"> Deals </i>
						</div>
					</div>
				</div>
				<Carousel className="deals-carousel col-12" total={list.length} settings={settings} cardRef={widthCardRef}>
					{list.map((item: IFeaturedCardInfo) => (
						<div key={item.id ?? v4()} ref={widthCardRef} id={FEATURED_DEAL}>
							<a href={item.link} className="deals-carousel-link" target="_blank" rel="noreferrer">
								<FeaturedDealsCard
									tag={item.tag}
									title={item.title}
									caption={item.caption}
									inclusions={item.inclusions}
									image={item.image}
									logo={item.logo}
									isSkeleton={isSkeleton}
								/>
							</a>
						</div>
					))}
				</Carousel>
			</div>
			<div className="carousel-container__right col-xl-7" />
		</div>
	);
};
