import * as React from 'react';

import { DropList, IListElement, ListElementType } from '@app/components/DropList/DropList';
import '@app/scss/pages/documents-pages.scss';
import { Icon } from '@app/image/icon';

interface PageTemplateProps {
	title: string;
	items: Array<IListElement>;
}

export const TemplateWithDropList = (props: PageTemplateProps) => (
	<>
		<h1 className="documentation__title text-size_soft-large content-valign_center">
			{props.title}
		</h1>

		<DropList items={props.items} />
	</>
);

export const TemplateWithList = (props: PageTemplateProps) => (
	<>
		<h1 className="documentation__title text-size_soft-large content-valign_center">
			{props.title}
		</h1>

		<ul>
			{props.items.map((item: IListElement) => (
				<li key={item.id}>
					<a
						href={item.text as string}
						target="_blank"
						rel="noopener noreferrer"
						className="general-terms__pdf-link text-margin"
					>
						{item.type === ListElementType.PDF && <div className="general-terms__pdf-icon"><Icon.Pdf /></div>}
						{item.title}
					</a>
				</li>
			))}
		</ul>
	</>
);
