import { PackageType } from '@app/objects/CruisePackages';
import { Direction, Tag } from '@app/objects/Sorter';
import { KeysByValue } from '@app/objects/Utility';

export enum PackagesFilterKeys {
	TYPE = 'type',
	SHIP = 'ship',
	DESTINATIONREGION = 'destinationRegion',
	DESTINATION = 'destination',
	DATE = 'date',
	CRUISELINE = 'cruiseLine',
	DURATION = 'duration',
	PAGE = 'page',
	TAG = 'order.tag',
	DIRECTION = 'order.direction',
}

export interface PackagesFormValues {
	[PackagesFilterKeys.TYPE]: Array<PackageType>;
	[PackagesFilterKeys.DESTINATIONREGION]: Array<string>;
	[PackagesFilterKeys.DESTINATION]: Array<string>;
	[PackagesFilterKeys.DATE]: Array<number>;
	[PackagesFilterKeys.CRUISELINE]: Array<string>;
	[PackagesFilterKeys.SHIP]: Array<string>;
	[PackagesFilterKeys.DURATION]: Array<number>;
	[PackagesFilterKeys.PAGE]: number;
	[PackagesFilterKeys.TAG]: Tag;
	[PackagesFilterKeys.DIRECTION]: Direction;
}

// This should be created automatically somehow.
// Otherwise have to manually change array
const keys: Array<keyof PackagesFormValues> = [
	PackagesFilterKeys.TYPE,
	PackagesFilterKeys.DESTINATIONREGION,
	PackagesFilterKeys.DESTINATION,
	PackagesFilterKeys.DATE,
	PackagesFilterKeys.CRUISELINE,
	PackagesFilterKeys.SHIP,
	PackagesFilterKeys.DURATION,

	PackagesFilterKeys.PAGE,
	PackagesFilterKeys.TAG,
	PackagesFilterKeys.DIRECTION,
];

export function isPackagesKey(key: string | keyof PackagesFormValues): key is keyof PackagesFormValues {
	return (keys as Array<string>).includes(key);
}

const arrayKeys: Array<KeysByValue<PackagesFormValues, Array<unknown>>> = [
	PackagesFilterKeys.TYPE,
	PackagesFilterKeys.DESTINATIONREGION,
	PackagesFilterKeys.DESTINATION,
	PackagesFilterKeys.DATE,
	PackagesFilterKeys.CRUISELINE,
	PackagesFilterKeys.SHIP,
	PackagesFilterKeys.DURATION,
];

const numberKeys: Array<KeysByValue<PackagesFormValues, number | Array<number>>> = [
	PackagesFilterKeys.TYPE,
	PackagesFilterKeys.DATE,
	PackagesFilterKeys.DURATION,
	PackagesFilterKeys.PAGE,
];

/**
 * Is value stored with this key of type Array<T>
 * @param key
 */
export function isArrayKey<TKey extends keyof PackagesFormValues>(key: TKey): boolean {
	return (arrayKeys as Array<keyof PackagesFormValues>).includes(key);
}

/**
 * Is value stored with this key of type number (or Array<number>)
 * @param key
 */
export function isNumberKey<TKey extends keyof PackagesFormValues>(key: TKey): boolean {
	return (numberKeys as Array<keyof PackagesFormValues>).includes(key);
}
