import * as React from 'react';

import { Bullet } from '@app/components/Bullet/Bullet';
import { WHY_US_TEXT_1, WHY_US_TEXT_2, WHY_US_TEXT_3 } from '@app/pages/MainPage/WhyUs/text';

import { Icon } from '@image/icon';

import '@app/scss/pages/main/whyUs.scss';

const information = [
	{
		Icon: Icon.BigShip,
		title: 'Exclusive Deals on Popular Cruise Lines & Land Tour Brands',
		description: WHY_US_TEXT_1,
	},
	{
		Icon: Icon.Gadgets,
		title: 'We Make It Easy',
		description: WHY_US_TEXT_2,
	},
	{
		Icon: Icon.SafetyIcon,
		title: 'Trusted by Nearly 1,000,000 Travelers and Counting',
		description: WHY_US_TEXT_3,
	},
];

export const WhyUs: React.FC = () => {
	return (
		<div className="why-us col-12">
			<div className="why-us__title font-family_primary condensed font-weight_semi-bold text-size_hard-large">
				<b>Why</b>
				<i className="font-family_decorative font-weight_regular"> Online Vacation Center</i>
			</div>
			<div className="layout-container content-valign_center">
				<div className="why-us__bullet-container col-12 col-xs-7 col-lg-9">
					{information.map((item) => (
						<Bullet
							key={item.title}
							Icon={item.Icon}
							title={item.title}
							description={item.description}
						/>
					))}
				</div>
			</div>
		</div>
	);
};
