import * as React from 'react';

import { v4 } from 'uuid';

import { Inclusion, ListItem } from '@app/objects/List';
import { Route } from '@app/objects/Routes/Route';
import { RoutePort } from '@app/objects/Routes/RoutePort';
import { RouteVoyage } from '@app/objects/Routes/Voyage';
import { PackageType } from '@app/objects/CruisePackages';
import { Nullable } from '@app/objects/Utility';
import { InclusionType, RouteInclusion } from '@app/objects/Routes/RouteInclusion';
import { first, group } from '@app/utilities/group';
import { RouteVoyagePrice } from '@app/objects/Routes/RouteVoyagePrice';

function getUrlImage(url: string | undefined | null): string {
	if (!url) return '';

	return (`${process.env.app.hosts.images}${url}`);
}

interface IWithOrder {
	order: number;
}

function sortOrderables(a: IWithOrder, b: IWithOrder): number {
	return a.order - b.order;
}

function sortNumbers(a: number, b: number): number {
	return a - b;
}

function sortPrices(a: RouteVoyagePrice, b: RouteVoyagePrice): number {
	if (a.priority === b.priority) return (a.price ?? 0) - (b.price ?? 0);

	return a.priority - b.priority;
}

function getTitleSuffix(item: Route): Nullable<string> {
	switch (item.type) {
	case PackageType.ValueInclusivePackage:
		return 'Value Inclusive Package';

	case PackageType.EnrichmentJourney:
		return 'Enrichment Journey Package';

	case PackageType.AirInclusive:
		return 'Cruise with Airfare';

	case PackageType.CruiseOnly:
		return 'Cruise';

	case PackageType.Tour:
	case PackageType.Unknown:
	default:
		return null;
	}
}

function getTitle(item: Route): string {
	const suffix = getTitleSuffix(item);
	const destination = item.destination?.name ?? '';

	if (!suffix) return destination;

	return `${destination} ${suffix}`;
}

function isPortName(item: string | unknown): item is string {
	return typeof item === 'string';
}

function getCity(item: Nullable<string>): Nullable<string> {
	if (!item) return null;

	const list = item.split(',');

	if (list.length) return list[0];

	return null;
}

function getFrom(item: Route, ports: Array<string>): Nullable<string> {
	let city = getCity(item.departureCountryCity);
	if (city) return city;

	if (ports.length) {
		city = getCity(ports[0]);
	}

	return city;
}

function getTo(item: Route, ports: Array<string>): Nullable<string> {
	let city = getCity(item.arrivalCountryCity);
	if (city) return city;

	if (ports.length) {
		city = getCity(ports[ports.length - 1]);
	}

	return city;
}

function getSubtitle(item: Route): React.ReactNode {
	const ship = item.ship?.name ?? '';
	const list = item.ports.sort(sortOrderables).map((item: RoutePort) => item.port?.name).filter(isPortName);

	const from = getFrom(item, list);
	const to = getTo(item, list);

	let prefix: React.ReactNode = null;
	if (from && to) prefix = <span><i>{from}</i> to <i>{to}</i></span>;

	if (!ship) return prefix;
	if (!prefix) return ship;

	return <span>{prefix} on {ship}</span>;
}

function getInclusion(item: RouteInclusion, type: InclusionType): Inclusion {
	let name: React.ReactNode = item.name;

	if (type === InclusionType.Airfare) {
		name = `${name} (View Gateways)`;
	} else if (type === InclusionType.Promo) {
		const list = item.name.split('!');
		if (list.length > 1) {
			const [first, ...others] = list;
			name = (<span><b>{first}!</b>{others.join('!')}</span>);
		}
	}

	return {
		id: item.id,
		title: name,
		link: item?.link ?? undefined,
	};
}

function getInclusions(route: Route, list: Array<RouteInclusion>): Array<Inclusion> {
	const groups = group<InclusionType, RouteInclusion>(list, (item: RouteInclusion) => item.type);
	const result: Array<Inclusion> = [];

	const addInclusion = (type: InclusionType) => {
		const item = first(groups, type);
		if (item) {
			result.push(getInclusion(item, type));
		}
	};

	// Do not display cruise nights for CruiseOnly since it's equal to duration of cruise
	if (route.type !== PackageType.CruiseOnly) {
		addInclusion(InclusionType.CruiseNights);
	}

	addInclusion(InclusionType.Airfare);
	addInclusion(InclusionType.Tax);

	const transfers = groups.get(InclusionType.Transfers);
	if (transfers?.length) {
		let details = '';

		if (transfers.length > 1) {
			const head = transfers.slice(0, -1).map((item: RouteInclusion) => item.name).join(', ');
			const tail = transfers[transfers.length - 1].name;

			details = `${head} and ${tail}`;
		} else {
			details = transfers[0].name;
		}

		result.push({
			id: v4(),
			title: 'Transfers',
			link: '',
			details,
		});
	}

	addInclusion(InclusionType.HotelStays);
	addInclusion(InclusionType.Spiffs);

	const specials = groups.get(InclusionType.Specials);
	if (specials?.length) {
		specials.forEach((special: RouteInclusion) => {
			result.push({
				id: special.id,
				title: special.name,
				link: special.link ?? undefined,
			});
		});
	}
	addInclusion(InclusionType.Promo);

	return result;
}

function getPrice(route: Route): Nullable<RouteVoyagePrice> {
	const prices = route.voyages
		.flatMap((item: RouteVoyage) => item.prices)
		.filter((item: RouteVoyagePrice) => item.price !== null)
		.sort(sortPrices);

	if (prices.length) return prices[0];

	return null;
}

/**
 * Convert routes (server-format) to Array<ListItem> for display in search cards
 * @param list - Packages list
 * @return {Array<ListItem>}
 */
export function routesToListItems(list: Array<Route>): Array<ListItem> {
	if (!list.length) return [];

	return list.map<ListItem>((item: Route): ListItem => ({
		id: item.id,

		title: getTitle(item),
		subTitle: getSubtitle(item),

		image: getUrlImage(item.image),
		coverType: item.coverType,

		dates: item.voyages.map((item: RouteVoyage) => item.date).sort(sortNumbers),
		ports: item.ports.sort(sortOrderables),

		price: getPrice(item),
		inclusions: getInclusions(item, item.inclusions),

		duration: item.duration + item.preDuration + item.postDuration,
		ship: item.ship?.name ?? '',
		type: item.type,

		bookLink: item.bookLink,
		detailsLink: item.detailsLink,

		cruiseLineId: item.cruiseLineId,
		cruiseLineName: item.cruiseLine?.name ?? '',
		cruiseLineLogo: getUrlImage(item.cruiseLine?.logo),
	}));
}
