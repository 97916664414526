export enum LinkType {
	Internal = 0,
	External = 1,
}

export interface MenuOption {
	title: string;
	link: string;
	type?: LinkType;
	className?: string;
}

export const items: Array<MenuOption> = [
	{
		title: 'Exclusive Packages',
		link: '/valueinclusive',
		type: LinkType.External,
	},
	{
		title: 'Destinations',
		link: `
			https://res.onlinevacationcenter.com/swift/cruise/destination/africa
				?siid=228902lang=1&destinations=2&destinationType=All&DestinationorRiverId=destinationId
				&activeTab=CruiseLineCalendar&showPromoCodes=true
		`,
		type: LinkType.External,
	},
	{
		title: 'Cruise Lines',
		link: 'https://res.onlinevacationcenter.com/swift/cruise/cruiseline?siid=228902&lang=1',
		type: LinkType.External,
	},
	{
		title: 'Our Distinction',
		link: '/why-ovc',
		type: LinkType.Internal,
	},
];
