import * as React from 'react';

import '@app/scss/pages/documents-pages.scss';
import { INTRO, requirements } from '@app/pages/StaticPages/DocumentationRequirements/requirements';

import passwords from '@image/docRequirements/passwords.png';

export const DocumentationRequirements = () => (
	<div className="documentation doc-requirements font-family_primary font-weight_regular text-size_extra-small">
		<h1 className="documentation__title text-size_soft-large content-valign_center">
			Travel Documentation Requirements
		</h1>

		<div className="doc-requirements__intro-block documentation__section">
			<p>
				{INTRO}
			</p>
			<img src={passwords} alt="Passports" />
		</div>

		{requirements.map((item) => (
			<div className="documentation__section" key={item.id}>
				<h4 className="documentation__subtitle font-weight_bold">{item.title}</h4>
				<div className="documentation__text-block">{item.text}</div>
			</div>
		))}
	</div>
);
