import React from 'react';

import { Nullable } from '@app/objects/Utility';

export function useDebounce<T>(handler: (value: Nullable<T>) => void, period: number): (value: Nullable<T>) => void {
	const debounce = React.useRef<{ timer: ReturnType<typeof setTimeout>, value: Nullable<T> } | null>();

	const onTimeout = () => handler(debounce.current?.value ?? null);

	React.useEffect(() => {
		return () => clearTimeout(debounce.current?.timer);
	}, []);

	return (value: Nullable<T>) => {
		if (debounce.current) {
			clearTimeout(debounce.current.timer);
		}

		debounce.current = { timer: setTimeout(onTimeout, period), value };
	};
}
