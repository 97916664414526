import * as React from 'react';

import { NotificationType } from '@app/components/Notification/Notification';

export interface SubscribeNotification {
	type: NotificationType;
	message: React.ReactNode;
}

export interface SubscribeData {
	email: string;
	firstName: string;
	lastName: string;
	zip: string;
	agreeWithPP: boolean;
}

export interface SubscribeResponse {
	status: number;
	message: string;
}

export enum SubscribeStatus {
	AlreadyExists = 0,
	Added = 1,
	Error = 2,
}

export const initialSubscribeValues: SubscribeData = {
	firstName: '',
	lastName: '',
	zip: '',
	email: '',
	agreeWithPP: false,
};
export const SuccessMessage: SubscribeNotification = {
	type: NotificationType.Success,
	message: (
		<>
			Thank you for subscribing!
			<script
				type="text/javascript"
				data-action="cnv"
				data-id="alocdn-ldr"
				data-label="subscribe"
				src="//js.alocdn.com/c/wta9phqe.js"
				async
				defer
			/>
		</>
	),
};

export const ExistsMessage: SubscribeNotification = {
	type: NotificationType.Info,
	message: 'Email is already subscribed',
};

export const ErrorMessage: SubscribeNotification = {
	type: NotificationType.Error,
	message: 'Ops! Something went wrong. Please, try again later!',
};

export const RecaptchaMessage: SubscribeNotification = {
	type: NotificationType.Error,
	message: 'Unable to verify if user is human.',
};

export function getNotification(status: SubscribeStatus): SubscribeNotification {
	switch (status) {
	case SubscribeStatus.Added:
		return SuccessMessage;

	case SubscribeStatus.AlreadyExists:
		return ExistsMessage;

	default:
		return ErrorMessage;
	}
}
