import * as React from 'react';
import { useLocation } from 'react-router-dom';

import { Nullable } from '@app/objects/Utility';

type Callback = (map: Record<string, Nullable<string>>) => void;

/**
 * Detect query params change, compare values and pass only changed ones to callback
 * @param list - a list of query params that need to be checked
 * @param onChange - a callback function to handle change
 */
export function useQueryParams(list: Array<string>, onChange: Callback) {
	const location = useLocation();
	const curValue = React.useRef<Nullable<Record<string, Nullable<string>>>>(null);

	React.useEffect(() => {
		const params = new URLSearchParams(location.search);
		const newValue: Record<string, Nullable<string>> = {};
		list.forEach((item: string) => { newValue[item] = params.get(item); });

		if (curValue.current === null || !list.reduce((acc: boolean, key: string) => acc && curValue.current?.[key] === newValue[key], true)) {
			onChange(newValue);
			curValue.current = newValue;
		}
	}, [location.search, onChange]);
}
