import * as React from 'react';

import Collapse from 'rc-collapse';

import { Button, ButtonVariant } from '@app/components/Button/Button';
import { BaseFiltersProps } from '@app/components/Filters/Filters';

import '@app/scss/components/filters.scss';

import { Icon } from '@image/icon';

interface FilterHeadProps {
	title: string;
	titleIcon?: React.ReactNode;
	extraButton?: React.ReactNode;
}

export const FilterHead = ({ title, titleIcon, extraButton }: FilterHeadProps) => (
	<div className="layout-container">
		<div className="filters__header col-12">
			<div className="col-12">
				<div className="content-halign_center content-valign_top">
					{titleIcon ?? <Icon.Filter />}
					<p className="font-family_primary font-weight_regular text-size_medium icon-margin">{title}</p>
				</div>
				{extraButton}
			</div>
		</div>
	</div>
);

type FilterBodyProps = Omit<BaseFiltersProps, 'open' | 'onClose'>

export const FilterBody: React.FC<FilterBodyProps> = (props: FilterBodyProps) => {
	const [activeKey, setActiveKey] = React.useState<React.Key | Array<React.Key>>();

	return (
		<>
			<div className="layout-container">
				<Collapse
					activeKey={activeKey}
					onChange={(props) => setActiveKey(props)}
					expandIcon={Icon.ChevronDown}
				>
					{props.children}
				</Collapse>
			</div>

			<div className="col-12 filters__checkbox-container">
				{props.extra}

				<Button
					variant={ButtonVariant.Filled}
					onClick={() => props.onReset?.()}
					disabled={!props.canReset}
				>
					Reset All
				</Button>
			</div>
		</>
	);
};
