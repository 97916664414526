import * as React from 'react';

import clsx from 'clsx';

import '@app/scss/pages/search/searchBar.scss';

import { Icon } from '@app/image/icon';

interface SearchBarProps {
	children: React.ReactNode;
}

export const SearchBar: React.FC<SearchBarProps> = (props: SearchBarProps) => {
	const [isVisible, setIsVisible] = React.useState<boolean>(false);

	return (
		<div className={clsx('search-bar col-12', isVisible && 'search-bar-active')}>
			<div className="layout-container content-valign_center" onClick={() => setIsVisible(!isVisible)}>
				<div className="col-12 col-lg-11">
					<div className="search-bar__action-btn text-size_medium">
						Modify your search
						<Icon.ChevronDown className="search-bar__arrow" />
					</div>
				</div>
			</div>
			<div
				id="search-bar"
				className="layout-container content-valign_center"
			>
				<div className={clsx('search-bar__container col-12 col-lg-11', !isVisible && 'disabled')}>
					{props.children}
				</div>
			</div>
		</div>
	);
};
