import * as React from 'react';

import { Tag } from '@app/components/Tag/Tag';
import {
	ActionMode,
	Button,
	ButtonVariant,
	Color,
} from '@app/components/Button/Button';

import '@app/scss/components/dealCard.scss';
import { OVERLAY } from '@app/components/Cards/FeaturedDealsCard/FeaturedDealsCard';

export enum DealCardTypes {
	Fullscreen = 'Fullscreen',
	Medium = 'Medium',
	Large = 'Large',
}

export interface DealCardProps {
	image: string;
	// eslint-disable-next-line react/no-unused-prop-types
	count: number;
	title: string;
	description: string;
	link: string;
	size: DealCardTypes;
}

type CardContentProps = Omit<DealCardProps, 'image' | 'count' | 'size'>
type DealCardMediumProps = Omit<DealCardProps, 'size'>;

const CardContent: React.FC<CardContentProps> = (props: CardContentProps) => (
	<>
		<b className="font-family_primary condensed font-weight_bold text-size_soft-large">{props.title}</b>
		<p className="deal-card__description font-weight_regular text-size_extra-small line-height_large">{props.description}</p>
		<Button
			className="font-family_primary"
			variant={ButtonVariant.Outlined}
			color={Color.Light}
			action={ActionMode.ExternalLink}
			link={props.link}
		>
			Explore
		</Button>
	</>
);

const FullScreenCard: React.FC<DealCardProps> = (props: DealCardProps) => (
	<a
		className="deal-card font-family_primary deal-card_fullscreen content-valign_center content-halign_center"
		style={{ backgroundImage: `linear-gradient(50deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.3) 50%, rgba(0,0,0,0) 100%), url(${props.image})` }}
		href={props.link}
		target="_blank"
		rel="noreferrer"
	>
		<div className="deal-card__text-block content-halign_center">
			<Tag style={{ background: '#8EC23D', margin: 0 }} className="text-size_small">{`${props.count} deals`}</Tag>
			<CardContent {...props} />
		</div>
	</a>
);

const MediumCard: React.FC<DealCardMediumProps> = (props: DealCardMediumProps) => (
	<div className="deal-card_medium">
		<a
			className="deal-card font-family_primary content-valign_center"
			style={{ backgroundImage: `${OVERLAY}, url(${props.image})` }}
			href={props.link}
			target="_blank"
			rel="noreferrer"
		>
			<b className="font-family_primary condensed font-weight_bold text-size_soft-large">{props.title}</b>
		</a>
		<div className="layout-container content-valign_center ">
			<p className="deal-card__description font-weight_regular text-size_extra-small line-height_large col-11">
				{props.description}
			</p>
		</div>
		<Button
			className="font-family_primary"
			variant={ButtonVariant.Outlined}
			color={Color.Dark}
			action={ActionMode.ExternalLink}
			link={props.link}
		>
			Explore
		</Button>
	</div>
);

export const DealCard: React.FC<DealCardProps> = (props: DealCardProps) => {
	if (props.size === DealCardTypes.Fullscreen) return <FullScreenCard {...props} />;
	if (props.size === DealCardTypes.Medium) return <MediumCard {...props} />;

	return (
		<a
			className="deal-card deal-card_large font-family_primary content-valign_left col-12 col-md-6"
			style={{ backgroundImage: `${OVERLAY}, url(${props.image})` }}
			href={props.link}
			target="_blank"
			rel="noreferrer"
		>
			<div className="deal-card__text-block deal-card__text-block_large content-halign_left">
				<CardContent {...props} />
			</div>
		</a>
	);
};
