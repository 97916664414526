import * as React from 'react';

import { v4 } from 'uuid';

import '@app/scss/pages/documents-pages.scss';

const getLink = (link: string, text: string) => <a href={link} target="_blank" rel="noopener noreferrer">{text}</a>;

const list = [
	{
		id: v4(),
		link: getLink('https://www.onlinevacationcenter.com/', 'Online Vacation Center,'),
		text: ' a leading U.S. cruise retailer;',
	},
	{
		link: getLink('http://www.dunhilltraveldeals.com/', 'Dunhill Travel Deals,'),
		id: v4(),
		// eslint-disable-next-line max-len
		text: ' a digital marketing company publicizing travel, vacation and cruise deals through an extensive email subscriber base and 40+ partner websites;',
	},
	{
		id: v4(),
		link: getLink('http://www.enrichmentjourneys.com/', 'Enrichment Journeys,'),
		text: ' a developer of unique river, ocean and land-based vacation packages;',
	},
	{
		id: v4(),
		link: getLink('http://www.luxurylink.com/', 'Luxury Link,'),
		text: ' an online window to the world\'s best destinations, luxury accommodations and curated travel experiences; and',
	},
	{
		id: v4(),
		link: getLink('https://www.cruiseshipcenters.com/en-US/ovc/home', 'Expedia® Cruises of OVC®,'),
		text: ' a franchise supporting independent travel consultants.',
	},
];

export const InvestorRelations = () => (
	<>
		<h1 className="documentation__title text-size_soft-large content-valign_center">
			Investor Relations
		</h1>
		<div className="documentation__section">
			<p>
				Founded in 1972, Online Vacation Center Holdings (OTCQX: ONVC) owns five vacation-oriented businesses:
			</p>
			<p>
				<ul>
					{list.map((item) => (
						<li key={item.id}>
							{item.link}
							<span>{item.text}</span>
						</li>
					))}
				</ul>
			</p>
		</div>
	</>
);
