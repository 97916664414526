import * as React from 'react';

export type Nullable<T> = T | null;
export type Optional<T> = Nullable<T> | undefined;

export type ByValue<T, TValue> = { [P in keyof T as T[P] extends TValue ? P : never]: T[P] };
export type KeysByValue<T, TValue> = keyof ByValue<T, TValue>;

export type OptionalRecord<T> = {
	[K in keyof T]?: Optional<T[K]>
}

/**
 * TypeGuard to check if value is present (not null and undefined)
 * @param {Optional<T>} envelope
 * @returns {envelope is T}
 */
export function hasValue<T>(envelope: Optional<T>): envelope is T {
	return envelope !== null && envelope !== undefined;
}

/**
 * TypeGuard to check if value is number (NaN is not a number, lol)
 * @param {unknown} item
 * @returns {item is number}
 */
export function isNumber(item: number | unknown): item is number {
	return typeof item === 'number' && !Number.isNaN(item);
}

/**
 * TypeGuard to check if value is string
 * @param {unknown} item
 * @returns {item is number}
 */
export function isString(item: string | unknown): item is string {
	return typeof item === 'string';
}

/**
 * TypeGuard to check if provided value is an Array
 * @param {unknown} list
 * @returns {list is Array<T>}
 */
export function isArray<T>(list: unknown | Array<T>): list is Array<T> {
	return Array.isArray(list);
}

/**
 * TypeGuard to check if provided item is an Array of numbers
 * @param {unknown} list
 * @returns {list is Array<number>}
 */
export function isNumericArray<T>(list: unknown | Array<T>): list is Array<number> {
	if (!isArray(list)) return false;

	return list.length === 0 || list.reduce((acc: boolean, cur: T | unknown) => acc && isNumber(cur), true);
}

/**
 * TypeGuard to check if provided item is an Array of strings
 * @param {unknown} list
 * @returns {list is Array<string>}
 */
export function isStringArray<T>(list: unknown | Array<T>): list is Array<string> {
	if (!isArray(list)) return false;

	return list.length === 0 || list.reduce((acc: boolean, cur: T | unknown) => acc && isString(cur), true);
}

export type TIcon = React.FunctionComponent<React.SVGProps<SVGSVGElement> & { title?: string }>;
