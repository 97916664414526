import * as React from 'react';
import { Link } from 'react-router-dom';
import {
	Field, FieldProps, Form, Formik, FormikHelpers, FormikValues,
} from 'formik';
import * as Yup from 'yup';

import { NotificationType } from '@app/components/Notification/Notification';
import { Button, ButtonVariant } from '@app/components/Button/Button';
import { Checkbox } from '@app/components/Checkbox/Checkbox';
import { LinkType } from '@app/components/Header/Menu';

import '@app/scss/pages/forms.scss';
import { Icon } from '@app/image/icon';

interface ExtraCheckbox {
	fieldName: string;
	label: string;
}

export interface IMessage {
	type: NotificationType;
	content: React.ReactNode;
}

interface FormTemplateProps<FormValues> {
	title: string;

	subtitle?: string;
	extra?: React.ReactNode;
	extraCheckbox?: ExtraCheckbox;

	innerForm: React.ReactNode;
	initialValues: FormValues;
	validationSchema: Yup.ObjectSchema<object>;
	onSubmit: (values: FormValues, helpers: FormikHelpers<FormValues>) => void;

	message?: IMessage;
}

const getLink = (link: string, text: string, type?: LinkType) => {
	if (type === LinkType.Internal) {
		return (
			<Link
				to={link}
				target="_blank"
				rel="noopener noreferrer"
			>
				{` ${text} `}
			</Link>
		);
	}

	return (
		<a href={link} target="_blank" rel="noopener noreferrer">{text}</a>
	);
};

const labelConfirmCheckbox = (
	<p>
		By clicking this button, you agree to our
		{getLink('/general-terms', 'Terms and Condition', LinkType.Internal)}
		and {getLink('/privacy-policy', 'Privacy Policy', LinkType.Internal)}
	</p>
);

interface ErrorMessage {
	message: React.ReactNode;
	onChange: (value?: IMessage) => void;
}

const ErrorMessage = ({ message, onChange }: ErrorMessage) => (
	<div className="form-group form-group_outlined form-group_outlined--error">
		<button
			type="button"
			onClick={() => onChange(undefined)}
			className="modal__btn-close form-group__btn-close"
		>
			<Icon.Clear />
		</button>
		<p className="content-valign_center">{message}</p>
	</div>
);

interface ISuccessMessage {
	title: string;

	subtitle?: string;
	extra?: React.ReactNode;
}

export const SuccessMessage = ({ title, subtitle, extra }: ISuccessMessage) => (
	<div className="col-12 content-halign_center form__head">
		<h1 className="text-size_soft-large content-valign_center form__title">{title}</h1>
		{subtitle && <p className="form__subtitle text-size_large">{subtitle}</p>}

		{extra}
	</div>
);

const InnerForm = <FormValues extends FormikValues, >(props: FormTemplateProps<FormValues>) => {
	const {
		title, subtitle, extra,
		extraCheckbox, innerForm, initialValues,
		validationSchema, onSubmit, message,
	} = props;
	const [mess, setMess] = React.useState<IMessage | undefined>(message);

	React.useEffect(() => setMess(message), [message]);

	return	(
		<>
			<div className="col-12 content-halign_center form__head">
				<h1 className="text-size_soft-large content-valign_center form__title">{title}</h1>
				{subtitle && <p className="col-12 col-md-8 form__subtitle">{subtitle}</p>}

				{extra}
			</div>

			<Formik<FormValues>
				initialValues={initialValues}
				validationSchema={validationSchema}
				onSubmit={onSubmit}
				enableReinitialize
			>
				{(formikBag) => (
					<Form>
						{innerForm}

						<div className="col-12 form__checkbox-block">
							{extraCheckbox && (
								<div className="form-group">
									<Field name={extraCheckbox.fieldName}>
										{({ field, form }: FieldProps) => (
											<Checkbox
												label={extraCheckbox.label}
												checked={field.value}
												onChange={(value: boolean) => form.setFieldValue(field.name, value)}

												text={form.errors[extraCheckbox.fieldName] as string}
												error={Boolean(form.errors[extraCheckbox.fieldName] && form.touched[extraCheckbox.fieldName])}
											/>
										)}
									</Field>
								</div>
							)}
							<Field name="agreeSubmittingTerms">
								{({ field, form }: FieldProps) => (
									<div className="form-group">
										<Checkbox
											checked={field.value}
											onChange={(value: boolean) => form.setFieldValue(field.name, value)}
											label={labelConfirmCheckbox}

											text={form.touched.agreeSubmittingTerms ? form.errors.agreeSubmittingTerms as string : undefined}
											error={Boolean(form.errors.agreeSubmittingTerms && form.touched.agreeSubmittingTerms)}
										/>
									</div>
								)}
							</Field>
							<div className="layout-container content-valign_center form__btn-submit">
								<div className="col-12 col-lg-4">
									<Button
										style={{ width: '100%' }}
										className="uppercase form-group"
										variant={ButtonVariant.Filled}
										onClick={formikBag.handleSubmit}
										loading={formikBag.isSubmitting}
									>
										Submit
									</Button>
								</div>
							</div>
							{mess?.type === NotificationType.Error && <ErrorMessage message={mess.content} onChange={setMess} />}
							<p className="content-valign_center text-size_extra-small">
								This site is protected by reCAPTCHA and the Google&ensp;
								{getLink('https://policies.google.com/privacy', 'Privacy Policy')}
								&ensp;and&ensp; {getLink('https://policies.google.com/terms', 'Terms of Service')}&ensp;apply.
							</p>
						</div>
					</Form>
				)}
			</Formik>
		</>
	);
};

export const FormTemplate = <FormValues extends FormikValues, >(props: FormTemplateProps<FormValues>) => (
	<div className="col-12 col-md-8 content-halign_center form font-family_primary font-weight_regular text-size_medium">
		{props.message && props.message?.type === NotificationType.Success ? props.message?.content : <InnerForm {...props} /> }
	</div>
);
