import * as React from 'react';

import { useListData } from '@app/hooks/data/useListData';
import { Select } from '@app/components/Selects/Select';
import { Option } from '@app/components/Selects/Option';
import { Nullable } from '@app/objects/Utility';

interface Ship {
	id: number;
	name: string;
	supplierId: number;
}

interface ShipsSelectProps {
	value?: Nullable<number>;
	onChange: (value?: Nullable<number>) => void;
	cruiseLine?: Nullable<number>;
}

function getShips(ships: Array<Ship> | undefined, supplierId?: Nullable<number>) {
	if (ships === undefined) return [];
	if (!supplierId || supplierId <= 0) return ships;

	return ships.filter((item: Ship) => item.supplierId === supplierId);
}

const location = 'api/bridge/v1/ships/list?';

export const ShipsSelect: React.FC<ShipsSelectProps> = (props: ShipsSelectProps) => {
	const { list, loading, fetch } = useListData<Ship, number>(location, props.value, 1000);

	return (
		<Select
			placeholder="Ship"
			loading={loading}
			value={props.value}
			onChange={props.onChange}
			onPopupScroll={fetch}
		>
			{
				getShips(list, props.cruiseLine).map((item: Ship) => (
					<Option key={item.id} value={item.id}>
						{item.name}
					</Option>
				))
			}
		</Select>
	);
};
