import * as React from 'react';

import { FilterHead, FilterBody } from '@app/components/Filters/FilterComponents/FilterComponents';
import { BaseFiltersProps } from '@app/components/Filters/Filters';

import '@app/scss/components/filters.scss';

export type DesktopFiltersProps = Omit<BaseFiltersProps, 'open' | 'onClose'>

export const DesktopFilters: React.FC<DesktopFiltersProps> = (props: DesktopFiltersProps) => (
	<div className="filters col-3">
		<FilterHead title="Filter Your Search" />

		<div>
			<FilterBody
				extra={props.extra}
				canReset={props.canReset}
				onReset={props.onReset}
			>
				{props.children}
			</FilterBody>
		</div>
	</div>
);
