import * as React from 'react';

import { Settings } from 'react-slick';

import { DealCard, DealCardTypes } from '@app/components/Cards/DealCard/DealCard';
import { ActionMode, Button, ButtonVariant } from '@app/components/Button/Button';
import { Carousel } from '@app/components/Carousel/Carousel';
import '@app/scss/pages/main/dealsSections.scss';
import { IDealCard } from '@app/objects/DealSections';

export interface DealSectionsProps {
	dealList: Array<IDealCard>;
}

const settings: Settings = {
	className: 'slider variable-width',
	variableWidth: true,
	infinite: true,
	speed: 1500,
	slidesToShow: 1,
	slidesToScroll: 1,
	arrows: false,
	responsive: [
		{
			breakpoint: 930,
			settings: {
				centerMode: true,
				slidesToShow: 1,
				slidesToScroll: 1,
				variableWidth: true,
			},
		},
	],
};

export const DealSections: React.FC<DealSectionsProps> = (props: DealSectionsProps) => {
	const dealVIP = props.dealList.find((item: IDealCard) => item.order === 0);
	const mediumSections = props.dealList.filter((item: IDealCard) => item.order === 1
		|| item.order === 2
		|| item.order === 3);
	const dealVacation = props.dealList.filter((item: IDealCard) =>
		item.order === 4 || item.order === 5);

	return (
		<>
			{dealVIP && (
				<DealCard
					title={dealVIP?.title}
					description={dealVIP.description}
					image={dealVIP.image}
					count={dealVIP.count}
					size={DealCardTypes.Fullscreen}
					link={dealVIP.link}
				/>
			)}
			<div className="middle-section">
				<div className="middle-section__background-block" />
				<div className="middle-section__title-block">
					<div className="middle-section__title font-family_primary condensed font-weight_semi-bold text-size_hard-large">
						<b>Shop by</b>
						<i className="font-family_decorative font-weight_regular"> Destination </i>
					</div>
					<Button
						variant={ButtonVariant.Filled}
						action={ActionMode.ExternalLink}
						link={`https://res.onlinevacationcenter.com/swift/cruise/destination/africa?siid=228902&lang=1&destinations=2&
							destinationType=All&DestinationorRiverId=destinationId&activeTab=CruiseLineCalendar&showPromoCodes=true`}
					>
						View All Destinations
					</Button>
				</div>
				<div className="col-12 col-xs-7 col-lg-9 content-halign_center content-valign_center">
					<div className="deal-card-container">
						{
							mediumSections.map((item) => (
								<div className="deal-card-container__item col-4" key={item.image}>
									<DealCard
										title={item.title}
										description={item.description}
										image={item.image}
										count={item.count}
										size={DealCardTypes.Medium}
										link={item.link}
									/>
								</div>
							))
						}
					</div>
				</div>
				<Carousel
					className="middle-section-carousel"
					total={mediumSections.length}
					settings={settings}
				>
					{mediumSections.map((item) => (
						<DealCard
							key={item.count}
							title={item.title}
							description={item.description}
							image={item.image}
							count={item.count}
							size={DealCardTypes.Medium}
							link={item.link}
						/>
					))}
				</Carousel>
			</div>
			<div className="bottom-section">
				{dealVacation.map((item: IDealCard) => (
					<DealCard
						key={item.order}
						title={item.title}
						description={item.description}
						image={item.image}
						count={item.count}
						size={DealCardTypes.Large}
						link={item.link}
					/>
				))}
			</div>
		</>
	);
};
