import * as React from 'react';

import clsx from 'clsx';
import moment from 'moment';

import { RoutePort } from '@app/objects/Routes/RoutePort';
import { ExpandableList } from '@app/components/Cards/SearchListCard/CardComponents/ExpandableList';
import { Inclusion } from '@app/objects/List';
import { InclusionItem } from '@app/components/Cards/SearchListCard/CardComponents/Inclusion';

import { Icon } from '@image/icon';

import '@app/scss/components/searchListCard.scss';
import '@app/scss/components/tooltip.scss';

interface CardContent {
	tour: boolean;
	duration: number;
	subtitle: React.ReactNode;
	departureDates: Array<number>;
	ports: Array<RoutePort>;
	includes?: Array<Inclusion>;
}

const titleClassName = 'font-family_primary font-weight_semi-bold text-size_extra-small line-height_large';
const contentCol = 'col-12 col-sm-4';

export const CardContent: React.FC<CardContent> = (props: CardContent) => (
	<div className="search-list-card__content font-family_primary font-weight_regular">
		<div className="layout-container">
			<div className={clsx(contentCol, titleClassName)}>
				<div className="content-halign_center">
					<div className="content-valign_center"><Icon.Moon /></div>
					<p className="icon-margin">{props.duration} Nights</p>
				</div>
			</div>
			<p className="col-12 col-sm-8 mobile-margin text-size_small">
				{props.subtitle}
			</p>
		</div>

		<div className="layout-container">
			<div className={clsx(contentCol, titleClassName)}>
				<div className="content-halign_center">
					<div className="content-valign_center"><Icon.Calendar /></div>
					<p className="icon-margin">{props.departureDates.length} Departure Date{`${props.departureDates.length > 1 ? 's' : ''}`}:</p>
				</div>
			</div>
			<ExpandableList
				items={props.departureDates.map((item: number) => {
					const date = moment(item).utc();

					return {
						key: item,
						content: (
							<>
								{date.format('MMM DD')},&nbsp;
								<b className="font-weight_bold">
									{date.format('YYYY')}
								</b>
							</>
						),
					};
				})}
				className="col-12 col-sm-8 text-size_small"
			/>
		</div>

		{
			props.ports.length ? (
				<div className="layout-container">
					<div className={clsx(contentCol, titleClassName)}>
						<div className="content-halign_center">
							<div className="content-valign_center"><Icon.Anchor /></div>
							<p className="icon-margin">Visits:</p>
						</div>
					</div>
					<ExpandableList
						items={props.ports.map((item) => ({
							key: item.id,
							content: item.port?.name ?? 'Unknown Port',
						}))}
						className="col-12 col-sm-8 text-size_small"
					/>
				</div>
			) : null
		}

		{(!props.tour && props.includes && Boolean(props.includes.length)) && (
			<div className="layout-container" style={{ marginTop: '15px' }}>
				<div className={clsx('content-valign_center', contentCol, titleClassName)}>
					<div className="content-halign_center">
						<div className="content-valign_center"><Icon.Includes /></div>
						<p className="icon-margin">Includes:</p>
					</div>
				</div>
				<ExpandableList
					items={props.includes?.map((item: Inclusion) => ({
						key: item.id,
						content: <InclusionItem key={item.id} item={item} />,
					}))}
					count={8}
					className="col-12 col-sm-8 search-list-card__includes text-size_extra-small"
				/>
			</div>
		)}
	</div>
);
