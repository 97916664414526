import * as React from 'react';
import { Link } from 'react-router-dom';

import clsx from 'clsx';
import { useSelector } from 'react-redux';
import moment from 'moment';

import { SocialIcons } from '@app/components/SocialIcons/SocialIcons';
import {
	getInTouch,
	travelResources,
	vacationCenter,
} from '@app/components/Footer/text';
import { TIcon } from '@app/objects/Utility';
import { getPath } from '@app/services/path';
import { formatPhone } from '@app/services/phone';
import { LinkType } from '@app/components/Header/Menu';
import { RootStore } from '@app/store';

import { Icon } from '@image/icon';

import '@app/scss/components/footer.scss';

interface ListItem {
	title: string;
	link: string;
	type?: LinkType;
}

interface DropListProps {
	list: Array<ListItem>
	title: string;
}

interface SocialIcon {
	Icon: TIcon;
	link: string;
}

const socialIcons: Array<SocialIcon> = [
	{
		Icon: Icon.Twitter,
		link: 'https://twitter.com/VacationManager',
	},
	{
		Icon: Icon.Facebook,
		link: 'https://www.facebook.com/OnlineVacationCenter',
	},
	{
		Icon: Icon.Instagram,
		link: 'https://www.instagram.com/onlinevacationcenter/',
	},
	{
		Icon: Icon.Pinterest,
		link: 'https://www.pinterest.com/vacationmanager/',
	},
];

const DropList: React.FC<DropListProps> = (props: DropListProps) => {
	const [isVisible, setIsVisible] = React.useState<boolean>(false);

	return (
		<div className={clsx('drop-list', isVisible && 'drop-list_active')}>
			<div className="drop-list__title-block" onClick={() => setIsVisible(!isVisible)}>
				<h4 className="drop-list__title font-family_primary condensed font-weight_bold text-size_medium">{props.title}</h4>
				<Icon.ChevronDown className="drop-list__arrow" />
			</div>
			<ul className="drop-list__list">
				{props.list.map((item: ListItem) => (
					<li key={item.title}>
						{item.type === LinkType.Internal
							? (
								<Link
									to={item.link}
									onClick={() => window.scrollTo(0, 0)}
									className="drop-list__link font-family_primary font-weight_regular text-size_extra-small line-height_extra-large"
								>
									{item.title}
								</Link>
							) : (
								<a
									href={item.type === LinkType.External ? item.link : getPath(item.link)}
									className="drop-list__link font-family_primary font-weight_regular text-size_extra-small line-height_extra-large"
									target="_blank"
									rel="noreferrer"
								>
									{item.title}
								</a>
							)}
					</li>
				))}
			</ul>
		</div>
	);
};

export const contestLink = (
	<a
		href="https://www.sigtn.com/clients/emarketing/intro.cfm?agency_id=2954"
		className="drop-list__link text-size_extra-small line-height_extra-large"
		target="_blank"
	>
		Enter Contest
	</a>
);

const Social: React.FC = () => {
	const phone = useSelector((state: RootStore) => state.header.item?.phone);

	return (
		<div className="footer__social-block">
			<h4 className="drop-list__title font-family_primary condensed font-weight_bold text-size_medium">Customer Support</h4>
			<p className="footer__social-block__title font-family_primary condensed font-weight_bold text-size_soft-large">
				{formatPhone(phone)}
			</p>
			<p className="font-family_primary font-weight_regular text-size_extra-small line-height_extra-large">Your Personal Vacation Managers®</p>
			<div className="footer__social-icons">
				<SocialIcons list={socialIcons} />
			</div>

			{contestLink}
		</div>
	);
};

export const Footer: React.FC = () => {
	return (
		<footer className="footer col-12">
			<div className="layout-container content-valign_center">
				<div className="footer__container col-12 col-xs-9">
					<div className="footer__contacts-block">
						<DropList list={vacationCenter} title="Online Vacation Center" />
						<DropList list={travelResources} title="Travel Resources" />
						<DropList list={getInTouch} title="Get in touch" />
						<Social />
					</div>
				</div>
			</div>
			<div className="footer__copyright layout-container content-valign_center">
				<p className="font-family_primary font-weight_regular text-size_extra-small col-12 col-xs-9">
					Copyright © {moment().format('YYYY')} Online Vacation Center | All rights reserved.
				</p>
			</div>
		</footer>
	);
};
