import * as React from 'react';

import { SubscribeInner } from '@app/pages/MainPage/Subscribe/Subscribe';

const title = (
	<h1 className="text-size_soft-large content-valign_center form__title">Sign Up to Save</h1>
);

export const Subscribe: React.FC = () => (
	<div className="signup-section">
		<div className="col-12 col-md-5 content-halign_center form font-family_primary font-weight_regular text-size_medium signup-form">
			<SubscribeInner title={title} />
		</div>
	</div>
);
