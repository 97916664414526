import { hasValue } from '@app/objects/Utility';
import { OdyManager } from '@app/odesseus/OdyManager';
import {
	OdesseusFilterRecord,
	OdesseusOptions,
	OdesseusSearchFormValues,
	OdesseusSelectOption,
} from '@app/odesseus/types/Filters/Odesseus';
import { Range } from '@app/objects/Filters/Range';
import { OdesseusFilterKeys, OdesseusFilterValue } from '@app/odesseus/types/Filters';

interface MinMax {
	min: number;
	max: number;
}

export function addOdesseusValues<T extends OdesseusFilterValue = OdesseusFilterValue>(
	filters: Array<OdesseusFilterRecord<T>>,
	key: OdesseusFilterKeys,
	values: Array<T>,
): Array<OdesseusFilterRecord<T>> {
	if (!values.length) return filters.filter((item: OdesseusFilterRecord<T>) => item.key !== key);

	const entity = filters?.find((item: OdesseusFilterRecord<T>) => item.key === key);
	if (!entity) return [...filters, { values, key }];

	return filters.map((item: OdesseusFilterRecord<T>) => (item.key === key ? { values, key } : item));
}

export function addOdesseusRanges<T extends OdesseusFilterValue = OdesseusFilterValue>(
	filters: Array<OdesseusFilterRecord<T>>,
	key: OdesseusFilterKeys,
	ranges: Array<Range<T>>,
): Array<OdesseusFilterRecord<T>> {
	if (!ranges.length) return filters.filter((item: OdesseusFilterRecord<T>) => item.key !== key);

	const entity = filters?.find((item: OdesseusFilterRecord<T>) => item.key === key);
	if (!entity) return [...filters, { ranges, key }];

	return filters.map((item: OdesseusFilterRecord<T>) => (item.key === key ? { ranges, key } : item));
}

function getDatesRange(
	values: Pick<OdesseusSearchFormValues, OdesseusFilterKeys.DEPARTUREDATE>,
	options: OdesseusOptions,
): MinMax {
	const ranges: Array<Range<number>> = values[OdesseusFilterKeys.DEPARTUREDATE].map((item: number) => {
		const value = options.dates.find((q: OdesseusSelectOption<Range<number>>) => q.value === item);
		if (!value?.raw) return null;

		return value.raw;
	}).filter(hasValue);

	const min = Math.min.apply(null, ranges.map((value: Range<number>) => value.from));
	const max = Math.max.apply(null, ranges.map((value: Range<number>) => value.to).filter(hasValue));

	return { min, max };
}

function getDurations(
	values: Pick<OdesseusSearchFormValues, OdesseusFilterKeys.DURATION>,
	options: OdesseusOptions,
): string {
	return values[OdesseusFilterKeys.DURATION]
		.map((value: number) => {
			const option = options.durations.find((q: OdesseusSelectOption<Range<number>>) => q.value === value);
			if (!option?.raw) return null;

			return option.raw;
		})
		.filter(hasValue)
		.sort((a: Range<number>, b: Range<number>) => a.from - b.from)
		.map((range: Range<number>) => (range.to ? `${range.from}-${range.to}` : range.from))
		.join(',');
}

const baseUrl = 'https://res.onlinevacationcenter.com/swift/cruise';
export function getSearchURL(values: OdesseusSearchFormValues, options: OdesseusOptions): string {
	const params: Array<string> = [
		'sidd=222056',
		'searchcruise=1',
		'lang=1',
		'destinationtype=All',
	];

	if (values[OdesseusFilterKeys.CRUISELINE].length) {
		params.push(`cruiseline=${values[OdesseusFilterKeys.CRUISELINE].join(',')}`);
	}

	if (values[OdesseusFilterKeys.SHIP].length) {
		params.push(`ship=${values[OdesseusFilterKeys.SHIP].join(',')}`);
	}

	if (values[OdesseusFilterKeys.DEPARTUREPORT].length) {
		params.push(`departureports=${values[OdesseusFilterKeys.DEPARTUREPORT].join(',')}`);
	}

	if (values[OdesseusFilterKeys.DESTINATION].length) {
		params.push(`destinations=${values[OdesseusFilterKeys.DESTINATION].join(',')}`);
	}

	if (values[OdesseusFilterKeys.DEPARTUREDATE].length) {
		const { min, max } = getDatesRange(values, options);

		params.push(`startdate=${OdyManager.toDateString(min)}`);
		if (max !== -Infinity) {
			params.push(`enddate=${OdyManager.toDateString(max)}`);
		}
	}

	if (values[OdesseusFilterKeys.DURATION].length) {
		params.push(`durations=${getDurations(values, options)}`);
	}

	return `${baseUrl}?${params.join('&')}`;
}
