import { Icon } from '@app/image/icon';

export const SEARCH_TEXT_PACKAGE_TAB = `
Browse sailings with bonus savings and special extras, or choose from our exclusive vacation packages with air,
hotel, tours and more - only available at Online Vacation Center!
`;

export enum ActiveTabKey {
	Cruises = 'cruises',
	Packages = 'packages',
	Hotels = 'hotels',
}

export enum SearchType {
	CruisesVIP = 0,
	CruisesAll = 1,
	CruisesPackages = 2,
	Hotels = 3,
	Tours = 4,
}

export const searchPaths: Record<SearchType, string> = {
	[SearchType.CruisesVIP]: '/search?type=4&order.direction=1&order.tag=Price&page=1',
	[SearchType.CruisesAll]: 'https://res.onlinevacationcenter.com/swift/cruise?sidd=222056&searchcruise=1&lang=1&destinationtype=All',
	[SearchType.CruisesPackages]: '/search?type=0,1,2&order.direction=1&order.tag=Price&page=1',
	[SearchType.Hotels]: 'https://hotels.onlinevacationcenter.com/luxury-travel-ideas/best-deals',
	[SearchType.Tours]: 'https://onlinevacationcenter.ttc.com/en-us/vacation-search/',
};

export function getIcon(type: SearchType) {
	switch (type) {
	case SearchType.CruisesVIP:
	case SearchType.CruisesAll:
	case SearchType.CruisesPackages:
		return Icon.Ship;
	case SearchType.Hotels:
		return Icon.Location;
	case SearchType.Tours:
		return Icon.Calendar;

	default:
		return undefined;
	}
}

export function isExternalPage(type: SearchType) {
	return type === SearchType.Hotels || type === SearchType.Tours;
}
