import * as React from 'react';

import { INTRO, sections } from '@app/pages/StaticPages/GeneralTerms/text';

import { Icon } from '@image/icon';

import '@app/scss/pages/documents-pages.scss';

export const GeneralTerms: React.FC = () => (
	<div className="documentation general-terms font-family_primary font-weight_regular text-size_extra-small">
		<h1 className="documentation__title text-size_soft-large content-valign_center">
			General Terms and Conditions - Please Read the Terms Below
		</h1>
		<p className="text-margin">
			Last Updated: February 20, 2023
		</p>
		<a
			href="https://www.onlinevacationcenter.com/pdf/generaltsandcs.pdf"
			target="_blank"
			rel="noopener noreferrer"
			className="general-terms__pdf-link"
		>
			<div className="general-terms__pdf-icon"><Icon.Pdf /></div>
			View print-friendly version.
		</a>
		<p>{INTRO}</p>
		{sections.map((section) => (
			<>
				<h4 className="documentation__subtitle font-weight_bold text-size_normal">
					{section.title}
				</h4>
				<div className="documentation__text-block">
					{section.description}
				</div>
			</>
		))}
	</div>
);
