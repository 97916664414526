import * as React from 'react';

import clsx from 'clsx';
import * as Yup from 'yup';
import {
	Field, FieldArray, FieldProps, useField,
} from 'formik';

import { TextField } from '@app/components/TextField/TextField';
import { FormTemplate } from '@app/pages/Forms/FormTemplate';
import { simpleBoolSchema, simpleStringSchema } from '@app/services/validationSchemas';
import { TextAreaField } from '@app/components/TextField/TextAreaField';
import { Button, ButtonVariant } from '@app/components/Button/Button';
import { Icon } from '@app/image/icon';

const subtitleText = `Send them an invitation to receive our current selection of exclusive cruise deals.
 It's free to join and is a convenient way for them to find out about outstanding vacation offers as they become available.`;

const Extra = () => (
	<>
		<h3 className="col-12 form__subtitle">Think your friends and family might like to receive our newsletter?</h3>
		<p className="col-12 col-md-8 form__subtitle" style={{ marginTop: 10 }}>{subtitleText}</p>
	</>
);

const newFriend: FriendInfo = { name: '', email: '' };

interface FriendInfo {
	name: string;
	email: string;
}

interface FriendInviteFormValues {
	name: string;
	email: string;
	friendsInfo: Array<FriendInfo>;
	note: string;
	isUSEmail: boolean;
	agreeSubmittingTerms: boolean;
}

const initialValues: FriendInviteFormValues = {
	name: '',
	email: '',
	friendsInfo: [newFriend],
	note: '',
	isUSEmail: false,
	agreeSubmittingTerms: false,
};

const validationSchema = Yup.object().shape({
	name: simpleStringSchema,
	email: Yup.string()
		.email('Invalid email format')
		.required('This field is required!'),
	friendsInfo: Yup.array()
		.of(
			Yup.object().shape({
				name: simpleStringSchema,
				email: Yup.string()
					.email('Invalid email format')
					.required('This field is required!'),
			}),
		)
		.min(1, 'You must specify at least 1 friend').required(),
	isUSEmail: simpleBoolSchema,
	agreeSubmittingTerms: simpleBoolSchema,
});

interface ITField {
	label: string;
	type: string;
	name: string;
	values: Array<FriendInfo>;
}

const TField = ({ label, values, ...props }: ITField) => {
	const [field, meta, helpers] = useField(props);
	const columnClassName = values.length > 1 ? 'col-sm-5' : 'col-sm-6';

	return (
		<div className={clsx('col-12 form-group', columnClassName)}>
			<TextField
				value={field.value}
				onChange={(value: string) => helpers.setValue(value, false)}
				onBlur={field.onBlur}

				title={label}
				inputProps={{ type: props.type }}

				text={meta.touched ? meta.error : undefined}
				error={Boolean(meta.error && meta.touched)}
			/>
		</div>
	);
};

const InnerForm = (): JSX.Element => (
	<div className="col-12">
		<div className="layout-container">
			<Field name="name">
				{({ field, form }: FieldProps<string, FriendInviteFormValues>) => (
					<div className="col-12 col-sm-6 form-group">
						<TextField
							value={field.value}
							onChange={(value: string) => form.setFieldValue(field.name, value, false)}
							onBlur={field.onBlur}

							title="Your Name*"

							text={form.touched.name ? form.errors.name : undefined}
							error={Boolean(form.errors.name && form.touched.name)}
						/>
					</div>
				)}
			</Field>
			<Field name="email">
				{({ field, form }: FieldProps<string, FriendInviteFormValues>) => (
					<div className="col-12 col-sm-6 form-group">
						<TextField
							value={field.value}
							onChange={(value: string) => form.setFieldValue(field.name, value, false)}
							onBlur={field.onBlur}

							title="Your Email Address*"
							inputProps={{ type: 'email' }}

							text={form.touched.email ? form.errors.email : undefined}
							error={Boolean(form.errors.email && form.touched.email)}
						/>
					</div>
				)}
			</Field>
			<Field name="note">
				{({ field, form }: FieldProps<number, FriendInviteFormValues>) => (
					<div className="col-12 form-group">
						<TextAreaField
							value={field.value}
							onChange={(value: string) => form.setFieldValue(field.name, value, false)}
							onBlur={field.onBlur}

							title="Comments"

							text={form.touched.note ? form.errors.note : undefined}
							error={Boolean(form.errors.note && form.touched.note)}
						/>
					</div>
				)}
			</Field>
			<FieldArray
				name="friendsInfo"
				render={(arrayHelpers) => {
					const items = arrayHelpers.form.values.friendsInfo;

					return (
						<>
							<div className="col-12">
								{items.map((item: FriendInfo, index: number) => {
									return (
									// eslint-disable-next-line react/no-array-index-key
										<div key={index} className="layout-container form__add-group-container">
											<TField name={`friendsInfo.${index}.name`} label="Friend's Name" type="text" values={items} />
											<TField name={`friendsInfo.${index}.email`} label="Friend's Email Address" type="email" values={items} />
											{items.length > 1 && (
												<div
													className="col-12 col-sm-2 form-group content-valign_center content-halign_center"
													onClick={() => arrayHelpers.remove(index)}
													title="Delete"
													style={{ cursor: 'pointer' }}
												>
													<Icon.Delete className="select-component__icon" fill="#F11158" />
												</div>
											)}
										</div>
									);
								})}
							</div>
							<div className="col-12 content-halign_center">
								<Button
									className="uppercase col-12 col-md-6"
									variant={ButtonVariant.Underlined}
									onClick={() => arrayHelpers.push(newFriend)}
								>
									+ Add More Friend
								</Button>
							</div>
						</>
					);
				}}
			/>
		</div>
	</div>
);

export const FriendInvite = () => (
	<FormTemplate
		title="Tell A Friend"
		extra={<Extra />}
		innerForm={<InnerForm />}
		initialValues={initialValues}
		validationSchema={validationSchema}
		extraCheckbox={{
			fieldName: 'isUSEmail',
			label: 'I certify the above email address(es) belongs to someone in the United States',
		}}
		onSubmit={console.log}
	/>
);
