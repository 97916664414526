import * as React from 'react';
import { Link } from 'react-router-dom';

import clsx from 'clsx';

import { MenuOption } from '@app/components/Header/Menu';
import { NavBarItem } from '@app/components/Header/NavBarItem';
import { Button, ButtonVariant } from '@app/components/Button/Button';
import { SearchInput } from '@app/components/SearchInput/SearchInput';
import { BurgerContext } from '@app/services/contexts';

import logo from '@image/logo.png';

interface NavBarProps {
	items: Array<MenuOption>;
}

export const NavBar: React.FC<NavBarProps> = (props: NavBarProps) => {
	const [isSearch, setIsSearch] = React.useState<boolean>(false);
	const {
		isShowBurger, toggleShowBurger, toggleShowSubscribeForm,
	} = React.useContext(BurgerContext);

	return (
		<nav className="site-header__bottom-block font-weight_semi-bold">
			<Link to="/">
				<img src={logo} alt="logo" className="site-header__logo" />
			</Link>
			<div className={clsx('site-header__right-block', isSearch && 'search')}>
				<div className="site-header__menu">
					<SearchInput
						onOpen={setIsSearch}
						placeholder="Offer Id"
						isOpen={isSearch}
					/>
				</div>
				<div className="site-header__menu-items">
					{props.items.map((item: MenuOption) => <NavBarItem key={item.title} {...item} />) }
				</div>
				<Button
					variant={ButtonVariant.Underlined}
					className={clsx('menu-item__burger', isShowBurger && 'icon_active')}
					onClick={() => {
						toggleShowBurger?.(!isShowBurger);
						toggleShowSubscribeForm?.(false);
						document.body.classList.toggle('no-scroll-body');
					}}
				>
					<div className="menu-item__burger-icon" />
				</Button>
			</div>
		</nav>
	);
};
