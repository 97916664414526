import * as React from 'react';

import { Button, ButtonVariant } from '@app/components/Button/Button';

import { Icon } from '@image/icon';

import '@app/scss/components/searchInput.scss';
import '@app/scss/components/formikInput.scss';

interface SearchInputProps {
	onOpen?: (value: boolean) => void;
	isOpen?: boolean;
	placeholder?: string;
}

const BASE_URL = 'https://www.onlinevacationcenter.com/offer_deal.cfm?offerId=';
const KEY_ENTER = 'Enter';
const OFFER_HARDCODE = 'EW16085';

const redirectToOffer = (offerId: string) => {
	if (offerId === OFFER_HARDCODE) {
		window.open(`https://www.signaturetravelnetwork.com/
		clients/emarketing2/ewizard/view.cfm?template_id=16085&agency_id=2954&utp=consumer&type=consumer`);
	} else {
		window.open(`${BASE_URL}${offerId}`);
	}
};

export const SearchInput: React.FC<SearchInputProps> = (props: SearchInputProps) => {
	const [offerId, setOfferId] = React.useState<string>('');

	return (
		<div className="search-block">
			<span
				className="search-block__button-search content-valign_center"
				onClick={() => props.onOpen?.(true)}
			>
				<Icon.Search className="menu-item__search search-block__button-search__icon" />
				{!props.isOpen && (
					<span
						className="search-block__button-search__text icon-margin font-family_primary font-weight_semi-bold text-size_normal"
					>
						Offer Id
					</span>
				)}
			</span>
			<div className="input-container light search-block__input">
				<input
					className="form-control"
					type="text"
					placeholder={props.placeholder}
					value={offerId}
					onChange={(event: React.ChangeEvent<HTMLInputElement>) => setOfferId(event.target.value)}
					onKeyUp={(event: React.KeyboardEvent<HTMLInputElement>) => {
						if (event.key === KEY_ENTER) redirectToOffer(offerId);
					}}
				/>
			</div>
			<div
				className="search-block__button-clean"
				onClick={() => {
					setOfferId('');
					props.onOpen?.(false);
				}}
			>
				<Icon.Clear className="search-block__button-clean__icon" />
			</div>
			<Button
				variant={ButtonVariant.Underlined}
				className="search-block__right-button-search content-valign_center"
				onClick={() => redirectToOffer(offerId)}
			>
				<span
					className="search-block__button-search__text font-family_primary font-weight_semi-bold text-size_normal"
				>
					Search
				</span>
			</Button>
		</div>
	);
};
