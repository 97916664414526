import { PackagesFormValues } from '@app/objects/Filters/Packages';
import { isArray } from '@app/objects/Utility';

export interface ISearchPackagesURLTraverser {
	filter: <TKey extends keyof PackagesFormValues>(key: TKey, value: PackagesFormValues[TKey]) => boolean;
	map: <TKey extends keyof PackagesFormValues>(key: TKey, value: PackagesFormValues[TKey]) => string;
}

export class DefaultTraverser implements ISearchPackagesURLTraverser {
	filter<TKey extends keyof PackagesFormValues>(key: TKey, value: PackagesFormValues[TKey]): boolean {
		return !isArray(value) || Boolean(value.length);
	}

	map<TKey extends keyof PackagesFormValues>(key: TKey, value: PackagesFormValues[TKey]): string {
		if (!isArray(value)) {
			return `${key}=${value}`;
		}

		return `${key}=${value.join(',')}`;
	}
}
