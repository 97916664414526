import * as React from 'react';

import clsx from 'clsx';

import error from '@image/icon/error.svg';
import info from '@image/icon/info.svg';
import success from '@image/icon/success.svg';

import '@app/scss/components/notification.scss';

export enum NotificationPlacement {
	TopLeft = 'topLeft',
	TopRight = 'topRight',
	BottomLeft = 'bottomLeft',
	BottomRight = 'bottomRight',
}

export enum NotificationType {
	Error = 1,
	Success = 2,
	Info = 3,
}

export interface NotificationProps {
	content: React.ReactNode;
	placement?: NotificationPlacement;
	className?: string;

	type?: NotificationType;
}

function getIcon(type: NotificationType) {
	let icon: string = '';

	switch (type) {
	case NotificationType.Error:
		icon = error;
		break;

	case NotificationType.Success:
		icon = success;
		break;

	default:
		icon = info;
	}

	return icon;
}

export const Notification: React.FC<NotificationProps> = (props: NotificationProps) => {
	const { placement = NotificationPlacement.BottomLeft } = props;

	return (
		<div className={clsx('notification', props.className, placement)}>
			<div className="notification__container">
				{props.type && <img src={getIcon(props.type)} alt="icon" className="notification__type-icon" />}
				{props.content}
			</div>
		</div>
	);
};
