import * as Yup from 'yup';

const message = 'This field is required';
const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

export const simpleStringSchema = Yup.string().required(message);
export const simpleNumberSchema = Yup.number().required(message);
export const phoneValidator = Yup.string()
	.matches(phoneRegExp, 'Phone number is not valid')
	.required('Phone number is required')
	.min(10, 'Too short')
	.max(11, 'Too long');
export const simpleBoolSchema = Yup.boolean().oneOf([true], message).required(message);
