type EventType = number | string;
type Handler<T extends EventType, TData> = (event: T, data: TData) => void;

export class EventBus<T extends EventType, TData> {
	private series: number = 0;
	private readonly subscribers: Record<number, Handler<T, TData>> = {};

	public subscribe(handler: Handler<T, TData>): number {
		const id = ++this.series;
		this.subscribers[id] = handler;

		return id;
	}

	public unsubscribe(id: number): void {
		delete this.subscribers[id];
	}

	public publish(event: T, data: TData): void {
		Object.values(this.subscribers).forEach((item: Handler<T, TData>) => item(event, data));
	}
}
