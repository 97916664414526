/* eslint-disable max-len */
import * as React from 'react';
import { v4 } from 'uuid';

import { LinkType, link } from '@app/pages/StaticPages/utils';
import { IListElement } from '@app/components/DropList/DropList';
import { RoutePaths } from '@app/utilities/RoutePathVars';

const phone = link('631-928-7655', LinkType.Phone);
const TEXT_I = (
	<>
		<p>
			The Company was organized under the laws of the State of Florida on July 15, 1996 under the name Alec Bradley Cigar Corporation. From inception through March 15, 2006, the Company was an importer and distributor of cigars and traded on the OTC Bulletin Board under the ticker ABDC. Without the ability to expand its operations, the board of directors determined that it was in the Company's best interests to dispose of all or substantially all of its cigar operations, which it did through the reverse merger with Online Vacation Center Holdings Corp., effective March 15, 2006.
		</p>
		<p>
			Under the share exchange agreement dated August 25, 2005, effective March 15, 2006, the Company issued to the Online Vacation Center Holdings, Inc. interest holders an aggregate of 15,000,000 shares of the Company's common stock in exchange for a 100% interest in Online Vacation Center Holdings, Inc. In connection with the share exchange, pursuant to an asset purchase agreement, the Company sold all of its assets (and transferred all of its liabilities) to Alan Rubin, the former President and CEO, for a total purchase price of 2,700,000 shares of the Company's common stock. The 2,700,000 shares were returned to the Company and have been canceled. For accounting purposes the consummation of these actions resulted in a reverse merger, and Online Vacation Center Holdings, Inc. is the accounting survivor and surviving business, however, the Company is the surviving legal entity. The Company also changed its ticker to ONVC.
		</p>
	</>
);
const TEXT_II = (
	<p>
		Kaufman, Rossin & Co., P.A.
		<br />
		3310 Mary Street
		<br />
		Suite 501
		<br />
		Miami FL 33133
	</p>
);
const TEXT_III = (
	<p>
		Tripp Scott
		<br />
		110 South East 6th Street
		<br />
		15th Floor
		<br />
		Fort Lauderdale, Florida 33301
	</p>
);
const TEXT_IV = 'The Company\'s cusip number is 014445';
const TEXT_V = (
	<p>
		If you are interested in working for Online Vacation Center Holdings Corp., please send your resume to {link('hr@onlinevacationcenter.com', LinkType.Mail)}, or learn more about employment opportunities and apply online via our {link(RoutePaths.careers, LinkType.Internal, 'Careers')} page.
	</p>
);
const TEXT_VI = (
	<p>
		If you hold shares of common stock through a broker, the Company does not, but your broker should. If you possess the stock certificate, please contact the Company's transfer agent, Manhattan Transfer Registrar Company at {phone}.
	</p>
);
const TEXT_VII = (
	<p>
		Registered stockholders should contact the Company's transfer agent and registrar: Manhattan Transfer Registrar Company 531 Cardens Court, Erie, CO 80516 or call them at {phone}. Please contact your stockbroker if your shares of common stock are held in a brokerage account.
	</p>
);
const TEXT_VIII = 'Online Vacation Center Holdings Corp. does not have a direct purchase plan. Please contact your stockbroker to purchase Online Vacation Center Holdings common stock.';

export const investorFAQs: Array<IListElement> = [
	{
		title: 'When and where was Online Vacation Holdings Corp. incorporated?',
		text: TEXT_I,
		id: v4(),
	},
	{
		title: 'Who is the Company\'s Independent Auditor?',
		text: TEXT_II,
		id: v4(),
	},
	{
		title: 'Who is the Company\'s Legal Counsel?',
		text: TEXT_III,
		id: v4(),
	},
	{
		title: 'What is the Company\'s cusip number?',
		text: TEXT_IV,
		id: v4(),
	},
	{
		title: 'How can I apply for employment at Online Vacation Center Holdings Corp.?',
		text: TEXT_V,
		id: v4(),
	},
	{
		title: 'Does the Company have a record of my stock trading activity?',
		text: TEXT_VI,
		id: v4(),
	},
	{
		title: 'As a registered stockholder, who should I contact to report my address change/report a lost stock certificate?',
		text: TEXT_VII,
		id: v4(),
	},
	{
		title: 'Can I purchase shares directly from the Company?',
		text: TEXT_VIII,
		id: v4(),
	},
];
