import * as React from 'react';
import { Link } from 'react-router-dom';

import clsx from 'clsx';

import '@app/scss/components/button.scss';
import { Loader } from '@app/components/Loader/Loader';

export enum ButtonVariant {
	Filled,
	Outlined,
	Underlined,
}

export enum Color {
	Dark = 'dark',
	Light = 'light',
}

export enum ActionMode {
	ExternalLink,
	InternalLink,
	Button,
}

export interface ButtonProps {
	variant: ButtonVariant;
	disabled?: boolean;
	tabIndex?: number;
	color?: Color,
	onClick?: () => void;
	link?: string;
	action?: ActionMode;

	children?: React.ReactNode;

	className?: string;
	style?: React.CSSProperties;
	loading?: boolean;
}

export interface LinkProps extends ButtonProps {
	btnClassName: string;
}

type DefaultButtonType = Omit<LinkProps, 'action' | 'color' | 'link'>;

export const DefaultButton: React.FC<DefaultButtonType> = (props: DefaultButtonType) => (
	<button
		type="button"
		className={clsx('btn text-size_medium', props.variant === ButtonVariant.Filled ? 'btn-red' : props.btnClassName, props.className)}
		disabled={props.disabled}
		tabIndex={props.tabIndex}
		onClick={props.onClick}
		style={props.style}
	>
		{props.loading ? <Loader small /> : props.children}
	</button>
);

export const ExternalLink: React.FC<LinkProps> = (props: LinkProps) => (
	<a href={props.link ?? '/'} target="_blank" rel="noreferrer">
		<DefaultButton {...props} btnClassName={props.btnClassName} />
	</a>
);

export const InternalLink: React.FC<LinkProps> = (props: LinkProps) => (
	<Link to={props.link ?? '/'}>
		<DefaultButton {...props} btnClassName={props.btnClassName} />
	</Link>
);

export const Button: React.FC<ButtonProps> = (props: ButtonProps) => {
	const btnClassName = props.variant === ButtonVariant.Outlined
		? clsx('btn-outline', props.color)
		: 'btn-line';

	if (props.action === ActionMode.ExternalLink) return <ExternalLink {...props} btnClassName={btnClassName} />;
	if (props.action === ActionMode.InternalLink) return <InternalLink {...props} btnClassName={btnClassName} />;

	return (
		<DefaultButton {...props} btnClassName={btnClassName} />
	);
};
