import * as React from 'react';

import moment from 'moment';
import { ColumnProps } from 'rc-table/lib/sugar/Column';
import clsx from 'clsx';

import TableBuilder from '@app/services/TableBuilder';
import {
	ActionMode, Button, ButtonVariant, Color,
} from '@app/components/Button/Button';
import { Nullable } from '@app/objects/Utility';
import { LinkType, link } from '@app/pages/StaticPages/utils';
import { formatPhone } from '@app/services/phone';

const warningStyle = 'details__warning-style';
const defaultValue = (className?: string) => (
	<p className={clsx('text-size_extra-small', className)}>
		<span className="font-weight_bold text-size_normal">Call Now*</span><br />
		{link(formatPhone('800-780-9002'), LinkType.Phone)}
	</p>
);

// eslint-disable-next-line max-len
export const TERMS = 'Pricing is per person, based on double occupancy, subject to availability and may change at any time. Government taxes, fuel surcharges (if applicable) and transfers are additional, unless otherwise noted. Please call for more information. Additional restrictions may apply. Online Vacation Center is a registered Seller of Travel with the States of Florida (ST-32947), California (CST-2064227-40) and Washington (WA SOT 602250083).';
export const NOTE = `Travelers are responsible for verifying Passport, Visa, 
Immunization and Covid-19 vaccination / testing requirements prior to departure. 
For the most up-to-date requirements for U.S. Citizens traveling abroad, visit the U.S. 
Department of State, Bureau of Consular Affairs website. 
Non-U.S. passport holders must check with their individual embassy regarding Visa and Immunization requirements.`;

export interface IMoreDetails{
	cruiseLineLogo: string;
	title: string;
	route: string;
	startDate: number;
	images: Array<string>;
	allDepartureDates: Array<DepartureDateInfo>;
	portInfo: Array<PortInfo>;
	departureDate: Array<GeneralDepartureDateInfo>;
}

export interface PortInfo {
	date: number;
	name: string;
	arriveTime: number;
	departTime: number;
}

export interface DepartureDateInfo {
	startDate: number;
	inside: Nullable<number>;
	oceanView: number;
	balcony: Nullable<number>;
	suite: Nullable<number>;
	link: string;
}

type GeneralDepartureDateInfo = Omit<DepartureDateInfo, 'startDate' | 'link'>

export const generalDepartureDateColumns: Array<ColumnProps<GeneralDepartureDateInfo>> = TableBuilder.shape<GeneralDepartureDateInfo>()
	.addColumn({
		title: 'Inside from',
		dataIndex: 'inside',
		align: 'center',
		render: () => defaultValue(warningStyle),
	})
	.addColumn({
		title: 'Oceanview from',
		dataIndex: 'oceanView',
		align: 'center',
		render: (oceanView: number) => (
			<p className={clsx('text-size_extra-small', warningStyle)}>
				<span className="font-weight_bold text-size_normal">$ {oceanView}</span><br />
				per person
			</p>
		),
	})
	.addColumn({
		title: 'Balcony from',
		dataIndex: 'balcony',
		align: 'center',
		render: () => defaultValue(warningStyle),
	})
	.addColumn({
		title: 'Suite from',
		dataIndex: 'suite',
		align: 'center',
		render: () => defaultValue(warningStyle),
	})
	.build();

export const portsInfoColumns: Array<ColumnProps<PortInfo>> = TableBuilder.shape<PortInfo>()
	.addColumn({
		title: 'Date',
		dataIndex: 'date',
		render: (date: number) => moment(date).format('MM/DD/YYYY'),
	})
	.addColumn({
		title: 'Port',
		dataIndex: 'name',
		render: (name: string) => name,
	})
	.addColumn({
		title: 'Arrive',
		dataIndex: 'arriveTime',
		render: (arriveTime: number) => { return arriveTime ? moment(arriveTime).format('LT') : '--'; },
	})
	.addColumn({
		title: 'Depart',
		dataIndex: 'departTime',
		render: (departTime: number) => { return departTime ? moment(departTime).format('LT') : '--'; },
	})
	.build();

export const departureDateColumns: Array<ColumnProps<DepartureDateInfo>> = TableBuilder.shape<DepartureDateInfo>()
	.addColumn({
		title: 'Starts',
		dataIndex: 'startDate',
		align: 'center',
		render: (startDate: number) => moment(startDate).format('MM/DD/YYYY'),
	})
	.addColumn({
		title: 'Inside from',
		dataIndex: 'inside',
		align: 'center',
		render: () => defaultValue(),
	})
	.addColumn({
		title: 'Oceanview from',
		dataIndex: 'oceanView',
		align: 'center',
		render: (oceanView: number) => `$ ${oceanView}`,
	})
	.addColumn({
		title: 'Balcony from',
		dataIndex: 'balcony',
		align: 'center',
		render: () => defaultValue(),
	})
	.addColumn({
		title: 'Suite from',
		dataIndex: 'suite',
		align: 'center',
		render: () => defaultValue(),
	})
	.addColumn({
		title: '',
		dataIndex: 'action',
		render: (link: string) => (
			<Button
				variant={ButtonVariant.Filled}
				color={Color.Dark}
				className="details__support-btn"
				action={ActionMode.InternalLink}
				link={link}
			>
				View Cruise
			</Button>
		),
	})
	.build();
