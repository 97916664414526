import { Nullable } from '@app/objects/Utility';
import {
	IListAction,
	IListReceiveAction,
	IListRequestErrorAction,
	TypeKeys,
} from '@app/store/MainPage/ListActions';
import { RootStore } from '@app/store';

export interface IStateItem<TEntity> {
	item: Nullable<TEntity>;
	loading: boolean;
	error: Nullable<string>;
	counter: number;
}

const initialState = {
	item: null,
	loading: false,
	error: null,
	counter: 0,
};

export function listReducer<TEntity>(state: IStateItem<TEntity> | undefined, action: IListAction) {
	if (!state) return initialState;

	switch (action.type) {
	case TypeKeys.REQUESTITEMS: {
		const newCount = state.counter + 1;

		return {
			...state,
			loading: Boolean(newCount),
			counter: newCount,
			error: null,
		};
	}
	case TypeKeys.RECEIVEITEMS: {
		const { payload }: IListReceiveAction = action as IListReceiveAction;
		const newCount = state.counter - 1;

		return {
			...state,
			loading: Boolean(newCount),
			counter: newCount,
			item: payload.item,
		};
	}
	case TypeKeys.RECEIVEERROR: {
		const { payload }: IListRequestErrorAction = action as IListRequestErrorAction;
		const newCount = state.counter - 1;

		return {
			...state,
			loading: Boolean(newCount),
			counter: newCount,
			error: payload.error,
		};
	}

	default:
		return state;
	}
}

export function getListReducer<TEntity, TKey extends keyof RootStore>(store: TKey) {
	return (state: IStateItem<TEntity> | undefined, action: IListAction) => {
		if (store !== action.store) return state || initialState;

		return listReducer<TEntity>(state, action);
	};
}
