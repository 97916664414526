import * as React from 'react';

import { useListData } from '@app/hooks/data/useListData';
import { Nullable } from '@app/objects/Utility';
import { Select } from '@app/components/Selects/Select';
import { Option } from '@app/components/Selects/Option';
import { Manager } from '@app/objects/Bridge/Manager';

interface ManagersSelectProps {
	value?: Nullable<number>;
	onChange: (value?: Nullable<number>) => void;
}

const location = 'api/bridge/v1/managers/list';
const noValue = -2;

export const ManagersSelect: React.FC<ManagersSelectProps> = (props: ManagersSelectProps) => {
	const { list, loading, fetch } = useListData<Manager, number>(location, props.value && props.value > 0 ? props.value : undefined);

	return (
		<Select
			placeholder="Select Your Personal Manager"
			value={!props.value ? noValue : props.value}
			onChange={(value: number) => props.onChange(value === noValue ? null : value)}
			loading={loading}
			allowClear={false}
			onPopupScroll={fetch}
		>
			<>
				<Option value={noValue}>None</Option>
				{
					list.map((item: Manager) => (
						<Option key={item.id} value={item.id}>
							{`${item.firstName} ${item.lastName}`}
						</Option>
					))
				}
			</>
		</Select>
	);
};
