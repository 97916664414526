import * as React from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { v4 } from 'uuid';
import Table from 'rc-table';

import {
	ActionMode, Button, ButtonVariant, Color,
} from '@app/components/Button/Button';
import { RoutePaths } from '@app/utilities/RoutePathVars';

import '@app/scss/pages/search/moreDetailsPage.scss';
import { Carousel } from '@app/components/Carousel/Carousel';
import {
	IMoreDetails,
	NOTE,
	TERMS, departureDateColumns, generalDepartureDateColumns, portsInfoColumns,
} from '@app/pages/MoreDetailsPage/utils';
import { BurgerContext } from '@app/services/contexts';

import { Icon } from '@image/icon';

const table1 = [
	{
		inside: null,
		oceanView: 491,
		balcony: null,
		suite: null,
	},
];

const table2 = [
	{
		date: 358757643,
		name: 'Boston, Massachusetts',
		arriveTime: 986748200334,
		departTime: 936748200334,
	},
];

const table3 = [
	{
		startDate: 936748200334,
		inside: null,
		oceanView: 491,
		balcony: null,
		suite: null,
		link: '',
	},
	{
		startDate: 936748202334,
		inside: null,
		oceanView: 493,
		balcony: null,
		suite: null,
		link: '',
	},
];

const item: IMoreDetails = {
	cruiseLineLogo: 'https://www.onlinevacationcenter.com/images/logo_images/Royal Caribbean Intl.png',
	title: 'Canada/New England Cruise',
	route: '7 Nights Boston to Boston on Grandeur of the Seas',
	startDate: 986748200334,
	images: [
		'https://u.9111s.ru/uploads/202302/05/0b18c76742b7a1504d57c567278dd5e9.jpg',
		'http://fedcruise.com/wp-content/uploads/2021/07/10441403_m.jpg'],
	allDepartureDates: table3,
	portInfo: table2,
	departureDate: table1,
};

const settings = {
	dots: true,
	infinite: true,
	slidesToShow: 1,
	slidesToScroll: 1,
	autoplay: true,
	autoplaySpeed: 5000,
};

export const MoreDetailsPage: React.FC = () => {
	const generalDepartureInfoRef = React.useRef<HTMLDivElement>(null);
	const otherDepartureInfoRef = React.useRef<HTMLDivElement>(null);
	const { toggleShowBurger, toggleShowSubscribeForm } = React.useContext(BurgerContext);

	const print = (value: string, secondValue: string) => {
		window.stop();
		const originalContents = document.body.innerHTML;
		document.body.innerHTML = `${value}${secondValue}`;
		window.print();
		document.body.innerHTML = originalContents;
	};

	return (
		<div className="details col-12 font-family_primary text-size_extra-small">
			<div className="marquee">
				<span>
					<Icon.Fire />
					May qualify for a bonus offer. Call for details.
				</span>
			</div>

			<div className="layout-container content-valign_center">
				<div className="details__container col-12 col-lg-11">
					<div className="details__right-block col-12 col-lg-3">
						<div className="details__right-block__btns">
							<Button
								variant={ButtonVariant.Filled}
								color={Color.Dark}
								className="details__support-btn"
								action={ActionMode.InternalLink}
								link={RoutePaths.requestQuotePage}
							>
								Quote Request
							</Button>
							<Button
								variant={ButtonVariant.Filled}
								className="details__support-btn"
							>
								Shore excursions
							</Button>
							<Button
								variant={ButtonVariant.Filled}
								className="details__support-btn"
								action={ActionMode.InternalLink}
								link={RoutePaths.contactPage}
							>
								Need Help?
							</Button>
						</div>

						<div className="details__carousel-wrapper">
							<Carousel className="details-carousel" total={0} settings={settings}>
								{item.images.map((item: string) => <img src={item} key={v4()} alt="Trip" />)}
							</Carousel>
							<div className="details__icons">
								<a
									onClick={() => {
										if (generalDepartureInfoRef.current?.innerHTML && otherDepartureInfoRef.current?.innerHTML) {
											print(generalDepartureInfoRef.current?.innerHTML, otherDepartureInfoRef.current?.innerHTML);
										}
									}}
								>
									<Icon.Printer />
								</a>
								<Link to={RoutePaths.friendForward}><Icon.Share /></Link>
								<a
									href="https://www.facebook.com/OnlineVacationCenter"
									className="footer__social-icon-item"
									target="_blank"
									rel="noreferrer"
								>
									<Icon.FacebookFill fill="#090E2C" />
								</a>
								<a
									href="https://www.facebook.com/OnlineVacationCenter"
									className="footer__social-icon-item"
									target="_blank"
									rel="noreferrer"
								>
									<Icon.TwitterFill fill="#090E2C" />
								</a>
							</div>
							<p className="details__right-block__text">
								<span className="font-weight_bold">Please Note:</span> {NOTE}
							</p>
							<Button
								variant={ButtonVariant.Filled}
								className="details__support-btn"
								onClick={() => {
									window.scroll(0, 0);
									toggleShowBurger?.(true);
									toggleShowSubscribeForm?.(true);
								}}
							>
								Free Vacation NewsLatter
							</Button>
						</div>
					</div>
					<div className="details__center-block col-12 col-lg-9" ref={generalDepartureInfoRef}>
						<div className="details__header-block">
							<img src={item.cruiseLineLogo} alt="Cruise Line" className="details__header-block__image" />
							<h1 className="text-size_large font-weight_semi-bold">{item.title}</h1>
							<p className="text-size_normal">{item.route}</p>
							<p className="text-size_normal">Starts {moment(item.startDate).format('LL')}</p>
						</div>

						<Table
							columns={generalDepartureDateColumns}
							data={item.departureDate}
							className="travel-insurance__table details__table text-size_normal"
						/>

						<div className="details__btn">
							<a href="#otherVariants">
								<Button
									variant={ButtonVariant.Underlined}
								>
									Change Departure Date
								</Button>
							</a>
							<Button
								variant={ButtonVariant.Filled}
								className=""
								action={ActionMode.InternalLink}
								link={RoutePaths.requestQuotePage}
							>
								Check Availability
							</Button>
						</div>
						<Table
							columns={portsInfoColumns}
							data={item.portInfo}
							className="travel-insurance__table text-size_normal"
						/>
					</div>
				</div>
				<div className="col-12 col-lg-11" ref={otherDepartureInfoRef} id="otherVariants">
					<h2 className="details__bottom-block__title text-size_large font-weight_regular">
						<b>Departure</b> Dates <i className="text-size_normal">Itinerary may vary by date</i>
					</h2>

					<Table
						columns={departureDateColumns}
						data={table3}
						className="travel-insurance__table details__table text-size_normal"
					/>

					<div className="details__terms text-size_xx-small">
						<span className="font-weight_bold">Terms and Conditions:</span> {TERMS}
					</div>
				</div>
			</div>
		</div>
	);
};
