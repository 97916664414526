import * as React from 'react';

import clsx from 'clsx';

import '@app/scss/components/checkbox.scss';

export interface RadioProps {
	checked: boolean;
	onChange?: () => void;

	label?: React.ReactNode;
	className?: string;
	disabled?: boolean;

	containerClassName?: string;

	text?: string; // This is an additional description text
	error?: boolean; // If true - component is marked as 'with error'
}

export const Radio: React.FC<RadioProps> = (props: RadioProps) => {
	const id = React.useId();

	return (
		<>
			<div className={clsx('content-halign_center checkbox radio', props.error && 'error-checkbox', props.containerClassName)}>
				<input
					id={id}
					type="radio"

					checked={props.checked}
					onChange={() => props.onChange?.()}

					disabled={props.disabled}
					className={props.className}
				/>
				<label htmlFor={id} className="icon-margin">{props.label}</label>
			</div>
			{
				props.error && (
					<div className="checkbox__error-text font-family_primary font-weight_light text-size_extra-small">
						{props.text}
					</div>
				)
			}
		</>
	);
};
