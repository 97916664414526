import React from 'react';

import { PvmLink } from '../../../../constants/url.constants';

export const PersonalManager: React.FC = () => {
	function redirectToAnotherUrl() {
		window.location.href = `${PvmLink}`;
	}

	return (
		<div className="vacation-main">
			<div className="vacation">
				<h1><b>Meet Our</b> <span>Personal</span> <b>VACATION</b> <span className="managersText">Managers</span></h1>
			</div>
			<div className="personalManager">
				<div className="textItem">
					<p>Did you know that our sales team has over a 95% positive rating on customer satisfaction surveys?</p>
					<p>
						Learn more about our Personal Vacation Managers' travel industry certifications, experience and education.
					</p>
					<button type="button" className="btnWarning" onClick={redirectToAnotherUrl}>Get to Know Them</button>
				</div>
			</div>
		</div>
	);
};
