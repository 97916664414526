import * as React from 'react';

import * as Yup from 'yup';
import {
	Field,
	FieldProps,
	Form,
	Formik,
} from 'formik';
import { useInView } from 'react-intersection-observer';
import clsx from 'clsx';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

import { Button, ButtonVariant } from '@app/components/Button/Button';
import { TextField } from '@app/components/TextField/TextField';
import { subscribe } from '@app/services/formData';
import { Notification, NotificationPlacement } from '@app/components/Notification/Notification';
import { Anchor } from '@app/components/Anchor/Anchor';
import { Anchors } from '@app/objects/Anchors';
import { BurgerContext } from '@app/services/contexts';

import {
	getNotification,
	initialSubscribeValues,
	SubscribeData,
	SubscribeNotification,
	SubscribeResponse,
	ErrorMessage,
} from '@app/objects/Subscribe';

import { Icon } from '@image/icon';
import '@app/scss/pages/main/subscribe.scss';

const SUBSCRIBE_TEXT = `Don't miss out on any of our amazing specials
and exclusive offers! Online Vacation Center will send them right to your inbox.`;

const validationSchema = Yup.object().shape({
	email: Yup.string().email('Invalid email format').required('This field is required!'),
});

export interface MessageProps {
	text: React.ReactNode;
	onClose: (value?: SubscribeNotification) => void
}

export const Message: React.FC<MessageProps> = (props: MessageProps) => (
	<>
		<p className="font-family_primary text-size_small line-height_large">{props.text}</p>
		<Button
			variant={ButtonVariant.Underlined}
			className="subscribe__message-btn"
			onClick={() => props.onClose(undefined)}
		>
			<Icon.Clear fill="white" />
		</Button>
	</>
);

interface SubscribeInnerProps {
	// onChange: (value?: SubscribeNotification) => void;
	title?: React.ReactNode;
}

/**
 * Wrap executeRecaptcha with promise - because really, it returns promise-like (with no finally)
 * @param promiselike - object to be wrapped
 */
export function wrap(promiselike: Promise<string> | undefined): Promise<string> {
	return new Promise<string>((resolve, reject) => {
		if (!promiselike) {
			reject(new Error('ReCaptcha setup is incorrect - missing recaptcha handler'));

			return;
		}

		promiselike.then(resolve).catch(reject);
	});
}

const defaultTitle = (
	<div className="subscribe__title font-family_primary condensed font-weight_semi-bold text-size_hard-large">
		<b>Sign Up</b>
		<i className="font-family_decorative font-weight_regular"> to Save</i>
	</div>
);

export const SubscribeInner: React.FC<SubscribeInnerProps> = (props: SubscribeInnerProps) => {
	const { title = defaultTitle } = props;
	const { executeRecaptcha } = useGoogleReCaptcha();
	const { alert, changeAlert } = React.useContext(BurgerContext);

	React.useEffect(() => {
		setTimeout(() => changeAlert?.(undefined), 5000);
	}, [alert]);

	return (
		<>
			{title}
			<p className="subscribe__description font-family_primary font-weight_regular text-size_medium line-height_large">
				{SUBSCRIBE_TEXT}
			</p>
			<Formik
				initialValues={initialSubscribeValues}
				validationSchema={validationSchema}
				onSubmit={(values: SubscribeData, { setSubmitting, resetForm }) => {
					wrap(executeRecaptcha?.())
						.then((token: string) => subscribe({ ...values, agreeWithPP: true }, token))
						.then((response: SubscribeResponse) => {
							changeAlert?.(getNotification(response.status));
							resetForm();
						})
						.catch(() => changeAlert?.(ErrorMessage))
						.finally(() => setSubmitting(false));
				}}
			>
				{(formikBag) => (
					<Form id="subscribe-form">
						<Field name="email">
							{({ field, form }: FieldProps<string, SubscribeData>) => (
								<div className="form-group">
									<TextField
										value={field.value}
										onChange={(value: string) => form.setFieldValue(field.name, value, false)}
										onBlur={field.onBlur}

										title="Email Address"
										Prefix={Icon.Email}

										text={form.errors.email}
										error={Boolean(form.errors.email)}
									/>
								</div>
							)}
						</Field>
						<Button
							className="subscribe__btn"
							variant={ButtonVariant.Filled}
							loading={formikBag.isSubmitting}
							onClick={() => formikBag.handleSubmit()}
						>
							Subscribe
						</Button>
					</Form>
				)}
			</Formik>
			{alert && changeAlert && (
				<Notification
					type={alert?.type}
					content={<Message text={alert.message} onClose={changeAlert} />}
					placement={NotificationPlacement.BottomRight}
					className="col-10 col-sm-3"
				/>
			)}
		</>
	);
};

export const Subscribe: React.FC = () => {
	const { ref, inView } = useInView({
		threshold: 0.3,
		fallbackInView: true,
	});

	return (
		<Anchor.Tag anchor={Anchors.Subscribe}>
			<div className={clsx('subscribe', inView && 'animated')} ref={ref}>
				<div className="subscribe__container">
					<SubscribeInner />
				</div>
				<div className="subscribe__fog" />
			</div>
		</Anchor.Tag>
	);
};
