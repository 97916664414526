import * as React from 'react';
import { useLocation } from 'react-router-dom';

import clsx from 'clsx';

import { SocialIcons, SocialIcon } from '@app/components/SocialIcons/SocialIcons';
import { LinkType, MenuOption } from '@app/components/Header/Menu';
import { NavBarItem } from '@app/components/Header/NavBarItem';
import { SearchInput } from '@app/components/SearchInput/SearchInput';
import { Anchor } from '@app/components/Anchor/Anchor';
import { Anchors } from '@app/objects/Anchors';
import { useDimensions } from '@app/hooks/useDimensions';
import { SubscribeInner } from '@app/pages/MainPage/Subscribe/Subscribe';
import { BurgerContext } from '@app/services/contexts';
import { RoutePaths } from '@app/utilities/RoutePathVars';

import { Icon } from '@image/icon';

import '@app/scss/components/burgerMenu.scss';

interface BurgerProps {
	items: Array<MenuOption>;
	scrollToSubscribe?: boolean;
}

const socialIconsFill: Array<SocialIcon> = [
	{
		Icon: Icon.TwitterFill,
		link: 'https://twitter.com/VacationManager',
	},
	{
		Icon: Icon.FacebookFill,
		link: 'https://www.facebook.com/OnlineVacationCenter',
	},
	{
		Icon: Icon.InstagramFill,
		link: 'https://www.instagram.com/onlinevacationcenter/',
	},
	{
		Icon: Icon.PinterestFill,
		link: 'https://www.pinterest.com/vacationmanager/',
	},
];

const burgerList = [
	{
		title: 'Solo Travelers Club',
		link: '/solo_contact2.cfm',
		type: LinkType.External,
	},
	{
		title: 'Group Getaways',
		link: 'https://travel.onlinevacationcenter.com/acton/media/12605/groupgetaways',
		type: LinkType.External,
	},
	{
		title: 'Travel Blog',
		link: 'https://blog.onlinevacationcenter.com/',
		type: LinkType.External,
	},
];

const noScroll = 'no-scroll-body';

export const BurgerMenu: React.FC<BurgerProps> = (props: BurgerProps) => {
	const ref = React.useRef<HTMLElement>(null);
	const dimensions = useDimensions(ref);
	const location = useLocation();
	const {
		isShowBurger,
		toggleShowBurger,
		isShowSubscribeForm,
		toggleShowSubscribeForm,
	} = React.useContext(BurgerContext);

	React.useEffect(() => {
		if (isShowBurger) document.body.classList.toggle(noScroll, isShowBurger);
	}, [isShowBurger]);

	React.useEffect(() => {
		toggleShowBurger?.(false);
	}, [location.pathname]);

	return (
		<div className={clsx('burger', isShowBurger && '_active')}>
			<div className="burger__header">
				<SearchInput placeholder="Offer ID Search" />
				<div className="burger__header-anchor" />
			</div>
			{!isShowSubscribeForm
				? (
					<div className="burger__content col-10">
						<div className="burger__mobile-content layout-container">
							<div className="burger__link-container col-12 col-xs-6 col-lg-3">
								<a
									href={RoutePaths.requestQuotePage}
									className="burger__link font-family_primary condensed font-weight_bold text-size_medium"
								>
									Request a Quote
								</a>
							</div>
							<div className="burger__link-container col-12 col-xs-6 col-lg-3">
								<a
									href="/cp/login.cfm"
									className="burger__link font-family_primary condensed font-weight_bold text-size_medium"
								>
									Manage Trips
								</a>
							</div>
							<div
								className="burger__link-container col-12 col-xs-6 col-lg-3"
								onClick={() => {
									if (props.scrollToSubscribe) {
										toggleShowBurger?.(false);
										document.body.classList.remove(noScroll);
									} else {
										toggleShowSubscribeForm?.(true);
									}
								}}
							>
								<Anchor.Link
									anchor={Anchors.Subscribe}
									className="burger__link font-family_primary condensed font-weight_bold text-size_medium"
									offset={dimensions.height}
								>
									Subscribe
								</Anchor.Link>
							</div>
						</div>
						<div className="burger__mobile-content burger__mobile-links layout-container">
							{props.items.map((item: MenuOption) => (
								<div key={item.title} className="burger__link-container col-12 col-xs-6 col-lg-3">
									<NavBarItem {...item} className="burger__link font-family_primary condensed font-weight_bold text-size_medium" />
								</div>
							)) }
						</div>
						<div className="burger__main-content burger__main-links layout-container">
							{burgerList.map((item: MenuOption) => (
								<div key={item.title} className="burger__link-container col-12 col-xs-6 col-lg-3">
									<NavBarItem {...item} className="burger__link font-family_primary condensed font-weight_bold text-size_medium" />
								</div>
							))}
						</div>
					</div>
				) : (
					<div className="burger__subscribe-form col-10 col-lg-6 content-valign_center content-halign_center">
						<SubscribeInner />
					</div>
				)}
			<div className="burger__footer">
				<div className="burger__footer-container">
					<SocialIcons list={socialIconsFill} />
				</div>
			</div>
		</div>
	);
};
