import * as React from 'react';

import { useItemFetchStore } from '@app/hooks/data/useFetchStore/useItemFetchStore';

import { Nullable } from '@app/objects/Utility';
import { FetchStore } from '@app/services/fetch/FetchStore';
import { RequestError } from '@app/services/errors/RequestError';

export interface DataEnvelope<T> {
	item: Nullable<T>;
	error: Nullable<string>;
	loading: boolean;

	load: (url?: string) => void;
}

interface TaskEnvelope<T> {
	task: Promise<T>,
	controller: AbortController;
}

function getTask<T>(url: string): TaskEnvelope<T> {
	const result = {
		status: -1,
		text: '',
		ok: false,
	};

	const controller: AbortController = new AbortController();
	const task = fetch(url, {
		method: 'GET',
		credentials: 'same-origin',
		mode: 'cors',
		signal: controller?.signal,
	})
		.then((response: Response) => {
			result.status = response.status;
			result.ok = response.ok;

			return response.json();
		})
		.then((data) => {
			if (!result.ok) {
				if (result.text === 'abort') throw new RequestError(-1, 'Abort');

				throw new RequestError(result.status, data);
			}

			return data;
		});

	return {
		task,
		controller,
	};
}

type UseStore<T> = () => FetchStore<T>;

export function useData<T>(url: string, useStore: UseStore<T> = useItemFetchStore): DataEnvelope<T> {
	const store = useStore();

	const ref = React.useRef<Nullable<AbortController>>(null);
	const load = (_url: string = url) => {
		store.loading = true;
		store.error = null;

		if (ref.current) {
			ref.current.abort();
		}

		const {
			task,
			controller,
		} = getTask<T>(_url);

		ref.current = controller;
		task
			.finally(() => {
				ref.current = null;
			})
			.then((item: T) => {
				store.item = item;
			})
			.catch((error: Error) => {
				store.error = error.message;
			})
			.finally(() => {
				store.loading = false;
			});
	};

	React.useEffect(() => {
		load();

		return () => {
			ref.current = null;
		};
	}, []);

	return {
		item: store.item,
		error: store.error,
		loading: store.loading,
		load,
	};
}
