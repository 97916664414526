import * as React from 'react';

import '@app/scss/pages/documents-pages.scss';
import { INTRO, sections } from '@app/pages/StaticPages/WhyChooseOVC/text';

import marker from '@image/whyovcArrow.png';

export const WhyChooseOVC: React.FC = () => (
	<div className="documentation choose-ovc font-family_primary font-weight_regular">
		<h1 className="text-size_soft-large content-valign_center">
			Why Choose Online Vacation Center?
		</h1>
		<p className="text-size_extra-small">
			{INTRO}
		</p>
		{
			sections.map((section) => (
				<>
					<h4 className="documentation__subtitle font-weight_bold text-size_normal">
						<img src={marker} alt="marker" />
						{section.title}
					</h4>
					<div className="documentation__text-block text-size_extra-small">
						{section.description}
					</div>
				</>
			))
		}
	</div>
);
