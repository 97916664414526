import * as React from 'react';

import clsx from 'clsx';

import Dropdown from 'rc-dropdown';
import Menu, { Item as MenuItem } from 'rc-menu';
import { SelectInfo } from 'rc-menu/lib/interface';

import { BaseSorterProps } from '@app/components/Sorter/Sorter';
import { SorterOption } from '@app/objects/Sorter';

import '@app/scss/pages/search/searchList.scss';
import 'rc-dropdown/assets/index.css';

import arrow from '@image/icon/chevron_down.svg';

type DesktopSorterProps = Omit<BaseSorterProps, 'open' | 'onClose'>

export const DesktopSorter: React.FC<DesktopSorterProps> = (props: DesktopSorterProps) => (
	<li className="content-valign_bottom search-list__dropdown-block font-weight_regular">
		<span>Sort by</span>
		<div className="search-list__dropdown-container">
			<Dropdown
				trigger={['hover']}
				overlay={(
					<Menu
						onSelect={(info: SelectInfo) => {
							const option = props.options.find((item: SorterOption) => item.value.toString() === info.key);

							if (option) {
								props.onChange(option.value);
							}
						}}
						className="search-list__dropdown-menu"
					>
						{
							props.options.map((item: SorterOption) => (
								<MenuItem
									key={item.value}
									className={clsx(props.value === item.value && 'rc-menu-item-selected')}
								>
									{item.label}
								</MenuItem>
							))
						}
					</Menu>
				)}
				animation="slide-up"
			>
				<div className="search-list__dropdown-block__content">
					<p>{props.value !== undefined && props.value < props.options.length ? props.options[props.value].label : ''}</p>
					<img src={arrow} alt="Open/Close" className="search-list__dropdown-arrow" />
				</div>
			</Dropdown>
		</div>
	</li>
);
