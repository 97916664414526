import * as React from 'react';

import { Nullable } from '@app/objects/Utility';

interface PriceTagProps {
	name: string;
	price: Nullable<number>;
	tour: boolean;
}

export const PriceTag: React.FC<PriceTagProps> = (props: PriceTagProps) => {
	if (!props.price) return null;

	return (
		<div
			className={`no-padding
				font-family_primary
				font-weight_regular
				text-size_extra-small
				line-height_large
				content-valign_top
				search-list-card__price-info`}
		>
			{
				!props.tour
					? (
						<>{props.name}&nbsp;starting&nbsp;from</>
					) : null
			}
			<div className="font-family_primary condensed font-weight_semi-bold text-size_soft-large line-height_medium">
				${Math.round(props.price).toLocaleString('en-IN')}
				<i className="font-family_decorative font-weight_regular text-size_medium">&nbsp;USD</i>
			</div>
			{
				!props.tour
					? (
						<>per&nbsp;person</>
					) : null
			}
		</div>
	);
};
