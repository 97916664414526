import * as React from 'react';

export enum SiteKey {
	FB,
	TW,
	INST,
	PIN,
	BLOG,
	SITE,
}

export interface ILeaderShip {
	name: string;
	grade: string;
	text: string;
}

export interface ISite {
	key: SiteKey;
	link: string;
}

export interface IBrand {
	logo: string;
	description: string;
	social: Array<ISite>;
	className: string;
}

export interface ICommunityOutreach {
	icon: string;
	text: string;
}

export interface IAccolades {
	icon: string;
	link: string;
	text: React.ReactNode;
	style?: React.CSSProperties;
}

export interface IPartners {
	icon: string;
	link?: string;
}
