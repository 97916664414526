import React from 'react';
import { useSelector } from 'react-redux';

import { Icon } from '@app/image/icon';
import { RootStore } from '@app/store';
import { Chat } from '@app/components/Chat/Chat';

import '@app/scss/components/contactsWidget.scss';

export const ContactsWidget: React.FC = () => {
	const phone = useSelector((state: RootStore) => state.header.item?.phone);

	return (
		<section className="contacts-widget">
			<div className="contacts-widget__container">
				<a href={`tel:${phone}`}>
					<div className="contacts-widget__item phone">
						<div className="contacts-widget__icon-wrapper"><Icon.Phone fill="white" /></div>
						Phone
					</div>
				</a>
				<a href="mailto:customerservice@onkinevacationcenter.com?subject=Website Assistance">
					<div className="contacts-widget__item email">
						<div className="contacts-widget__icon-wrapper"><Icon.Email fill="white" /></div>
						Email
					</div>
				</a>
				<div className="contacts-widget__item chat">
					<Chat />
					<div className="contacts-widget__icon-wrapper"><Icon.Chat /></div>
					Chat
				</div>
			</div>
		</section>
	);
};
