import * as React from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { PanelWrapper } from '@app/components/Filters/FilterComponents/PanelWrapper';
import { useSelector } from '@app/hooks/useSelector';

import { PackagesFilterOption, PackagesFilterValue } from '@app/objects/CruisePackages';
import { PackagesFilterKeys, PackagesFormValues, isPackagesKey } from '@app/objects/Filters/Packages';
import { List, SearchResult } from '@app/objects/List';
import { Nullable, isArray } from '@app/objects/Utility';
import { SearchPackagesPageContent } from '@app/pages/SearchPage/SearchPackagesPageContent';
import { routesToListItems } from '@app/services/adaptors/SearchListAdaptor';
import { request, RequestConfig } from '@app/services/api';
import { getActionCreators } from '@app/store/MainPage/ListActions';
import { useDebounce } from '@app/hooks/useDebounce';

import { SelectableListItemProps } from '@app/components/SelectableList/SelectableList';
import { useSearchPackagesURLManager } from '@app/hooks/useSearchPackagesURLManager';
import { FilterURLTraverser } from '@app/services/searchPackages/FilterURLTraverser';
import { ListURLTraverser } from '@app/services/searchPackages/ListURLTraverser';
import { getOption, getOrder } from '@app/pages/SearchPage/SorterOptions';
import { DispatchType, RootStore } from '@app/store';

import { Icon } from '@image/icon';

function getOptions<TValue extends PackagesFilterValue>(
	list?: Array<PackagesFilterOption<TValue>>,
	children?: Array<PackagesFilterOption<TValue, TValue>>,
): Array<SelectableListItemProps<TValue>> {
	if (!list) return [];

	const res: Array<SelectableListItemProps<TValue>> = list
		.map((item: PackagesFilterOption<TValue>) => ({
			value: item.id,
			label: `${item.name} (${item.count})`,
		}));

	if (children) {
		res.forEach((item: SelectableListItemProps<TValue>) => {
			// eslint-disable-next-line no-param-reassign
			item.list = children
				.filter((child: PackagesFilterOption<TValue, TValue>) => child.parentId === item.value)
				.map((child: PackagesFilterOption<TValue, TValue>) => ({
					value: child.id,
					label: `${child.name} (${child.count})`,
				}));
		});
	}

	return res;
}

const initialPage: number = 1;
const pageSize: number = 10;

export const SearchPackagesPage = () => {
	const location = useLocation();
	const navigate = useNavigate();
	const store = useSelector((state: RootStore) => state.packagesFilters);

	const manager = useSearchPackagesURLManager();

	const [loading, setLoading] = React.useState<boolean>(false);
	const [list, setList] = React.useState<Nullable<List>>(null);
	const [filters, setFilters] = React.useState<PackagesFormValues>(() => manager.getAll());

	const dispatch = useDispatch<DispatchType<'packagesFilters'>>();
	function fetchData() {
		const filtersQuery = manager.toString(new FilterURLTraverser());
		const listQuery = manager.toString(new ListURLTraverser(pageSize));

		const filtersUrl = `api/search/v1/packages/filters?${filtersQuery}`;
		const listUrl = `api/search/v1/packages?${listQuery}`;
		const config: RequestConfig = {
			tag: 'package-request',
		};

		setLoading(true);

		// Fetch filters
		const factory = getActionCreators(filtersUrl, 'packagesFilters');
		dispatch(factory.request());

		request(listUrl, config)
			.then((res: SearchResult) => {
				setList({
					total: {
						items: res.routesTotal,
						subItems: res.voyagesTotal,
					},
					list: routesToListItems(res.routes),
					offset: res.offset,
				});
			})
			.catch((err: Error) => {
				if (err.message !== 'The user aborted a request.') {
					console.warn('Get cruises list failed: ', err.message);
				}
			})
			.finally(() => setLoading(false));
	}
	const debounce = useDebounce<PackagesFormValues>(fetchData, 750);

	React.useEffect(() => {
		const url = `?${manager.toString()}`;
		const filters = manager.getAll();
		setFilters(filters);

		if (url !== location.search) {
			navigate(url);
		}
	}, [location.search]);

	React.useEffect(() => {
		debounce(filters);
	}, [filters]);

	const handleReset = () => {
		manager.reset();
		const url = manager.toString();

		navigate(`?${url}`);
	};

	const handleSearch = (values: Partial<PackagesFormValues>) => {
		manager.setAll(values);
		const url = manager.toString();

		if (location.search !== url) {
			navigate(`?${url}`);
		}
	};

	const hasSelection = Object.keys(filters)
		.filter(isPackagesKey)
		.filter((key: keyof PackagesFormValues) => isArray(filters[key]))
		.filter((key: keyof PackagesFormValues) => (filters[key] as Array<never>).length).length > 0;

	return (
		<SearchPackagesPageContent
			loading={loading}
			list={list}
			sorter={getOption(filters[PackagesFilterKeys.TAG], filters[PackagesFilterKeys.DIRECTION]).value}
			onChangeSorter={(value: number) => {
				const order = getOrder(value);
				if (!order) return;

				handleSearch({
					[PackagesFilterKeys.PAGE]: initialPage,
					[PackagesFilterKeys.TAG]: order.tag,
					[PackagesFilterKeys.DIRECTION]: order.direction,
				});
			}}
			currentPage={filters[PackagesFilterKeys.PAGE]}
			onChangePage={(page: number) => {
				handleSearch({
					[PackagesFilterKeys.PAGE]: page,
				});
			}}
			onReset={handleReset}
			canReset={hasSelection}
		>
			<PanelWrapper
				title="Type"
				Icon={Icon.Tag}
				list={getOptions(store.item?.[PackagesFilterKeys.TYPE])}
				loading={store.loading}

				value={filters[PackagesFilterKeys.TYPE]}
				onChange={(values: Array<number>) => {
					if (!isArray(values)) return;

					handleSearch({
						[PackagesFilterKeys.TYPE]: values,
						[PackagesFilterKeys.PAGE]: initialPage,
					});
				}}
			/>
			<PanelWrapper
				title="Destination"
				Icon={Icon.Location}
				list={getOptions(store.item?.[PackagesFilterKeys.DESTINATIONREGION], store.item?.[PackagesFilterKeys.DESTINATION])}
				loading={store.loading}

				value={filters[PackagesFilterKeys.DESTINATION]}
				onChange={(values: Array<string>) => {
					handleSearch({
						[PackagesFilterKeys.DESTINATION]: values,
						[PackagesFilterKeys.PAGE]: initialPage,
					});
				}}
			/>
			<PanelWrapper
				title="Travel Dates"
				Icon={Icon.Calendar}
				list={getOptions(store.item?.[PackagesFilterKeys.DATE])}
				loading={store.loading}

				value={filters[PackagesFilterKeys.DATE]}
				onChange={(values: Array<number>) => {
					if (!isArray(values)) return;

					handleSearch({
						[PackagesFilterKeys.DATE]: values,
						[PackagesFilterKeys.PAGE]: initialPage,
					});
				}}
			/>
			<PanelWrapper
				title="Vacation Duration"
				Icon={Icon.Moon}
				list={getOptions(store.item?.[PackagesFilterKeys.DURATION])}
				loading={store.loading}

				value={filters[PackagesFilterKeys.DURATION]}
				onChange={(values: Array<number>) => {
					if (!isArray(values)) return;

					handleSearch({
						[PackagesFilterKeys.DURATION]: values,
						[PackagesFilterKeys.PAGE]: initialPage,
					});
				}}
			/>
			<PanelWrapper
				title="Cruise Line / Tour Operator"
				Icon={Icon.Helm}
				list={getOptions(store.item?.[PackagesFilterKeys.CRUISELINE])}
				loading={store.loading}

				value={filters[PackagesFilterKeys.CRUISELINE]}
				onChange={(values: Array<string>) => {
					if (!isArray(values)) return;

					handleSearch({
						[PackagesFilterKeys.CRUISELINE]: values,
						[PackagesFilterKeys.PAGE]: initialPage,
					});
				}}
			/>
			<PanelWrapper
				title="Cruise Ship"
				Icon={Icon.Ship}
				list={getOptions(store.item?.[PackagesFilterKeys.SHIP])}
				loading={store.loading}

				value={filters[PackagesFilterKeys.SHIP]}
				onChange={(values: Array<string>) => {
					if (!isArray(values)) return;

					handleSearch({
						[PackagesFilterKeys.SHIP]: values,
						[PackagesFilterKeys.PAGE]: initialPage,
					});
				}}
			/>
		</SearchPackagesPageContent>
	);
};
