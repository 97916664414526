import * as React from 'react';
import '@app/scss/components/badge.scss';

interface BadgeProps {
	children: React.ReactNode;
	count?: number;
}

export const Badge: React.FC<BadgeProps> = (props: BadgeProps) => (
	<span className="badge">
		{props.children}
		{props.count ? <span className="badge__count">{props.count}</span> : null}
	</span>
);
