import * as React from 'react';

import { Nullable } from '@app/objects/Utility';
import { Select } from '@app/components/Selects/Select';
import { Option } from '@app/components/Selects/Option';
import { useListData } from '@app/hooks/data/useListData';

interface Supplier {
	id: number;
	name: string;
}

interface SuppliersSelectProps {
	value?: Nullable<number>;
	onChange: (value?: Nullable<number>) => void;
}

const location = 'api/bridge/v1/suppliers/list?';

export const SuppliersSelect: React.FC<SuppliersSelectProps> = (props: SuppliersSelectProps) => {
	const { list, loading, fetch } = useListData<Supplier, number>(location, props.value);

	return (
		<Select
			placeholder="Cruise Line"
			loading={loading}
			allowClear={false}
			value={props.value}
			onChange={props.onChange}
			onPopupScroll={fetch}
		>
			{
				list.map((item: Supplier) => (
					<Option key={item.id} value={item.id}>
						{item.name}
					</Option>
				))
			}
		</Select>
	);
};
