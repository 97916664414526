import * as React from 'react';
import { DateRange, RangeFocus } from 'react-date-range';

import Dropdown from 'rc-dropdown';

import moment from 'moment';

import { TIcon, Nullable } from '@app/objects/Utility';
import { TextField } from '@app/components/TextField/TextField';

import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';

import '@app/scss/components/dateRange.scss';

interface OwnProps {
	from?: Nullable<number>;
	to?: Nullable<number>;
	onChange?: (from: Nullable<number>, to: Nullable<number>) => void;
	shouldClose?: boolean;

	onFocus?: React.FocusEventHandler;

	min?: number;
	max?: number;

	title?: string; // This is placeholder / caption
	Prefix?: TIcon; // Icon that comes before input

	text?: string; // This is an additional description text
	error?: boolean; // If true - component is marked as 'with error'
}

interface PickerDropdownProps extends Omit<OwnProps, 'title' | 'Prefix' | 'text' | 'error'> {
	onBlurPicker?: (value: boolean) => void;
}

const PickerDropdown: React.FC<PickerDropdownProps> = (props: PickerDropdownProps) => {
	// There's currently no way to set no initial value
	const range = {
		startDate: props.from ? new Date(props.from) : new Date(),
		endDate: props.to ? new Date(props.to) : new Date(moment().add(1, 'days').startOf('day').valueOf()),
		key: 'value',
	};

	return (
		<DateRange
			ranges={[range]}
			onChange={(map) => {
				const range = map.value;
				if (!range) return;

				props.onChange?.(range.startDate?.valueOf() ?? null, range.endDate?.valueOf() ?? null);
			}}

			minDate={props.min ? new Date(props.min) : undefined}
			maxDate={props.max ? new Date(props.max) : undefined}
			onRangeFocusChange={(newFocusedRange: RangeFocus) => {
				if (newFocusedRange[1] === 0) props.onBlurPicker?.(false);
			}}
			rangeColors={['#E0F7FF']}
			showDateDisplay={false}
		/>
	);
};

function formatDate(date: Nullable<number> | undefined): string {
	if (!date) return '';

	return moment(date).format('MM/DD/YYYY');
}

function formatRange(from: Nullable<number> | undefined, to: Nullable<number> | undefined): string {
	const sFrom = formatDate(from);
	const sTo = formatDate(to);

	if (sFrom && sTo) return `${sFrom} - ${sTo}`;

	return sFrom || sTo;
}

export const DatePicker: React.FC<OwnProps> = (props: OwnProps) => {
	const [active, setActive] = React.useState<boolean>(() => false);
	const onFocus = (event: React.FocusEvent<HTMLInputElement>) => {
		setActive(true);
		props.onFocus?.(event);
	};

	React.useEffect(() => {
		if (props.shouldClose === true) setActive(false);
	}, [props.shouldClose]);

	React.useEffect(() => {
		if (!props.from && !props.to) setActive(false);
	}, [props.from, props.to]);

	return (
		<Dropdown
			visible={active}
			overlay={(
				<>
					<div className="rc-dropdown-overlay" onClick={() => setActive(false)} />
					<PickerDropdown
						from={props.from}
						to={props.to}
						onChange={props.onChange}

						min={props.min}
						max={props.max}

						onBlurPicker={setActive}
					/>
				</>
			)}
			animation="slide-up"
			minOverlayWidthMatchTrigger={false}
		>
			<TextField
				value={formatRange(props.from, props.to)}
				onChange={() => props.onChange?.(null, null)}

				title={props.title}
				Prefix={props.Prefix}

				text={props.text}
				error={props.error}

				onFocus={onFocus}

				style={{ caretColor: 'transparent', cursor: 'default' }}
			/>
		</Dropdown>
	);
};
