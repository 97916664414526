import * as React from 'react';
import { Link } from 'react-router-dom';

import { formatPhone } from '@app/services/phone';

export enum LinkType {
	External,
	Internal,
	Phone,
	Mail,
}

export const link = (link: string, type: LinkType, text?: string) => {
	switch (type) {
	case LinkType.Internal:
		return (<Link to={link}>{text ?? link}</Link>);
	case LinkType.Phone:
		return (<a href={`tel:${formatPhone(link)}`}>{formatPhone(link)}</a>);
	case LinkType.Mail:
		return (<a href={`mailto:${link}`}>{link ?? text}</a>);

	default:
		return (<a href={link} target="_blank" rel="noopener noreferrer">{text ?? link}</a>);
	}
};
