import * as React from 'react';
import Table from 'rc-table';

import {
	DISCLAIMER, emergencyData, healthData,
	INTRO, propertyData,
	travelData, travelInsuranceList,
	WHY_PURCHASE_TEXT,
} from '@app/pages/StaticPages/TravelInsurance/travel-insurance';
import { LinkType, link } from '@app/pages/StaticPages/utils';

import { Icon } from '@image/icon';
import '@app/scss/pages/documents-pages.scss';

function getColumns(title: string) {
	return (
		[
			{
				title,
				dataIndex: 'name',
				key: 'name',
			},
			{
				title: '',
				dataIndex: 'value',
				key: 'value',
				width: 200,
			},
		]
	);
}

const travelColumns = getColumns('Travel');
const emergencyColumns = getColumns('Emergency Travel');
const healthColumns = getColumns('Accident & Health');
const propertyColumns = getColumns('Property Coverage');

export const TravelInsurance = () => (
	<div className="documentation travel-insurance font-family_primary font-weight_regular text-size_extra-small">
		<div className="documentation-section">
			<h1 className="documentation__title text-size_soft-large content-valign_center">
				Travel Insurance
			</h1>
			<div className="documentation__text-block">{INTRO}</div>
			<a
				href="https://www.onlinevacationcenter.com/gtc/ovc_gen.pdf"
				target="_blank"
				rel="noopener noreferrer"
				className="general-terms__pdf-link text-margin"
			>
				<div className="general-terms__pdf-icon"><Icon.Pdf /></div>
				View Aegis’s Go Ready Travel Protection Plan General Terms & Conditions for this program.
			</a>
			<a
				href="https://www.onlinevacationcenter.com/gtc/ovc_gen_ma.pdf"
				target="_blank"
				rel="noopener noreferrer"
				className="general-terms__pdf-link"
			>
				<div className="general-terms__pdf-icon"><Icon.Pdf /></div>
				Massachusetts Residents view General Terms & Conditions here.
			</a>
		</div>

		<div className="documentation__section">
			<h4 className="documentation__subtitle font-weight_bold">WHY PURCHASE TRAVEL INSURANCE?</h4>
			<div className="documentation__text-block">{WHY_PURCHASE_TEXT}</div>
		</div>

		<div className="documentation__section">
			<h4 className="documentation__subtitle font-weight_bold">
				SUMMARY OF COVERAGES PER PERSON - Policy purchased after 8/1/2021
			</h4>
			<div className="layout-container">
				<Table
					columns={travelColumns}
					data={travelData}
					className="col-12 col-lg-7 travel-insurance__table"
				/>
				<Table
					columns={emergencyColumns}
					data={emergencyData}
					className="col-12 col-lg-7 travel-insurance__table"
				/>
				<Table
					columns={healthColumns}
					data={healthData}
					className="col-12 col-lg-7 travel-insurance__table"
				/>
				<Table
					columns={propertyColumns}
					data={propertyData}
					className="col-12 col-lg-7 travel-insurance__table"
				/>
			</div>
		</div>

		{travelInsuranceList.map((item) => (
			<div className="documentation__section" key={item.title}>
				<h4 className="documentation__subtitle font-weight_bold">{item.title}</h4>
				<div className="documentation__text-block">{item.text}</div>
			</div>
		))}

		<div className="documentation__section">
			<h4 className="documentation__subtitle font-weight_bold">Aegis Travel Protection</h4>
			<p>
				Customer Service: {link('8552350675', LinkType.Phone)}<br />
				Email: {link('ovc@goreadyinsurance.com', LinkType.Mail)}
			</p>
		</div>

		<div className="documentation__section">
			<h4 className="documentation__subtitle font-weight_bold">Disclaimer</h4>
			<p className="documentation__text-block">{DISCLAIMER}</p>
		</div>
	</div>
);
