import * as React from 'react';

import { useListData } from '@app/hooks/data/useListData';

import { Select } from '@app/components/Selects/Select';
import { Option } from '@app/components/Selects/Option';

export interface Option<T> {
	id: T;
	value: string;
}

interface DestinationsSelectProps {
	value?: Array<string>;
	onChange: (value: Array<string>) => void;

	error?: string;
}

const location = 'api/bridge/v1/destinations/list?';

export const DestinationsSelect: React.FC<DestinationsSelectProps> = (props: DestinationsSelectProps) => {
	const { list, loading, fetch } = useListData<Option<string>, Array<string>>(location, props.value);

	return (
		<Select<string>
			placeholder="Destination*"
			loading={loading}
			value={props.value}
			onChange={props.onChange}
			multiple

			text={props.error}
			error={Boolean(props.error)}

			onPopupScroll={fetch}
		>
			{
				list.map((item: Option<string>) => (
					<Option key={item.id} value={item.value}>
						{item.value}
					</Option>
				))
			}
		</Select>
	);
};
