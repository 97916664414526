import * as React from 'react';
import { useLocation } from 'react-router-dom';

import clsx from 'clsx';

import { NavBar } from '@app/components/Header/NavBar';
import { items } from '@app/components/Header/Menu';
import { InfoSection } from '@app/components/Header/InfoSection';
import { BurgerMenu } from '@app/components/BurgerMenu/BurgerMenu';
import { SearchCard } from '@app/pages/MainPage/SearchCard/SearchCard';

import '@app/scss/components/header.scss';

const home = '/';

interface OwnProps {
	minimized?: boolean;
}

const titleClassName: string = [
	'col-12',
	'search-card__intro',
	'content-halign',
	'font-family_primary',
	'condensed',
	'font-weight_semi-bold',
	'text-size_hard-large',
].join(' ');

const SearchCardIntro: React.FC = () => (
	<div
		className={titleClassName}
		style={{
			textWrap: 'balance',
			width: '100%',
			textAlign: 'center',
		}}
	>
		<b style={{ maxWidth: '75%', margin: '0 auto' }}>
			Your <i className="font-family_decorative font-weight_regular" style={{ textTransform: 'none' }}>Next</i> Trip Starts Here
		</b>
	</div>
);

export const Header: React.FC<OwnProps> = (props: OwnProps) => {
	const location = useLocation();
	const isMain = !props.minimized && location.pathname === home;

	return (
		<header className={clsx('site-header', isMain && 'is-main-page')}>
			<InfoSection scrollToSubscribe={isMain} />
			<NavBar items={items} />
			<BurgerMenu
				items={items}
				scrollToSubscribe={isMain}
			/>
			{
				isMain ? (
					<div className="search-container col-12">
						<div className="layout-container content-valign_center">
							<SearchCardIntro />
							<div className="col-12 col-xs-10 col-lg-8">
								<SearchCard />
							</div>
						</div>
					</div>
				) : null
			}
		</header>
	);
};
