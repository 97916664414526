import * as React from 'react';

interface Dimensions {
	width: number;
	height: number;
}

export function useDimensions(ref: React.RefObject<HTMLElement>): Dimensions {
	const [width, setWidth] = React.useState(() => 0);
	const [height, setHeight] = React.useState(() => 0);

	React.useEffect(() => {
		setHeight(ref.current?.clientHeight ?? 0);
		setWidth(ref.current?.clientWidth ?? 0);
	}, [ref]);

	return {
		width,
		height,
	};
}
