import React from 'react';

import { Loader } from '@app/components/Loader/Loader';

interface TrustBoxProps {
	children: (value: React.MutableRefObject<null>, loader: React.ReactElement) => React.ReactElement;
}
export const TrustBox: React.FC<TrustBoxProps> = ({ children }: TrustBoxProps) => {
	const ref = React.useRef(null);

	React.useEffect(() => {
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore-block
		if (ref.current && window.Trustpilot) {
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore-block
			window.Trustpilot.loadFromElement(ref.current, true);
		}
	}, []);

	return children(ref, <Loader />);
};
