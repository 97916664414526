import { RequestError } from '@app/services/errors/RequestError';
import { Nullable } from '@app/objects/Utility';

type BodyType = Record<string, unknown> | URLSearchParams;

export interface RequestConfig {
	externalUrl?: string;
	tag?: string;
	data?: BodyType;
	method?: 'GET' | 'PATCH' | 'POST';
}

enum ContentType {
	UrlEncoded = 'application/x-www-form-urlencoded',
	Json = 'application/json',
	Text = 'text/plain',
}

const pendingRequests: Map<string, AbortController> = new Map<string, AbortController>();

function getBody(data?: BodyType): URLSearchParams | string | null {
	if (!data) return null;
	if (data instanceof URLSearchParams) return data;

	return JSON.stringify(data);
}

function getHeaders(data?: BodyType): HeadersInit | undefined {
	if (!data) return undefined;
	if (data instanceof URLSearchParams) {
		return {
			'Content-type': ContentType.UrlEncoded,
		};
	}

	return {
		'Content-type': ContentType.Json,
	};
}

interface ResultEnvelope {
	status: number;
	text: string;
	ok: boolean;
	type: Nullable<ContentType>;
}

// eslint-disable-next-line sonarjs/cognitive-complexity
export const request = (path: string, config?: RequestConfig) => {
	const url = config?.externalUrl ? `${config.externalUrl}/${path}` : `/${path}`;
	const result: ResultEnvelope = {
		status: -1,
		text: '',
		ok: false,
		type: null,
	};

	let controller: Nullable<AbortController> = null;
	if (config?.tag) {
		if (pendingRequests.has(config.tag)) {
			pendingRequests.get(config.tag)?.abort();
		}

		controller = new AbortController();
		pendingRequests.set(config.tag, controller);
	}

	let task = fetch(url, {
		method: config?.method ?? 'GET',
		credentials: 'same-origin',
		mode: 'cors',
		signal: controller?.signal,
		body: getBody(config?.data),
		headers: getHeaders(config?.data),
	});

	if (config?.tag) {
		const tag = config?.tag;
		task = task.finally(() => {
			if (pendingRequests.get(tag) === controller) {
				pendingRequests.delete(tag);
			}
		});
	}

	return task
		.then((response: Response) => {
			result.status = response.status;
			result.ok = response.ok;

			const type = response.headers.get('Content-Type');
			if (type?.toLowerCase().includes(ContentType.Json)) {
				result.type = ContentType.Json;

				return response.json();
			}

			result.type = ContentType.Text;

			return response.text();
		})
		.then((data) => {
			if (result.type === ContentType.Text) {
				result.text = data;

				return null;
			}

			return data;
		})
		.then((data) => {
			if (!result.ok) {
				if (result.text === 'abort') throw new RequestError(-1, 'Abort');

				throw new RequestError(result.status, data);
			}

			return data;
		});
};

export const dataRequest = <T>(values: URLSearchParams, path: string, externalUrl?: string): Promise<T> => {
	const url = externalUrl ? `${externalUrl}/${path}` : `/${path}`;
	const result = {
		status: -1,
		text: '',
		ok: false,
		shouldParse: false,
	};

	return fetch(url, {
		method: 'POST',
		headers: {
			'Content-type': 'application/x-www-form-urlencoded',
		},
		body: values,
	})
		.then((response: Response) => {
			result.status = response.status;
			result.ok = response.ok;

			const contentType = response.headers.get('Content-Type');
			if (contentType?.includes('application/json')) {
				return response.json();
			}

			if (contentType?.includes('text/')) {
				result.shouldParse = true;

				return response.text();
			}

			throw new RequestError(result.status, 'Incorrect response type - application/json or text/html (text/plain) expected');
		})
		.then((data) => {
			if (!result.ok) throw new RequestError(result.status, data);

			if (result.shouldParse) {
				try {
					const obj = JSON.parse(data);

					return obj as T;
				} catch (error) {
					console.warn('Error while parsing response data: ', error);
					throw new RequestError(result.status, 'Incorrect response type - json expected');
				}
			}

			return data as T;
		});
};
