import * as React from 'react';

import { ActionMode, Button, ButtonVariant } from '@app/components/Button/Button';
import { WHICH_ONE_TEXT } from '@app/pages/MainPage/CruiseOptions/text';

import bestYou from '@image/bestYou.png';
import '@app/scss/pages/main/cruiseOptions.scss';

export const CruiseOptions = () => {
	return (
		<div className="which-one">
			<div className="which-one__container">
				<div className="which-one__title font-family_primary condensed font-weight_semi-bold text-size_hard-large">
					<b>Pick the</b>
					<i className="font-family_decorative font-weight_regular"> Cruise Vacation </i>
					<b>That Best Fits </b>
					<i className="font-family_decorative font-weight_regular">Your </i>
					<b>Needs</b>
				</div>
				<p className="which-one__description font-family_primary font-weight_regular text-size_medium line-height_large">
					{WHICH_ONE_TEXT}
				</p>
				<Button
					className="which-one__btn"
					variant={ButtonVariant.Filled}
					action={ActionMode.ExternalLink}
					link="https://travel.onlinevacationcenter.com/acton/media/12605/pick-the-cruise-vacation-that-best-fits-your-needs"
				>
					Learn More
				</Button>
			</div>
			<img src={bestYou} alt="Ship" className="which-one__image" />
		</div>
	);
};
