import { v4 } from 'uuid';

import { IListElement, ListElementType } from '@app/components/DropList/DropList';

export const pressReleases: Array<IListElement> = [
	{
		title: 'Online Vacation Center Holdings Corp. Announces FY 2019 Results - Apr 1, 2020',
		text: 'https://www.onlinevacationcenter.com/ir/documents/release_20200401.pdf',
		id: v4(),
		type: ListElementType.PDF,
	},
	{
		title: 'Online Vacation Center Holdings Corp. Announces FY 2018 Results - Apr 17, 2019',
		text: 'https://www.otcmarkets.com/stock/ONVC/news/Online-Vacation-Center-Holdings-Corp-Announces-FY-2018-Results?id=225155',
		id: v4(),
	},
	{
		title: 'Online Vacation Center Holdings Corp. Announces Annual Dividend of $0.04 Per Share - Apr 15, 2019',
		text: 'https://www.otcmarkets.com/stock/ONVC/news/Online-Vacation-Center-Holdings-Corp-Announces-Annual-Dividend-of-004-Per-Share?id=224827',
		id: v4(),
	},
	{
		title: 'Change in Certifying Accountant - Feb 28, 2019',
		text: 'https://www.otcmarkets.com/stock/ONVC/news/Change-in-Certifying-Accountant?id=219839',
		id: v4(),
	},
	{
		title: 'Change in Online Vacation Center Holdings Corp\'s Certifying Accountant - Feb 26, 2019',
		text: 'https://www.otcmarkets.com/stock/ONVC/news/Change-in-Online-Vacation-Center-Holdings-Corps-Certifying-Accountant?id=219534',
		id: v4(),
	},
	{
		title: 'Online Vacation Center Holdings Corp. Concludes Share Repurchase Program - Aug 31, 2018',
		text: 'https://www.otcmarkets.com/stock/ONVC/news/Online-Vacation-Center-Holdings-Corp-Concludes-Share-Repurchase-Program?id=202538',
		id: v4(),
	},
	{
		title: 'Tender Offer to Purchase up to 1.5 Million of its Common Shares at a Price of $1.25 Per Share - July 9, 2018',
		text: 'https://www.onlinevacationcenter.com/ir/documents/release_20180709.pdf',
		id: v4(),
		type: ListElementType.PDF,
	},
	{
		title: 'ONVC Announces Annual Dividend of $0.04 Per Share - March 13, 2018',
		text: 'https://www.onlinevacationcenter.com/ir/documents/release_20180313.pdf',
		id: v4(),
		type: ListElementType.PDF,
	},
	{
		title: 'ONVC Added to Three OTCQX Indices - August 7, 2017',
		text: 'https://www.onlinevacationcenter.com/ir/documents/release_20170807.pdf',
		id: v4(),
		type: ListElementType.PDF,
	},
	{
		title: 'OTC Markets Group Welcomes Online Vacation Center Holdings to OTCQX - May 4, 2017',
		text: 'http://www.prnewswire.com/news-releases/otc-markets-group-welcomes-online-vacation-center-holdings-to-otcqx-300450781.html',
		id: v4(),
	},
	{
		title: 'ONVC Announces FY 2016 Results - April 25, 2017',
		text: 'https://www.onlinevacationcenter.com/ir/documents/release_20170425.pdf',
		id: v4(),
		type: ListElementType.PDF,
	},
	{
		title: 'ONVC Announces an Increase of 33% to the Annual Dividend - March 20, 2017',
		text: 'https://www.onlinevacationcenter.com/ir/documents/release_20170320.pdf',
		id: v4(),
		type: ListElementType.PDF,
	},
	{
		title: 'ONVC Announces FY 2015 Results - April 18, 2016',
		text: 'https://www.onlinevacationcenter.com/ir/documents/release1_20160418.pdf',
		id: v4(),
		type: ListElementType.PDF,
	},
	{
		title: 'Online Vacation Center Holdings Corp. Announces an Annual Dividend - March 30, 2016',
		text: 'https://www.onlinevacationcenter.com/ir/documents/release_20160330.pdf',
		id: v4(),
		type: ListElementType.PDF,
	},
	{
		title: 'ONVC Announces FY 2014 Results - April 29, 2015',
		text: 'https://www.onlinevacationcenter.com/ir/documents/release_042915_updated.pdf',
		id: v4(),
		type: ListElementType.PDF,
	},
	{
		title: 'Online Vacation Center Holdings Corp. Announces an Annual Dividend - February 26, 2015',
		text: 'https://www.onlinevacationcenter.com/ir/documents/release_022615.pdf',
		id: v4(),
		type: ListElementType.PDF,
	},
	{
		title: 'ONVC Announces FY 2013 Results & Special Dividend - June 20, 2014',
		text: 'https://www.onlinevacationcenter.com/ir/documents/release_062014_3.pdf',
		id: v4(),
		type: ListElementType.PDF,
	},
	{
		title: 'ONVC Files Form 15 to Suspend SEC Reporting Obligations - February 18, 2011',
		text: 'https://www.onlinevacationcenter.com/ir/documents/021811.pdf',
		id: v4(),
		type: ListElementType.PDF,
	},
];
