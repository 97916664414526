import { Nullable } from '@app/objects/Utility';

type Selector<TKey, TValue> = (item: TValue) => TKey;
type Predicate<TValue> = (item: TValue) => boolean;

export function group<TKey, TValue>(source: Array<TValue>, selector: Selector<TKey, TValue>): Map<TKey, Array<TValue>> {
	const result: Map<TKey, Array<TValue>> = new Map<TKey, Array<TValue>>();

	source.forEach((item: TValue) => {
		const key = selector(item);

		if (result.has(key)) {
			result.get(key)?.push(item);
		} else {
			result.set(key, [item]);
		}
	});

	return result;
}

export function first<TKey, TValue>(group: Map<TKey, Array<TValue>>, key: TKey, predicate?: Predicate<TValue>): Nullable<TValue> {
	const list = group.get(key);

	if (list === undefined) return null;

	if (predicate) {
		return list.find(predicate) ?? null;
	}

	if (list.length) return list[0];

	return null;
}
