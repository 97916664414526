import * as React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import Tabs from 'rc-tabs';

import { InvestorRelations } from '@app/pages/StaticPages/Investors/InvestorsTabs/InvestorRelations';
import { TemplateWithDropList, TemplateWithList } from '@app/pages/StaticPages/Investors/InvestorsTabs/Templates';

import { directorsList } from '@app/pages/StaticPages/Investors/InvestorsTabs/texts/directors';
import { management } from '@app/pages/StaticPages/Investors/InvestorsTabs/texts/management';
import { investorFAQs } from '@app/pages/StaticPages/Investors/InvestorsTabs/texts/investorFAQs';
import { pressReleases } from '@app/pages/StaticPages/Investors/InvestorsTabs/texts/pressReleases';
import { financials } from '@app/pages/StaticPages/Investors/InvestorsTabs/texts/financials';

import '@app/scss/pages/documents-pages.scss';
import { RoutePaths } from '@app/utilities/RoutePathVars';
import { Icon } from '@app/image/icon';

export enum ActiveTabKey {
	InvestorRelations = 'investor-relations',
	BoardOfDirectors = 'board-directors',
	Management = 'management',
	PressReleases = 'press-releases',
	Financials = 'financials',
	InvestorFAQs = 'investor-FAQs',
}

const TAB = 'tab';

const tabs = [
	{
		label: 'Investor Relations',
		key: ActiveTabKey.InvestorRelations,
		children: <InvestorRelations />,
	},
	{
		label: 'Board of Directors',
		key: ActiveTabKey.BoardOfDirectors,
		children: <TemplateWithDropList title="Board of Directors" items={directorsList} />,
	},
	{
		label: 'Management',
		key: ActiveTabKey.Management,
		children: <TemplateWithDropList title="Management" items={management} />,
	},
	{
		label: 'Press Releases',
		key: ActiveTabKey.PressReleases,
		children: <TemplateWithList title="Press Releases" items={pressReleases} />,
	},
	{
		label: 'Financials',
		key: ActiveTabKey.Financials,
		children: <TemplateWithList title="Financials" items={financials} />,
	},
	{
		label: 'Investor Frequently Asked Questions',
		key: ActiveTabKey.InvestorFAQs,
		children: <TemplateWithDropList title="Investor Frequently Asked Questions" items={investorFAQs} />,
	},
];

const contactList = [
	'ONVC Investor Relations', 'Stephen Rudner', 'President', 'Fort Lauderdale, FL',
];

const getLink = (link: string, text: string) => <a href={link} target="_blank" rel="noopener noreferrer">{text}</a>;

const isKeyTab = (key: string | ActiveTabKey): key is ActiveTabKey => {
	return key === ActiveTabKey.BoardOfDirectors
	|| key === ActiveTabKey.Financials
	|| key === ActiveTabKey.InvestorFAQs
	|| key === ActiveTabKey.InvestorRelations
	|| key === ActiveTabKey.Management
	|| key === ActiveTabKey.PressReleases;
};

export const Investors = () => {
	const [activeKey, setActiveKey] = React.useState<ActiveTabKey>(ActiveTabKey.InvestorRelations);
	const location = useLocation();
	const navigate = useNavigate();

	React.useEffect(() => {
		const searchParams = new URLSearchParams(location.search);
		const key = searchParams.get(TAB);
		if (searchParams.has(TAB) && key && isKeyTab(key)) {
			setActiveKey(key);
		} else {
			searchParams.set(TAB, activeKey);
			navigate(`${RoutePaths.investors}?${searchParams.toString()}`);
		}
	}, [location.search]);

	return (
		<div className="documentation investor-relations font-family_primary font-weight_regular text-size_extra-small">
			<Tabs
				items={tabs}
				activeKey={activeKey}
				moreIcon={<Icon.Ellipsis />}
				onChange={(key) => {
					setActiveKey(key as ActiveTabKey);
					if (location.search !== `?${TAB}=${key}`) {
						const searchParams = new URLSearchParams(location.search);
						searchParams.set(TAB, key);
						navigate(`${RoutePaths.investors}?${searchParams.toString()}`);
					}
				}}
			/>
			<div className="documentation__section">
				<h4 className="documentation__subtitle font-weight_bold">Stock Information</h4>
				<p>ONVC - (OTCQX) 6/8/23</p>
			</div>

			<div>
				<h4 className="documentation__subtitle font-weight_bold">Contact Information</h4>
				<ul>
					{contactList.map((item: string) => <li key={item}>{item}</li>)}
				</ul>
				{getLink('mailto:investorrelations@onlinevacationcenter.com', 'investorrelations@onlinevacationcenter.com')}
			</div>
		</div>
	);
};
