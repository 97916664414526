import * as React from 'react';
import { v4 } from 'uuid';

import { LinkType, link } from '@app/pages/StaticPages/utils';
import { IListElement } from '@app/components/DropList/DropList';
import { RoutePaths } from '@app/utilities/RoutePathVars';

const phone = link('800-780-9002', LinkType.Phone);

const cruiselinesList = [
	{
		title: 'Royal Caribbean International',
		link: 'https://secure.royalcaribbean.com/beforeyouboard/boardingDocuments.do',
	},
	{
		title: 'Celebrity Cruises',
		link: 'https://secure.celebritycruises.com/beforeyourcruise/onlineDocumentation/home.do',
	},
	{
		title: 'Azamara Club Cruises',
		link: 'https://secure.azamaraclubcruises.com/dc/myAccount/onlineDocumentation/home.do',
	},
	{
		title: 'Holland America Line',
		link: 'https://www.hollandamerica.com/cruise-vacation-planning/OnlineCheckIn.action',
	},
	{
		title: 'Princess Cruises',
		link: 'https://book.princess.com/cruisepersonalizer/login.page',
	},
	{
		title: 'Cunard Line',
		link: 'https://secure.pocruises.com/cp/index.asp?pageID=4&ABTA=V8764&brandCode=C2',
	},
	{
		title: 'Carnival Cruise Lines',
		link: 'https://secure.carnival.com/BookedGuest/',
	},
	{
		title: 'Costa Cruises',
		link: 'https://www.costacruise.com/B2C/USA/Before_you_go/webcheck/webcheck-in.htm',
	},
	{
		title: 'MSC Cruises',
		link: 'https://www.msccruisesusa.com/us_en/Already-Booked/WebCheckin.aspx',
	},
	{
		title: 'Viking River Cruises',
		link: 'https://www.vikingrivercruises.com/my-trip/manage-my-trip/guest-information-form.html?agenturlid=ovc',
	},
	{
		title: 'Norwegian Cruise Line',
		link: 'https://www2.ncl.com/login?SOURCE_CTX=/nclweb',
	},
	{
		title: 'Oceania Cruises',
		link: 'https://www.oceaniacruises.com/alreadybookedlogin.aspx',
	},
];

const GUARANTEE_ROOM = (
	<p>
		A Guarantee room means that you are not assigned a cabin number at time of booking. You are guaranteed a cabin
		in your selected category or higher. The cruise line will automatically assign your cabin within 30 days of
		departure. With this option, location preferences cannot be notated and unobstructed views cannot be guaranteed.
		Cabin assignments are at the discretion of the cruise line.
		<br />
		If you have specific cabin preferences or requirements—for instance, location preferences (mid-ship, near an
		elevator, far from public areas/decks), wheelchair accessible, connecting cabins—then Online Vacation Center
		recommends not booking a Guarantee room, if possible.
	</p>
);
const NON_REFUNDABLE_FEE = (
	<p>
		The Nonrefundable Agency Fee works just like the cruise line's deposit. It secures the room for you and reduces
		the total amount due at final payment.
		<span className="font-weight_bold"> It is not a charge in addition to your cruise fare. </span>
		It is nonrefundable after 24 hours, but in the event of cancellation before final payment, it will be held on
		file and can be used toward your next cruise. You have
		<span className="font-weight_bold"> six months </span>
		from the date of cancellation to use your deposit, or it is forfeited.
		<br />
		Keep in mind that your cruise need not sail in six months, but only needs to be booked within that time.
	</p>
);
const MORE_INFO_VISA = (
	<p>
		Visa requirements change frequently and for many reasons. Because of this, our Personal Vacation Managers are
		unable to provide the most current visa information.
		<br />
		The best way to obtain up-to-date information regarding visa requirements is to visit the official US government
		website for Americans traveling
		abroad, {link('http://travel.state.gov/', LinkType.External, 'Travel.State.Gov')}, which has specific information
		on the countries you would like to visit, including visa requirements.
		<br />
		Some cruise lines offer visa services for your cruise at an additional charge. However,
		this is not always available.
		<br />
		You may also use a third-party visa service,
		such as {link('https://cibtvisas.com/?login=onlinevacationcenter', LinkType.External, 'CIBTvisas')}. These services
		require an additional service fee but make the process of applying for a visa easier
		for many people.
	</p>
);
const SPECIAL_RATES = 'Yes, provided that these rates are offered by the cruise line or travel company.';
const TRAVEL_INSURANCE = (
	<p>
		Travel insurance is a necessity on any voyage leaving the United States and can be helpful on shorter,
		local cruises, as well. Most health insurance plans do not cover international medical costs, and the care
		available internationally may not be comparable to the care you receive at home.
		<br />
		It is important that you consider the costs associated with seeking emergency medical attention abroad, as well
		as procuring new flights home, and incidental expenses during your medical stay in a foreign country.
		These can easily reach tens of thousands of dollars. Travel insurance will cover these expenses in most
		situations.
		<br />
		Moreover, travel insurance would also cover cancellation of your trip in the event you, your traveling partners
		or one of your family members becomes ill. Many of our canceled trips are because of illness of a non-traveling
		family member, and this would still be covered under our travel insurance policy. Cancellation penalties vary by
		cruise line, but all cruise lines eventually reach 100% nonrefundability by about 15 to 30 days prior to your
		cruise. Please also note that our Value Inclusive Packages are completely non-refundable
		<span className="font-weight_bold"> after final payment </span>.
		Most of our guests elect to purchase travel insurance. Please refer to our {
			link('https://www.onlinevacationcenter.com/travel_insurance.cfm?header_show=off', LinkType.External, 'Travel Insurance')
		} page
		for more information.
	</p>
);
const TEXT_I = (
	<p>
		Call a Personal Vacation Manager at {phone} to make additional payments. A PVM will be happy to assist you.
	</p>
);
const TEXT_II = (
	<p>
		Amenity vouchers are sent out by
		<span className="font-weight_bold"> e-mail </span>
		no later than
		<span className="font-weight_bold"> seven days </span>
		before your cruise. Please call a Personal Vacation Manager at {phone} if you are within one week of sailing and
		have not received your voucher.
	</p>
);
const TEXT_III = (
	<p>
		If you're sailing with Royal Caribbean International, Celebrity Cruises or Azamara Club Cruises, your luggage
		tags may be requested through the
		<span className="font-weight_bold"> Online Check-in </span>
		option on the cruise line's website.
		<br />
		Norwegian Cruise Line and Carnival Cruise Lines allow you to
		<span className="font-weight_bold"> print your luggage tags </span>
		with your final documents on their Online Check-in page.
		<br />
		If you are on a luxury cruise or a river cruise, the tags will be
		<span className="font-weight_bold"> mailed to your physical address </span>
		with your final documents.
		<br />
		If you have additional questions regarding luggage tags, please call a Personal Vacation Manager at {phone}.
	</p>
);
const TEXT_IV = (
	<>
		<p>You may click the following links to complete the Online Check-in process for the line you are sailing:</p>
		<p>
			<ul>
				{cruiselinesList.map((item) => (
					<li key={item.title}>{link(item.link, LinkType.External, item.title)}</li>
				))}
			</ul>
		</p>
	</>
);
const TEXT_V = (
	<p>
		Your Online Vacation Center Confirmation Number is the best number to reference when communicating with
		Online Vacation Center (calling, emailing, customer portal, etc.). The cruise lines will not recognize
		this number.
		<br />
		Your Cruise Line (or Tour Operator) Reservation Number is the reservation number that you should reference
		if contacting the cruise line and/or use to complete your online check-in on the cruise line's website.
	</p>
);
const TEXT_VI = `
Promotional OBC may be provided from multiple companies in order to get to the total displayed on your 
Online Vacation Center confirmation. OBC reflected on the cruise line website is provided by the cruise line itself. 
Additional OBC coming from Online Vacation Center or other partners will not be available or reflected until you are 
onboard the ship.
`;
const TEXT_VII = (
	<>
		<p>
			Shore excursions offered by most cruise lines can be reviewed and pre-booked on the cruise line website by
			logging into your cruise line account. See our Resource page for quick link access to most of these pages.
		</p>
		<p>
			If you are looking for independent shore excursion options, you can visit the
			{link('https://www.shoreexcursionsgroup.com/?ID=34', LinkType.External, ' Shore Excursions Group ')}
			website to search for alternatives they may offer on your cruise itinerary.
			Please review all policies involving rules and regulations for independent shore excursion providers
			prior to making a shore excursion reservation.
			Enjoy an exclusive discount on Shore Excursions Group reservations by using promo code OVCSE10 at checkout.
		</p>
	</>
);
const CUSTOMER_TEXT_I = `
Logging in requires your First Name, Last Name and Online Vacation Center Confirmation Number
(or Supplier Reservation Number). Your Confirmation Number appears towards the top of your Confirmation as a 10-digit
number (example: 1037408410).`;
const CUSTOMER_TEXT_II = `
A Lock icon may appear if your booking has recently been submitted or modified and has yet to be reviewed for quality 
assurance. This is temporary while your booking is being reviewed. Check back in 1-2 business days to view your booking.
`;
const CUSTOMER_TEXT_III = `No. The Tasks and Alerts within the Online Vacation Center Customer Portal serve as 
reminders that either action or review is recommended to keep you informed throughout the vacation planning and 
preparation process. Marking items as Complete is for your knowledge and peace of mind to stay on track.
`;
const CUSTOMER_TEXT_IV = `
No, requests cannot be canceled online. However, booking requests such as a Dining Time change, a Bedding Preference
notation, adding Supplier Past Guest Numbers and Insurance Purchase requests are reviewed by the Online Vacation Center 
Customer Service Team before being completed. Cancellation requests may be submitted within 1-2 business days via the 
Ask a Question form or by calling 800-780-9002 and following the prompts for Service to speak with a representative.
`;
const CUSTOMER_TEXT_V = (
	<p>
		Urgent Alerts mean that action is required as soon as possible in regard to the specified reservation.
		For questions or immediate assistance, please call us at {phone} so that Online Vacation Center may
		make the necessary updates to your reservation. Examples include obtaining credit card authorization for an
		upcoming payment, accepting the Online Vacation Center Terms and Conditions, or notification of a credit
		card decline.
		<br />
		General Tasks (Past Due and Upcoming) serve as reminders to keep you informed throughout the reservation process
		and provide you the opportunity to review and ask questions at the appropriate time. In the Tasks and Alerts
		page you may mark these items complete along the way to ensure you don't miss anything!
	</p>
);
const CUSTOMER_TEXT_VI = (
	<p>
		Yes! For each individual booking, you may update the credit card by viewing your Booking within 14 days of the
		final payment date, scrolling to the Payment Details and History section and clicking on the Add Card button.
		Once Submitted, your payment method will be automatically updated and authorized for future payments on that
		specific booking.
		<br />
		If you have multiple credit cards on file for a specific reservation and/or passengers are paying separately,
		you can call us at {phone} to update your payment method.
	</p>
);
const CUSTOMER_TEXT_VII = (
	<p>
		This message may display if your credit card has not been updated in the last 7-14 days, if you made your
		booking online or onboard a previous cruise, or if the booking was transferred to Online Vacation Center.
		If this message is displayed and there is a balance due on your booking, then an updated credit card is
		needed in order to process any pending payments. You may click the Add Card button within 14 days of the final
		payment date in order to update your payment method on file or you may call us at {phone} and follow
		the prompts for Service.
	</p>
);
const PACKAGES_TEXT_I = `
A Value Inclusive Package (VIP) is the best and most convenient way to
book your upcoming cruise vacation. The VIP combines your cruise with air (to and from listed Gateway city),
hotel stay(s) and transfers - all while saving you money and the headache of arranging these details yourself.
Ask your Personal Vacation Manager if a VIP package exists on the destination you're interested in to see what your
options are.
`;
const PACKAGES_TEXT_II = `
Please advise your Personal Vacation Manager when making your 
reservation so we can inform the airline and any applicable restaurants ahead of your arrival.
`;
const PACKAGES_TEXT_III = `
If you are bringing a walker, wheelchair or scooter, you need to advise your Personal Vacation Manager so that
appropriate accessible transportation can be arranged. We can also request an accessible room at the hotel(s)
on your behalf. Please note that requests are subject to hotel room availability.
`;
const PACKAGES_TEXT_IV = `
Your Value Inclusive Package may contain an internal flight between cities, an invitation letter for a tourist visa, or
a stay in a country where the hotel is required by law to gather their guests passport information for security purposes.
We want to have the most up-to-date information on file, so kindly ensure that the names as they appear on your
Online Vacation Center confirmation match your passport exactly and that your passport is valid for at least six months
past your vacation end date to ensure the smoothest travel experience.
`;
const PACKAGES_TEXT_V = (
	<p>
		We offer pricing options for the most common air gateways on many of our Value Inclusive Package itineraries.
		If you would prefer to fly from an unlisted airport, you must first confirm your reservation with one of the
		listed airports as your default gateway. Next you will ask your Personal Vacation Manager to obtain a quote from
		your preferred airport. Once the quote has been provided to you, you may either accept or decline the offer and
		any additional charges contained within.
		<br />
		If you choose to decline, you may keep the air from the default gateway that you previously selected and make
		your own travel arrangements to/from that gateway, or you may elect to take the air credit and arrange your own
		air travel. If you elect to take the air credit, any airport transfers included in the package will be forfeit.
		<br />
		We do our very best to be flexible and provide you with options. Please note that air requests must be submitted
		prior to final payment and no changes can be made once flights are ticketed. Any changes after ticketing will
		result in additional costs, penalties and/or fees.
	</p>
);
const PACKAGES_TEXT_VI = `
In some cases, we may be able to add hotel nights for those wishing to deviate from the original package dates.
Your Personal Vacation Manager can assist with availability and a quote for the additional hotel night(s), updated air
travel and private transfer from the airport. To change your flight dates, this option will require a $100 deviation fee.
This fee is nonrefundable if you decided against the changes. However, if you accept the new dates and associated cost
then the $100 fee will count towards that additional collection.
`;
const PACKAGES_TEXT_VII = (
	<p>
		An updated confirmation will be sent to you by e-mail, at the very latest,
		<span className="font-weight_bold"> 30 days </span> before you sail. We cannot confirm air information for these
		bookings until then. The hotel and transfer information will also be sent by e-mail
		<span className="font-weight_bold"> 30 days </span>
		before you sail.
	</p>
);
const OTHER_QUESTIONS_TEXT_I = `
If you reside in the US or Canada, our Personal Vacation Managers® are able to book your travel—whether you're
in New York, Houston, The Villages, San Diego or beyond! Contact us if you live elsewhere (outside the US or Canada)
and have questions regarding your eligibility to make a reservation. Online Vacation Center is a Registered Seller of 
Travel in the States of Florida, California, and Washington as required.
`;
const OTHER_QUESTIONS_TEXT_II = (
	<p>
		The gift cards that are sent out by our Personal Vacation Managers are our way of rewarding past guests for
		continuing to book with us. Unfortunately, we cannot apply a gift card retroactively - they must be used at the
		time of booking. Gift cards may only be used for Online Vacation Center vacation offers and are not redeemable
		onboard cruise or tour. Additional terms, conditions or restrictions may apply. Please call {phone}, and ask a
		Personal Vacation Manager for details.
	</p>
);
const OTHER_QUESTIONS_TEXT_III = (
	<p>
		Our Frequently Asked Questions page includes answers to the most common questions we receive.
		If you have additional questions or need more information, you may {
			link(RoutePaths.requestQuotePage, LinkType.External, 'Contact Us')
		} electronically through our online submission form or via phone at {phone}. Our Personal Vacation Managers
		are happy to answer any questions you may have!
	</p>
);
export const beforeBookList: Array<IListElement> = [
	{
		title: 'What is a "Guarantee" room?',
		text: GUARANTEE_ROOM,
		id: v4(),
	},
	{
		title: 'What is the Nonrefundable Agency Fee?',
		text: NON_REFUNDABLE_FEE,
		id: v4(),
	},
	{
		title: 'How can I learn more about obtaining a visa to travel to a country that requires one?',
		text: MORE_INFO_VISA,
		id: v4(),
	},
	{
		title: 'Do you offer senior rates, military discounts, police and fire rescue discounts, and other special rates?',
		text: SPECIAL_RATES,
		id: v4(),
	},
	{
		title: 'Why is it important to purchase travel insurance even if I have medical insurance?',
		text: TRAVEL_INSURANCE,
		id: v4(),
	},
];
export const alreadyBookList: Array<IListElement> = [
	{
		title: 'How can I make additional payments once I have paid the deposit online?',
		text: TEXT_I,
		id: v4(),
	},
	{
		title: `
			I've booked a cruise that comes with an amenity (spa treatment, specialty dining, parking, etc.).
			When & how will I receive this voucher?
		`,
		text: TEXT_II,
		id: v4(),
	},
	{
		title: 'Where can I request my tags if I am sailing within 30 days and have not yet received them?',
		text: TEXT_III,
		id: v4(),
	},
	{
		title: 'How can I complete the Online Check-in process for the cruise line?',
		text: TEXT_IV,
		id: v4(),
	},
	{
		title: 'Why do I have multiple reservation numbers?',
		text: TEXT_V,
		id: v4(),
	},
	{
		title: 'Why is the Onboard Credit (OBC) reflected on the cruise line website less than on my Online Vacation Center Confirmation?',
		text: TEXT_VI,
		id: v4(),
	},
	{
		title: 'Where can I book shore excursions?',
		text: TEXT_VII,
		id: v4(),
	},
];
export const customerPortalList: Array<IListElement> = [
	{
		title: 'How do I login to manage my bookings?',
		text: CUSTOMER_TEXT_I,
		id: v4(),
	},
	{
		title: 'Why is my booking Locked ?',
		text: CUSTOMER_TEXT_II,
		id: v4(),
	},
	{
		title: 'Are the Tasks I mark complete in the Customer Portal communicated to the cruise line or tour operator?',
		text: CUSTOMER_TEXT_III,
		id: v4(),
	},
	{
		title: 'Can I cancel a request I submitted on the Customer Portal?',
		text: CUSTOMER_TEXT_IV,
		id: v4(),
	},
	{
		title: 'What are Tasks and Alerts?',
		text: CUSTOMER_TEXT_V,
		id: v4(),
	},
	{
		title: 'Can I update/change my credit card on file?',
		text: CUSTOMER_TEXT_VI,
		id: v4(),
	},
	{
		title: 'Why does my booking show Card Invalid?',
		text: CUSTOMER_TEXT_VII,
		id: v4(),
	},
];
export const inclusivePackagesList: Array<IListElement> = [
	{
		title: 'What is a Value Inclusive Package?',
		text: PACKAGES_TEXT_I,
		id: v4(),
	},
	{
		title: 'I have special dietary needs and/or food allergies. Can you accommodate them?',
		text: PACKAGES_TEXT_II,
		id: v4(),
	},
	{
		title: 'I have mobility restrictions, and/or I need an Accessible room.',
		text: PACKAGES_TEXT_III,
		id: v4(),
	},
	{
		title: 'Why do you need my passport information?',
		text: PACKAGES_TEXT_IV,
		id: v4(),
	},
	{
		title: 'Can I fly to or from my home airport if it is not listed as an available gateway?',
		text: PACKAGES_TEXT_V,
		id: v4(),
	},
	{
		title: 'Is it possible to fly in earlier or fly out later than the dates listed on the package?',
		text: PACKAGES_TEXT_VI,
		id: v4(),
	},
	{
		title: 'When will flight or hotel information be available for the VIP I recently booked?',
		text: PACKAGES_TEXT_VII,
		id: v4(),
	},
];
export const otherQuestionsList: Array<IListElement> = [
	{
		title: 'Which locations do you serve?',
		text: OTHER_QUESTIONS_TEXT_I,
		id: v4(),
	},
	{
		title: 'Can I use an Online Vacation Center gift card toward a booking I have already made?',
		text: OTHER_QUESTIONS_TEXT_II,
		id: v4(),
	},
	{
		title: 'What should I do if I do not see my question listed?',
		text: OTHER_QUESTIONS_TEXT_III,
		id: v4(),
	},
];
