import Cookies from 'js-cookie';

import { dataRequest } from '@app/services/api';
import { SubscribeData, SubscribeResponse } from '@app/objects/Subscribe';
import { Nullable } from '@app/objects/Utility';

const baseUrl = 'sweeps_signup.cfm?cont=sweeps_process.cfm&response=true';

interface Referral {
	rf: string;
	cid: string;
}

/**
 * Get referral info from url
 */
function getReferralURL(): Nullable<Referral> {
	const params = new URLSearchParams(window.location.search);
	const rf = params.get('rf');
	const cid = params.get('cid');

	if (rf && cid) {
		return {
			rf,
			cid,
		};
	}

	return null;
}

/**
 * Get referral info from cookie
 */
export function getReferralCookie(): Nullable<Referral> {
	const rf = Cookies.get('referrer');
	const cid = Cookies.get('cid');

	if (rf && cid) {
		return {
			rf,
			cid,
		};
	}

	return null;
}

/**
 * Append referral information (rid and cid) to sub form
 * @param form
 */
function appendReferral(form: URLSearchParams): URLSearchParams {
	// Priority:
	//	a) URL data
	//	b) Cookie data

	const referral = getReferralURL() ?? getReferralCookie();

	// Logic with url / cookies being parsed here is temporary
	// When we rewrite subscribe to C# - we'll be able to parse cookies and deal with them on server side
	if (referral) {
		form.append('cookie_referrer', referral.rf);
		form.append('cid', referral.cid);
	}

	return form;
}

/**
 * Convert values object into FormData for request
 * @param values - values from subscribe form
 * @param token - ReCaptcha token
 */
function formatData(values: SubscribeData, token: string): URLSearchParams {
	const formData = new URLSearchParams();
	formData.append('fname', values.firstName);
	formData.append('lname', values.lastName);
	formData.append('zipcode', values.zip);
	formData.append('email', values.email);
	formData.append('agreeWithPP', values.agreeWithPP?.toString());
	formData.append('source', 'OVC');
	formData.append('recaptchaResponse', token);

	return appendReferral(formData);
}

export function subscribe(values: SubscribeData, token: string): Promise<SubscribeResponse> {
	return dataRequest<SubscribeResponse>(formatData(values, token), baseUrl);
}
