import {
	isArrayKey,
	isNumberKey,
	isPackagesKey,
	PackagesFilterKeys,
	PackagesFormValues,
} from '@app/objects/Filters/Packages';
import { isNumber, Nullable } from '@app/objects/Utility';
import {
	Direction,
	isDirectionValue,
	isTagValue,
	Tag,
} from '@app/objects/Sorter';
import { ISearchPackagesURLTraverser, DefaultTraverser } from '@app/services/searchPackages/SearchPackagesURLTraverser';

let shared: Nullable<SearchPackagesURLManager> = null;

function clone(item: PackagesFormValues): PackagesFormValues {
	return JSON.parse(JSON.stringify(item));
}

/**
 * This is a single entry-point for all operations with URL state of SearchPackages
 */
export class SearchPackagesURLManager {
	private search: string = '';
	private dirty: boolean = false;

	private readonly state: PackagesFormValues = {
		[PackagesFilterKeys.CRUISELINE]: [],
		[PackagesFilterKeys.DATE]: [],
		[PackagesFilterKeys.DESTINATION]: [],
		[PackagesFilterKeys.DESTINATIONREGION]: [],
		[PackagesFilterKeys.DURATION]: [],
		[PackagesFilterKeys.SHIP]: [],
		[PackagesFilterKeys.TYPE]: [],

		[PackagesFilterKeys.DIRECTION]: Direction.Ascending,
		[PackagesFilterKeys.TAG]: Tag.Price,
		[PackagesFilterKeys.PAGE]: 1,
	};

	private readonly externalParams: Record<string, string> = {};

	private _parse(): void {
		/// MARK: probably a bit more optimal solution is to determine changes and update only changed fields?
		const search = this.search;
		this.reset();
		this.search = search;

		const params = new URLSearchParams(search);
		params.forEach((value: string, key: string) => {
			if (!isPackagesKey(key)) {
				this.externalParams[key] = value;

				return;
			}

			if (key === PackagesFilterKeys.PAGE) {
				let page = Number.parseInt(value, 10);
				if (Number.isNaN(page) || page < 1) {
					page = 1;
				}

				this.state[PackagesFilterKeys.PAGE] = page;
			}

			if (key === PackagesFilterKeys.TAG) {
				this.state[PackagesFilterKeys.TAG] = isTagValue(value) ? value : Tag.Price;
			}

			if (key === PackagesFilterKeys.DIRECTION) {
				const dir = Number.parseInt(value, 10);
				this.state[PackagesFilterKeys.DIRECTION] = isDirectionValue(dir) ? dir : Direction.Ascending;
			}

			const list = value.split(',').map((item: string) => item.trim());
			if (isArrayKey(key)) {
				if (isNumberKey(key)) {
					const nums = list.map((item: string) => Number.parseInt(item, 10)).filter(isNumber);
					this.set(key, nums);
				} else {
					this.set(key, list);
				}
			}
		});
	}

	public static get shared(): SearchPackagesURLManager {
		if (shared === null) {
			shared = new SearchPackagesURLManager();
		}

		return shared;
	}

	public reset(sort?: boolean): void {
		this.search = '';
		this.dirty = false;

		Object.keys(this.state)
			.filter(isPackagesKey)
			.forEach((key: keyof PackagesFormValues) => {
				if (key === PackagesFilterKeys.TAG) {
					if (sort) {
						this.state[key] = Tag.Price;
					}
				} else if (key === PackagesFilterKeys.DIRECTION) {
					if (sort) {
						this.state[key] = Direction.Ascending;
					}
				} else if (key === PackagesFilterKeys.PAGE) {
					this.state[key] = 1;
				} else {
					this.state[key] = [];
				}
			});
		this.dirty = false;
	}

	public parse(search: string): void {
		if (this.search !== search) {
			this.search = search;

			this.dirty = true;
		}
	}

	public toString(traverser: ISearchPackagesURLTraverser = new DefaultTraverser()): string {
		if (this.dirty) {
			this._parse();
		}

		return Object.keys(this.state)
			.filter(isPackagesKey)
			.filter((key: keyof PackagesFormValues) => traverser.filter(key, this.state[key]))
			.map((key: keyof PackagesFormValues) => traverser.map(key, this.state[key]))
			.concat(this.getExternalParams())
			.join('&');
	}

	public get<TKey extends keyof PackagesFormValues>(key: TKey): PackagesFormValues[TKey] {
		if (this.dirty) {
			this._parse();
		}

		return this.state[key];
	}

	public set<TKey extends keyof PackagesFormValues>(key: TKey, value: PackagesFormValues[TKey]): void {
		if (this.dirty) {
			this._parse();
		}

		this.state[key] = value;
	}

	public setAll(values: Partial<PackagesFormValues>): void {
		if (this.dirty) {
			this._parse();
		}

		Object.keys(values)
			.filter(isPackagesKey)
			.filter((key: keyof PackagesFormValues) => values[key] !== undefined)
			.forEach((key: keyof PackagesFormValues) => {
				const value = values[key];
				if (value === undefined) return;

				this.set(key, value);
			});
	}

	// MARK: do not alter returned value
	public getAll(): PackagesFormValues {
		if (this.dirty) {
			this._parse();
		}

		return clone(this.state);
	}

	public forEach(callback: <TKey extends keyof PackagesFormValues>(key: TKey, value: PackagesFormValues[TKey]) => void) {
		if (this.dirty) {
			this._parse();
		}

		Object.keys(this.state)
			.filter(isPackagesKey)
			.forEach((key: keyof PackagesFormValues) => callback(key, this.state[key]));
	}

	private getExternalParams(): Array<string> {
		return Object.keys(this.externalParams).map((key) => `${key}=${this.externalParams[key]}`);
	}
}
