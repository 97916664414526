import * as Yup from 'yup';

export interface TValues {
	email?: string;
}

export enum Frequency {
	Daily = 0,
	TreeTWeek = 1,
	OnceWeek = 2,
	Pause = 3,
}

export enum Reasons {
	Frequently = 0,
	ManyEmails = 1,
	NotRelevant = 2,
	Other = 3,
}

export interface RadioItem {
	id: number;
	label: string;
}

export type AddressFormValues = Omit<FrequencyFormValues, 'frequency'>;

export interface FrequencyFormValues {
	email: string;
	frequency: Frequency;
}

export interface UnsubscribeFormValues {
	reason: Reasons;
	note: string
}

export const initialValuesAddress: AddressFormValues = {
	email: '',
};

export const initialValuesUnsubscribe: UnsubscribeFormValues = {
	reason: Reasons.Frequently,
	note: '',
};

export const initialValuesFrequency = {
	email: '',
	frequency: Frequency.Daily,
};

export const validationSchemaFrequency = Yup.object().shape({
	email: Yup.string()
		.email('Invalid email format')
		.required('This field is required!'),
	frequency: Yup.number().oneOf([]).required(),
});

export const validationSchemaAddress = Yup.object().shape({
	email: Yup.string()
		.email('Invalid email format')
		.required('This field is required!'),
});

export const validationSchemaUnsubscribe = Yup.object().shape({
	reason: Yup.number().oneOf([Reasons.Frequently, Reasons.ManyEmails, Reasons.NotRelevant, Reasons.Other]).required(),
	note: Yup.string()
		.when('reason', (val) => {
			if (val[0] === Reasons.Other) return Yup.string().required('Please, specify another reason');

			return Yup.string();
		}),
});
