import React, { useEffect, useState } from 'react';

import { AxiosResponse } from 'axios';

import { httpModules } from '../../../../helpers/http.module';

import { FeaturedURL, SellerImagesUrl } from '@app/constants/url.constants';

export interface ISuppliers {
	topSupplier: number;
	supplierId: string;
	supplierName: string;
	supplierLogo: string;
	supplierImage: string;
}

export const SpecialOffers: React.FC = () => {
	const [suppliers, setsuppliers] = useState<Array<ISuppliers>>([]);
	useEffect(() => {
		httpModules
			.get<Array<ISuppliers>>('api/pages/v1/Suppliers')
			.then((response: AxiosResponse<Array<ISuppliers>>) => {
				setsuppliers(response.data);
			})
			.catch((error) => {
				console.log(error);
			});
	}, []);

	return (
		<div className="featured-deals ">
			<div className="featured-outer">
				<h1><b>Featured</b> <span>Cruise Lines</span> </h1>
				<div className="featured-inner-main">
					{
						suppliers && suppliers.map((value) => {
							return (
								<div className="featured-inner " key={value.supplierId}>
									<a href={`${FeaturedURL}${value.supplierId}&type=0,1&order.direction=1&order.tag=Price&page=1`}>
										<div className="featured-card-sec">
											<div className="featured-img">
												<img src={`${SellerImagesUrl}${value.supplierImage}`} alt="" />
											</div>
											<div className="ocania-sec">
												<img src={value.supplierLogo} alt="" />
											</div>
										</div>
									</a>
								</div>
							);
						})
					}
				</div>
			</div>
		</div>
	);
};
