import * as React from 'react';
import clsx from 'clsx';

import {
	ActionMode,
	Button,
	ButtonVariant,
	Color,
} from '@app/components/Button/Button';
import { ListItem } from '@app/objects/List';

import { BrandLogo } from '@app/components/BrandLogo/BrandLogo';
import { PackageType } from '@app/objects/CruisePackages';
import { PriceTag } from '@app/components/Cards/SearchListCard/CardComponents/Price';
import { CardContent } from '@app/components/Cards/SearchListCard/CardComponents/CardContent';
import { CoverType } from '@app/objects/Routes/Route';
import { Modal } from '@app/components/Modal/Modal';
import { Image } from '@app/components/Image';

import fallback from '@image/search-fallback.png';

import '@app/scss/components/searchListCard.scss';

export type SearchListCardProps = ListItem;

export const SearchListCard: React.FC<SearchListCardProps> = (props: SearchListCardProps) => {
	const [isOpen, setIsOpen] = React.useState<boolean>(false);
	const priceValue = props.price?.price ?? null;

	const isJustifyCenter = !priceValue && props.detailsLink;
	const isNoTour = props.type !== PackageType.Tour;

	return (
		<>
			<div className={clsx('search-list-card', !props.image && 'search-list-card_special-grid')}>
				{
					props.image
						? (
							<Image
								src={props.image}
								fallback={fallback}
								alt="Destination"
								className="search-list-card__left-block"
							/>
						)
						: null

				}
				<div className="search-list-card__header content-valign_left content-halign_center">
					<div className="col-10 col-sm-4">
						<img
							src={props.cruiseLineLogo}
							alt={`${props.cruiseLineName} logo`}
							className="search-list-card__header__logo"
						/>
					</div>
					<div className="col-10 col-sm-8 content-valign_center">
						<h4 className="font-family_primary condensed font-weight_bold text-size_medium">
							{props.title}
						</h4>
					</div>
				</div>
				<CardContent
					tour={props.type === PackageType.Tour}
					duration={props.duration}
					subtitle={props.subTitle}
					departureDates={props.dates}
					ports={props.coverType !== CoverType.Map ? props.ports : []}
					includes={props.inclusions}
				/>

				<div className="search-list-card__brand-logo-block">
					{
						isNoTour ? (
							<BrandLogo type={props.type} className="search-list-card__brand-logo" />
						) : null
					}
				</div>

				<div className={clsx('search-list-card__right-block content-halign_center', isJustifyCenter && 'content-valign_center')}>
					{props.price && <PriceTag price={priceValue} name={props.price?.description} tour={props.type === PackageType.Tour} />}
					<div className={clsx('search-list-card__btn-block', !isJustifyCenter && 'search-list-card__btn-block_auto')}>
						{props.detailsLink && (
							<div className="search-list-card__info-btn">
								<Button
									variant={ButtonVariant.Outlined}
									color={Color.Dark}
									className="search-list-card__more-btn"
									action={ActionMode.ExternalLink}
									link={`${process.env.app.hosts.link}${props.detailsLink}`}
									// action={ActionMode.InternalLink}
									// link={RoutePaths.moreDetailsPage}
								>
									More Details
								</Button>
							</div>
						)}
						{isNoTour && (
							<div className="search-list-card__action-btn">
								<Button
									variant={ButtonVariant.Filled}
									style={{ width: '100%' }}
									onClick={() => setIsOpen(true)}
								>
									Book Now
								</Button>
							</div>
						)}
					</div>
				</div>
			</div>
			<Modal
				open={isOpen}
				className="search-list-card__inclusion-modal"
				style={{
					minWidth: '350px',
					width: '75%',
					maxWidth: '850px',
					padding: 12,
					boxShadow: '0 10px 25px rgba(0, 0, 0, 0.5)',
				}}
				content={
					isOpen ? (
						<iframe
							title={props.bookLink}
							id={props.bookLink}
							name={props.bookLink}
							height="100%"
							style={{
								width: '100%',
								border: 'none',
							}}
							src={`${process.env.app.hosts.link}${props.bookLink}`}
						/>
					) : null
				}
				onClose={setIsOpen}
			/>
		</>
	);
};
