import * as React from 'react';

import moment from 'moment';
import clsx from 'clsx';

import { Nullable } from '@app/objects/Utility';
import { IFeedBackInfo } from '@app/objects/Feedback';

import '@app/scss/pages/main/feedback.scss';
import { TrustBox } from '@app/pages/MainPage/SearchCard/TrustBox';

export interface FeedbackProps {
	feedbacksList?: Array<IFeedBackInfo>;
}

interface FeedbackCardProps {
	posted: Nullable<number>;
	text: string;
	author: string;
	location: string;

	isSkeleton?: boolean;
}

const getDate = (date: Nullable<number>) => {
	if (!date) return '';

	const newDateFormat = moment(date).format('l').split('/').map((item: string) => {
		if (item.length === 1) return `0${item}`;

		return item;
	});

	return newDateFormat.join('.');
};

const getAuthorName = (author?: string, location?: string) => {
	if (!author) return '';
	if (!location) return `- ${author}`;

	return `- ${author}, ${location}`;
};

export const FeedbackCard: React.FC<FeedbackCardProps> = (props: FeedbackCardProps) => (
	<div className={clsx('feedback-card content-halign_center content-valign_center', props.isSkeleton && 'feedback-skeleton')}>
		<div className="feedback-card__container content-halign_center content-valign_center col-10">
			<p className="feedback-card__date font-family_primary condensed font-weight_medium text-size_extra-small">
				{getDate(props?.posted)}
			</p>
			<p
				className="feedback-card__description font-family_primary font-weight_regular text-size_extra-small line-height_large"
				title={props.text}
			>
				{props.text}
			</p>
			<p className="feedback-card__author font-family_primary condensed font-weight_medium text-size_extra-small">
				{getAuthorName(props.author, props.location)}
			</p>
		</div>
	</div>
);

export const Feedbacks: React.FC = () => (
	<div className="feedback content-halign_center content-valign_center">
		<div className="feedback__container">
			<div className="feedback__title-block content-halign_center">
				<div className="feedback__title font-family_primary condensed font-weight_semi-bold text-size_hard-large">
					<b>What Our</b>
					<i className="font-family_decorative font-weight_regular"> Clients&nbsp;</i>
					<b>Are&nbsp;</b>
					<i className="font-family_decorative font-weight_regular">&nbsp;Saying</i>
				</div>
				<i className="feedback__quote font-family_decorative font-weight_regular">“</i>
			</div>

			<TrustBox>
				{(_, loader: React.ReactElement) => (
					<div
						className="trustpilot-widget"
						data-locale="en-US"
						data-template-id="54ad5defc6454f065c28af8b"
						data-businessunit-id="6037f8ef3217ab0001f8e69e"
						data-style-height="240px"
						data-style-width="100%"
						data-theme="light"
						data-stars="4,5"
						data-review-languages="en"
						data-font-family="Fira Sans"
						data-text-color="#090e2c"
					>
						<a
							href="https://www.trustpilot.com/review/onlinevacationcenter.com"
							target="_blank"
							rel="noopener noreferrer"
						>
							{loader}
						</a>
					</div>
				)}
			</TrustBox>
		</div>
	</div>
);
