import * as React from 'react';

import { MobileFilters } from '@app/components/Filters/MobileFilters';
import { DesktopFilters } from '@app/components/Filters/DesktopFilters';

export interface BaseFiltersProps {
	children: React.ReactNode;
	extra: React.ReactNode;
	onReset?: () => void;
	canReset?: boolean;

	open: boolean;
	onClose: (value: boolean) => void;
}

export const Filters: React.FC<BaseFiltersProps> = (props: BaseFiltersProps) => (
	<>
		<DesktopFilters {...props}>{props.children}</DesktopFilters>
		<MobileFilters {...props}>{props.children}</MobileFilters>
	</>
);
