import * as React from 'react';

import { Nullable } from '@app/objects/Utility';
import { FetchStore } from '@app/services/fetch/FetchStore';

export function useItemFetchStore<T>(): FetchStore<T> {
	const [loading, setLoading] = React.useState<boolean>(() => false);
	const [error, setError] = React.useState<Nullable<string>>(() => null);
	const [item, setItem] = React.useState<Nullable<T>>(null);

	return {
		get loading(): boolean {
			return loading;
		},
		set loading(value: boolean) {
			setLoading(value);
		},

		get error(): Nullable<string> {
			return error;
		},
		set error(value: Nullable<string>) {
			setError(value);
		},

		get item(): Nullable<T> {
			return item;
		},
		set item(value: Nullable<T>) {
			setItem(value);
		},
	};
}
