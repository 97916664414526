import * as React from 'react';

import { SorterOption } from '@app/objects/Sorter';
import { DesktopSorter } from '@app/components/Sorter/DesktopSorter';
import { MobileSorter } from '@app/components/Sorter/MobileSorter';

import '@app/scss/pages/search/searchList.scss';

import 'rc-dropdown/assets/index.css';

export interface BaseSorterProps {
	options: Array<SorterOption>;

	value?: number;
	onChange: (value: number) => void;

	open: boolean;
	onClose: (value: boolean) => void;
}

export const Sorter: React.FC<BaseSorterProps> = (props: BaseSorterProps) => (
	<>
		<DesktopSorter options={props.options} value={props.value} onChange={props.onChange} />
		<MobileSorter {...props} />
	</>
);
