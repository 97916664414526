import * as React from 'react';

import clsx from 'clsx';
import Collapse, { CollapsePanelProps } from 'rc-collapse';

import { TIcon } from '@app/objects/Utility';
import { Badge } from '@app/components/Badge/Badge';
import { PackagesFilterValue } from '@app/objects/CruisePackages';
import { LoaderOverlay } from '@app/components/Loader/LoaderOverlay';
import {
	SelectableList,
	SelectableListProps,
	SelectableListItemProps,
} from '@app/components/SelectableList/SelectableList';

const Panel = Collapse.Panel;

enum CollapsibleType {
	Disable = 'disabled',
}

// MARK: This, this is not obvious. But that's how rc-collapse works
// You have to implement and pass all CollapsePanelProps if you create a custom wrapper
interface OwnProps<TValue extends PackagesFilterValue> extends Omit<CollapsePanelProps, 'onChange'> {
	title: string;
	Icon: TIcon;

	value: Array<TValue>;
	onChange: (value: Array<TValue>) => void;

	list: SelectableListProps<TValue>['list'];
	loading?: boolean;
}

function getLeaves<TValue extends PackagesFilterValue>(roots: OwnProps<TValue>['list'], values: Array<TValue>): number {
	if (!roots) return 0;

	let count = 0;
	const stack = [...roots];

	while (stack.length) {
		const item = stack.pop();

		if (item) {
			const isLeaf = item.list === undefined || item.list.length === 0;

			if (!isLeaf) {
				item.list!.forEach((node: SelectableListItemProps<TValue>) => stack.push(node));
			} else if (values.includes(item.value)) {
				count++;
			}
		}
	}

	return count;
}

export const PanelWrapper = <TValue extends PackagesFilterValue>(props: OwnProps<TValue>) => {
	const {
		title,
		Icon,
		value,
		onChange,
		list,
		loading,
		...others
	} = props;
	const disabled = !props.list?.length;

	return (
		<Panel
			{...others}
			header={(<Badge count={getLeaves(list, value)}>{title}</Badge>)}
			key={title}
			extra={<Icon />}
			className={clsx('text-size_medium', disabled && 'disabled')}
			openMotion={{
				motionName: 'filter-panel-reveal',
				visible: props.isActive,
			}}
			collapsible={disabled ? CollapsibleType.Disable : undefined}
		>
			<LoaderOverlay loading={loading}>
				<SelectableList
					value={value}
					onChange={onChange}

					list={list}
				/>
			</LoaderOverlay>
		</Panel>
	);
};
