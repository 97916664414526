import { Nullable } from '@app/objects/Utility';

export enum InclusionType
{
	Unknown = -1,
	CruiseNights = 0,
	Airfare = 1,
	Tax = 2,
	Transfers = 3,
	HotelStays = 4,
	Spiffs = 5,
	Specials = 6,
	Promo = 7,
}

export interface RouteInclusion {
	id: string;

	name: string;
	link?: Nullable<string>;

	type: InclusionType;
}
