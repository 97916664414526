import {
	applyMiddleware,
	legacy_createStore as createStore,
	compose,
	combineReducers, AnyAction,
} from 'redux';

import thunk, { ThunkDispatch } from 'redux-thunk';

import { getListReducer, IStateItem } from '@app/store/MainPage/Reducers';
import { IHomePage, IHeaderPage } from '@app/store/MainPage/MainInterfaces';
import { CruisePackages } from '@app/objects/CruisePackages';

export interface RootStore {
	homePage: IStateItem<IHomePage>;
	header: IStateItem<IHeaderPage>;
	packagesFilters: IStateItem<CruisePackages>;
}

export const rootReducer = combineReducers({
	homePage: getListReducer<IHomePage, 'homePage'>('homePage'),
	header: getListReducer<IHeaderPage, 'header'>('header'),
	packagesFilters: getListReducer<CruisePackages, 'packagesFilters'>('packagesFilters'),
});

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
/* @ts-ignore */
// eslint-disable-next-line no-underscore-dangle
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
export const store = createStore(
	rootReducer,
	composeEnhancers(applyMiddleware(thunk)),
);

export type DispatchType<TKey extends keyof RootStore> = ThunkDispatch<RootStore[TKey], never, AnyAction>;
