import React, { useEffect, useState } from 'react';

import { v4 } from 'uuid';

import { Settings } from 'react-slick';

import { AxiosResponse } from 'axios';

import { httpModules } from '../../../../helpers/http.module';

import { Carousel } from '@app/components/Carousel/Carousel';

import { DestinationURL } from '@app/constants/url.constants';

export interface IFeaturedDeatination {
	destinationId: string;
	bookingId: number;
	product: string;
	pid: number;
	imgName: string;
}

const FEATURED_DEALS = 'featured_deals_id';

function redirectToAnotherUrl(destinationId: string) {
	window.location.href = `${DestinationURL}${destinationId}&type=0,1&order.direction=1&order.tag=Price&page=1`;
}

function getButtonText(product: string) {
	if (product === 'Transatlantic') {
		return `Explore ${product} Crossings`;
	}
	if (product === 'Australia/New Zealand') {
		return 'Explore Australia & New Zealand';
	}

	return `Explore ${product}`;
}

export const FeaturedDeatination: React.FC = () => {
	const [FeatureDeatination, setFeatureDeatination] = useState<Array<IFeaturedDeatination>>([]);
	useEffect(() => {
		httpModules
			.get<Array<IFeaturedDeatination>>('api/pages/v1/FeaturedDestination')
			.then((response: AxiosResponse<Array<IFeaturedDeatination>>) => {
				setFeatureDeatination(response.data);
			})
			.catch((error) => {
				console.log(error);
			});
	}, []);

	const settings: Settings = {
		dots: false,
		centerPadding: '0',
		infinite: true,
		speed: 500,
		slidesToShow: 1,
		slidesToScroll: 1,
		arrows: false,
		className: 'slider',
		responsive: [
			{

				breakpoint: 1024,
				settings: {
					centerPadding: '0',
					infinite: true,
					slidesToScroll: 1,
					className: 'slider',
					slidesToShow: 1,
				},
			},
			{
				breakpoint: 576,
				settings: {
					centerPadding: '0',
					infinite: true,
					slidesToScroll: 1,
					className: 'slider',
					slidesToShow: 1,
				},
			},

		],
	};

	const widthCardRef = React.useRef<HTMLDivElement>(null);

	return (
		<div className="carousel-container featuredDestinationMain">
			<div className="carousel-container__center featuredDestination">
				<div className="col-12 col-xs-7 col-sm-5">
					<div className="carousel-container__title-block text-size_hard-large layout-container dense content-valign_center">
						<div className="carousel-container__title font-family_primary condensed font-weight_semi-bold">
							<b>Featured </b>
							<i className="font-family_decorative font-weight_regular"> Destinations </i>
						</div>
					</div>
				</div>
				<Carousel
					className="deals-carousel col-12 featured-destination"
					total={FeatureDeatination?.length ?? 0}
					settings={settings}
					cardRef={widthCardRef}
				>
					{FeatureDeatination && FeatureDeatination.map((value) => (
						<div key={value.pid ?? v4()} ref={widthCardRef} id={FEATURED_DEALS}>
							<img src={`https://www.onlinevacationcenter.com/images/vipExclusives/destinations/${value.imgName}`} alt="" />
							<button className="btnfeatured" type="button" onClick={() => redirectToAnotherUrl(value.destinationId)}>
								{getButtonText(value.product)}
							</button>
						</div>
					))}
				</Carousel>
			</div>
			<div className="carousel-container__right col-xl-7" />
		</div>
	);
};
