import { Destination } from '@app/odesseus/types/Destination';
import { NitroResponse } from '@app/odesseus/types/NitroResponse';
import { Port } from '@app/odesseus/types/Port';
import { CruiseLine } from '@app/odesseus/types/CruiseLine';
import { Ship } from '@app/odesseus/types/Ship';
import { Country } from '@app/odesseus/types/Country';
import { State } from '@app/odesseus/types/State';
import {
	FacetPage,
	FacetResponse,
} from '@app/odesseus/types/Facet';
import { OdyManager } from '@app/odesseus/OdyManager';
import { OdesseusFilterRecord } from '@app/odesseus/types/Filters/Odesseus';

const manager = new OdyManager();

function baseRequest<T>(type: string, params: Array<string> = []): Promise<Array<T>> {
	params.push('requestSource=2');

	return fetch(`https://nitroapi.odysol.com/nitroapi/v2/master/all/${type}?${params.join('&')}`, {
		headers: {
			siteitemid: '228902',
			uniquetid: manager.uniquetid,
		},
	})
		.then((response: Response) => {
			if (!response.ok) throw Error(response.statusText);

			return response.json();
		})
		.then((result: NitroResponse<T>) => {
			if (!result.isSucceed) throw Error(`Failed to load ${type}`);

			return result.data;
		});
}

// eslint-disable-next-line max-len
const onlyFacetsUrl = 'https://nitroapi.odysol.com/nitroapi/v2/cruise/facets?&includeFacets=hasHqGroupRate,hasAgGroupRate,uniqueItineraryId&requestSource=2';
const getUrl = (page?: number) => `https://res.onlinevacationcenter.com/nitroapi/v2/cruise?
&sortColumn=cruiselinePriority&sortOrder=asc&pageStart=${page}
&includeFacets=uniqueId&pageSize=9&fetchFacets=true&groupByItineraryId=true&applyExchangeRates=true&requestSource=1`;
function facetRequest(filters?: Array<OdesseusFilterRecord>, page?: number): Promise<FacetPage> {
	const url = page ? getUrl(page) : onlyFacetsUrl;

	return fetch(url, {
		method: 'POST',
		headers: {
			siteitemid: '228902',
			uniquetid: manager.uniquetid,
			'Content-Type': 'application/json',
		},
		body: JSON.stringify({ filters }),
	})
		.then((response: Response) => {
			if (!response.ok) throw Error(response.statusText);

			return response.json();
		})
		.then((result: FacetResponse) => {
			if (!result.isSucceed) throw Error('Failed to load facet');

			return result.data;
		});
}

export function fetchDestinations(): Promise<Array<Destination>> {
	return baseRequest<Destination>('destination');
}

export function fetchPorts(): Promise<Array<Port>> {
	return baseRequest<Port>('port');
}

export function fetchCruiselines(): Promise<Array<CruiseLine>> {
	return baseRequest<CruiseLine>('cruiseline');
}

export function fetchShips(): Promise<Array<Ship>> {
	return baseRequest<Ship>('ship');
}

export function fetchCountries(): Promise<Array<Country>> {
	return baseRequest<Country>('country');
}

export function fetchStates(): Promise<Array<State>> {
	return baseRequest<State>('state');
}

export function fetchFacets(filters?: Array<OdesseusFilterRecord>, page?: number): Promise<FacetPage> {
	return facetRequest(filters, page);
}
