/* eslint-disable max-len */
import * as React from 'react';

import { LinkType, link } from '@app/pages/StaticPages/utils';
import { RoutePaths } from '@app/utilities/RoutePathVars';

export const INTRO = `
These Terms of Conditions ("Terms of Use"), including any revised terms that we may post from time to time,
state the terms and conditions under which Online Vacation Center, Inc. and its various affiliates
(collectively referred to herein as "OVC," "we," "our," "us" and "Company") provide you and your family, friends and
guests (collectively referred to herein as "you" and "your") with various services and the agreement between you and us
regarding your use of this website and those services. BY ACCESSING, BROWSING, AND/OR USING OUR WEBSITE AND/OR SERVICES
ON THE WEBSITE AND/OR THROUGH OUR CALL SERVICE CENTER, YOU ARE DEEMED TO ACCEPT THESE TERMS OF USE AND AGREE TO BE BOUND
BY THESE TERMS OF USE WITH RESPECT TO THE USE OF OUR SERVICES, WEBSITE AND/OR CALL SERVICE CENTER. IF YOU DO NOT WISH TO
BE BOUND BY THESE TERMS OF USE OR DO NOT MEET THE USE REQUIREMENTS LISTED HEREIN, PLEASE DO NOT ACCESS, BROWSE, OR USE
OUR WEBSITE OR ANY SERVICES WE PROVIDE. ADDITIONALLY, IF YOU DO NOT WISH TO BE BOUND BY THESE TERMS OF USE OR DO NOT
MEET THE USE REQUIREMENTS LISTED HEREIN, NO SALE TRANSACTION SHALL BE DEEMED TO HAVE OCCURRED OR COMPLETED AND YOU
SHOULD CONTACT THE COMPANY IMMEDIATELY FOR A REFUND OF ANY PAYMENTS PREVIOUSLY MADE. We may make changes to these Terms
of Use from time to time in our sole discretion. Each time changes are made to these terms, a revised Terms of Use
will be posted on our home page. Your continued use of our Site following the posting of changes constitutes your
acceptance of any such changes.`;
const INTELLECTUAL_PROPERTY = `
The contents of the www.onlinevacationcenter.com website ("Site") are intended for the personal use of our users.
The Site is owned and operated by the Company. All right, title, and interest to the content displayed on our Site,
including but not limited to the Site's look and feel, data, information, text, graphics, images, sound or video
materials, photographs, designs, trademarks, service marks, trade names, URLs, and content provided by third parties,
are the property of the Company, or third parties, and are protected by copyright, trademark, patent or other
proprietary rights and laws. Except as expressly authorized by us, you agree not to copy, modify, rent, lease, loan,
sell, assign, distribute, perform, display, license, reverse engineer, or create derivative works based on the Site or
any content (including without limitation any software) available through the Site. Our Site is not to be used to
distribute or transmit any content that infringes or violates another person's rights under copyright law.`;
const PRIVACY_POLICY = (
	<p>
		The information that we obtain through your use of our Site, whether through the registration process or
		otherwise, is subject to the privacy policy posted on our Site ("Privacy Policy"). Our Privacy Policy contains
		terms and conditions that govern our collection and use of the information you provide to us, including our
		respective rights relative to that information. Please review our Privacy Policy before you use our services.
		If you are unwilling to accept the terms and conditions of our Privacy Policy, please do not use our services.
		You agree not to modify our services in any manner or form, or to use modified versions of our services,
		including (without limitation) for the purpose of obtaining unauthorized access to our services or portions of
		the services. You agree not to access our services by any means other than through the interface that is
		provided by us for use in accessing our services. Except as expressly provided in this Terms of Use, nothing
		contained in this agreement or on the Site shall be construed as conferring any other license or right,
		expressly, by implication, by estoppels or otherwise, with respect to any of our content or under any third
		party's content. Any rights not expressly granted herein are reserved.
		{link(RoutePaths.privacyPolicy, LinkType.Internal, 'Click here')} to see our full privacy policy details.
	</p>
);
const CALIFORNIA_PRIVACY = (
	<p>
		If you are a California resident, California law may provide you with additional rights regarding our use of
		your personal information. To learn more about your California privacy rights, visit {link('https://www.onlinevacationcenter.com/CCPA.cfm', LinkType.External, 'Privacy Notice for California Residents')}.
		California's "Shine the Light" law (Civil Code Section § 1798.83) permits users of our App that are California
		residents to request certain information regarding our disclosure of personal information to third parties for
		their direct marketing purposes. To make such a request, please send an email to {link('privacy@onlinevacationcenter.com', LinkType.Mail)}.
	</p>
);
const LIABILITY = `
OVC sells vacation travel provided by third parties (each, a "Travel Supplier") that OVC does not control.
OVC is not liable or responsible in any respect for breach of contract, any action or inaction, commission or omission
whether intentional, negligent or otherwise, on the part of such third party suppliers resulting in any loss, damage,
delay or injury to you or your travel companions. Vacation itineraries, including changes after the sale which may
include missed ports of call, are the sole responsibility of the supplier. Furthermore, OVC assumes no responsibility
for overbooking, cancellation or delays by hotels, airlines, cruise lines, tour operators or ground transportation or
other providers of service. In the event that a travel supplier declares bankruptcy, OVC will not be held responsible.
The travel vendor may or may not be required to refund paid fares. OVC cannot be held financially responsible for any
vendor that declares bankruptcy. You agree that OVC is not responsible for any potential risks and hazards associated
with travel. Assessed government or quasi-government fees and taxes are subject to change without notice at any time,
and suppliers reserve the right to add a surcharge for these fees and taxes even if you have a confirmed booking under
deposit or have made final payment. All surcharges will be automatically charged to your credit card or in the event of
payment by check, you will be asked to immediately remit additional funds.`;
const PUBLICITY_RELEASE = (
	<>
		<p>
			OVC desires to use and publicize your image and likeness for advertising, promotion, and other commercial
			and business purposes. By agreeing to these terms and conditions, you are giving OVC permission to use and
			publicize your image and likeness for advertising, promotion, and other commercial and business purposes.
			You hereby irrevocably permit, authorize, grant, and license OVC and its affiliates, successors,
			and assigns, and their respective licensees and advertising agencies, the rights to display, reproduce,
			record, photograph, digitize, modify, alter, edit, adapt, create derivative works, exploit, license,
			otherwise use, and permit the use of your image, likeness, and appearance, and all materials created by or
			on behalf of OVC that incorporate any of the foregoing in perpetuity throughout the universe in any medium
			or format whatsoever now existing or hereafter created, including but not limited to, in and on magazines,
			brochures and other print publications, any electronic advertising and promotional materials, and the
			website of OVC and/or its affiliates, on any platform and for any advertising, public relations, publicity,
			and promotion of OVC and its affiliates and their respective businesses, products, and services, without
			further consent from or royalty, payment, or other compensation to you.
		</p>
		<p>
			At times, OVC may request and/or you may provide photographs and pictures, whether in print or digital form,
			to OVC from your travels which were booked through OVC. By agreeing to these terms and conditions, you
			certify that you are the sole owner of, and are the sole owner of any copyright to, any photographs and/or
			pictures, whether in print or digital, that you provide to OVC. By providing such photographs or pictures
			to OVC, you thereby irrevocably permit, authorize, grant, and license OVC and its affiliates, successors,
			and assigns, and their respective licensees and advertising agencies, the rights to display, reproduce,
			record, photograph, digitize, modify, alter, edit, adapt, create derivative works, exploit, license,
			otherwise use, and permit to use those photographs or pictures for advertising, public relations, publicity,
			and promotion of OVC and its affiliates and their respective businesses, products, and services, whether by
			print or electronic means, without any further consent from or royalty, payment, or other compensation to you.
		</p>
		<p>
			To the fullest extent permitted by applicable law, you hereby irrevocably waive all legal and equitable
			rights relating to all liabilities, claims, demands, actions, suits, damages, and expenses, including but
			not limited to claims for copyright or trademark infringement, infringement of moral rights, libel,
			defamation, invasion of any rights of privacy (including intrusion, false light, public disclosure of
			private facts, and misappropriation of name or likeness), violation of rights of publicity, physical or
			emotional injury or distress, or any similar claim or cause of action in tort, contract, or any other legal
			theory, now known or hereafter known in any jurisdiction throughout the world arising directly or indirectly
			from OVC exercise of their rights pursuant to these Terms or the production, exhibition, exploitation,
			advertising, promotion, or other use of your photographs and pictures and your image or likeness, and
			whether resulting in whole or in part by the negligence of OVC or any other person, covenant not to make or
			bring any such claim against OVC and its affiliates, successors, and assigns, and forever release and
			discharge OVC and its affiliates, successors, and assigns from liability under such claims.
		</p>
	</>
);
const BOOKING_DETAILS = `
It is the responsibility of the recipient to verify accuracy of all reservation information contained on the
confirmation. Names provided to OVC must match legal names as they appear on your passport or government issued photo
ID. OVC must be notified immediately if any changes are necessary. Failure to advise OVC in a timely manner may result
in substantial penalties.`;
const TRAVEL_DECLARATION = (
	<p>
		Due to the unique circumstances presented by COVID-19, travel procedures and requirements may be different
		than in the past. Online Vacation Center is committed to the well-being of our travelers, team members and
		partners. It is essential that we all stay informed and take personal responsibility during this time.
		By booking a reservation with Online Vacation Center, you hereby acknowledge and agree to this Travel
		Declaration. {link('https://www.onlinevacationcenter.com/terms_declaration.cfm', LinkType.External, 'Click here')} to see our full travel declaration details.
	</p>
);
const PRIOR_TO_TRAVEL = (
	<div className="text-uppercase">
		<p>
			It is your responsibility to (a) verify passport requirements, visa entry and health and other requirements
			for entry to and exit from each country you and your travel companions will enter as part of the purchased
			travel package, and (b) to obtain such updated passports, visas and other travel documents, and to meet such
			health and other country travel requirements as may in every case be necessary or required to travel in
			accordance with the purchased travel package (collectively, the "Travel requirements"). As an example and
			without limitation to any other travel requirements, certain countries require u.S. Citizens' passports to
			expire no less than six (6) months after the date of their scheduled return date from that country.
			This six (6) month expiration period may be more or less in other destinations. If you or any of your travel
			companions fail to comply with the travel requirements, you and/or your travel companions will be prevented
			from, in addition to other restrictions or penalties that may apply, (a) boarding any plane, ship or other
			mode of transportation, and/or (b) otherwise traveling to your departure destination. If such were to occur,
			you bear sole and full liability for such failure including, without limitation, forfeiting anything you
			paid to purchase the applicable travel package. Any travel insurance you may have purchased in connection
			with your travel package does not cover any loss due to your or your travel companions' failure to comply
			with the travel requirements.
		</p>
		<p>
			To determine the travel requirements, u.S. Citizens may visit, amongst other sources, the u.S. Department of
			state website or contact the united states' department of state directly via phone. Non-u.S. Citizens must
			check with their individual embassy regarding passport, visa and immunization and other travel requirements.
		</p>
		<p>
			Air arrangements purchased through a cruise line are generally made available 30 days prior to departure.
			Please note that air schedules are booked at the discretion of the cruise line and cannot be changed. Custom
			air requests must be arranged prior to final payment. The minimum charge for custom air itineraries is
			$50-$75 per person and additional charges may apply. It is your responsibility to confirm seat assignments
			and other special services, such as special meals or wheelchair assistance directly with the travel supplier. Y
			ou and your travel companions that are traveling together, but booked or deposited at different times, may
			be required to travel on different flights.
		</p>
		<p>
			Air arrangements purchased through a cruise line are generally made available 30 days prior to departure.
			Please note that air schedules are booked at the discretion of the cruise line and cannot be changed. Custom
			air requests must be arranged prior to final payment. The minimum charge for custom air itineraries is $50-$75
			per person and additional charges may apply. It is your responsibility to confirm seat assignments and other
			special services, such as special meals or wheelchair assistance directly with the travel supplier. You and
			your travel companions that are traveling together, but booked or deposited at different times, may be
			required to travel on different flights.
		</p>
	</div>
);
const TICKETS_DOCUMENTS = (
	<p>
		Travel documents are customarily distributed approximately 2 weeks prior to the departure date.
		Please review the travel documents for accuracy. If any corrections or changes are required,
		contact OVC immediately at {link('8007809002', LinkType.Phone)}
	</p>
);

const PAYMENT = (
	<>
		<p className="text-uppercase">
			Ovc is not required to give refunds or reimburse guests for any amounts paid to ovc, but may,
			in ovc's sole option, issue travel vouchers to guests. Interruption or cancellation requests for refunds by
			any guest after the scheduled departure date are not permitted. Notice of cancellation must be received by
			ovc a minimum of 5 business days prior to supplier deadlines.
		</p>
		<p>
			All requests for refunds must be in writing to OVC and mailed to 2307 West Broward Blvd., Suite 400, Fort
			Lauderdale, Florida 33312 or faxed to (954) 377-6368.
		</p>
		<p>
			Cruise tickets, air tickets, transfer coupons and/or vacation package vouchers and other OVC or supplier
			documentation must be returned to OVC before a refund can be processed. Refund processing time is about two
			credit card billing cycles or approximately 8 weeks. For detailed information regarding the travel providers
			cancellation policies or terms and conditions, refer to the travel provider's website.
		</p>
		<p>
			Cancellation penalties are unique and determined by each supplier. Penalties may begin at time of booking
			and are based upon the supplier's published rate before any adjustments. Certain exclusive promotions may
			follow a different penalty schedule than the supplier's standard policy. All notice of cancellation must be
			received by OVC a minimum of 5 business days prior to supplier deadlines.
		</p>
		<p>
			Nonrefundable Agency Fees are incorporated in all bookings made after October 15, 2002. All OVC shore
			excursions and hotel stays are nonrefundable once paid.
		</p>
		<p>
			Your credit card will be charged within 24 hours of your reservation.
		</p>
	</>
);
const AUTOMATED_QUERYING = `You may not send automated queries of any sort to our Site without express 
permission in advance from us.`;
const USE_OF_SITE = `
Impersonate any person or entity or falsely state or otherwise misrepresent your 
affiliation with a person or entity; Post any unsolicited or unauthorized advertising, promotional materials,
junk mail, spam, chain letters, pyramid schemes, or any other form of solicitation except in areas that are expressly
designated by us for such purpose; Disseminate or transmit any content that infringes or violates any party's copyright,
trademark, trade secret, patent or other proprietary right; Disseminate or transmit any worms, viruses or other
harmful, disruptive or destructive files, code or programs; Violate any law (whether local, state, national, or
international), whether or not intentionally; Forge headers or otherwise manipulate identifiers in order to disguise
the origin of any Posting; Collect or store personal data about other users, including engaging in screen scraping
collecting usernames, e-mail addresses, or other personally identifiable information of members by electronic,
automated scripts or other means; Engage in any activities that would violate the personal privacy rights of others,
including but not limited to, collecting and distributing information about Internet users without their permission,
except as permitted by applicable law. If we believe that anyone has violated this provision or any other provision of
these Terms of Use or otherwise violated the law, we retain the right to investigate and/ or pursue legal action
against such person.`;
const EXTERNAL_LINKS = `
A central part of our services includes links to other websites or resources. Because we have no control over
such sites and resources, you acknowledge and agree that we are not responsible for the availability of such external
sites or resources, and do not endorse and are not responsible or liable for any content, advertising, products, or
other materials on or available from such sites or resources. You further acknowledge and agree that we shall not be
responsible or liable, directly or indirectly, for any damage or loss caused by or in connection with use of or reliance
on any such content, goods or services available on or through any such site or resource. Finally, you acknowledge
that such external sites usually have their own terms and conditions, including privacy policies, over which we have
no control and which will govern your rights and obligations with respect to the use of those websites.`;
const YOUR_CONTACT = `
Your dealings with advertisers and third party vendors found on or through our services, including your participation
in promotions, the purchase of goods, services, and any terms, conditions, warranties or representations associated with
such activities, are solely between you and the third party. We do not make any representations or warranties with
respect to any goods or services or website that may be obtained from such third parties, and you agree that we will
have no liability for any loss or damage of any kind incurred as a result of any activities you undertake in connection 
with the use of or reliance on any content, goods, services, information or other materials available, or through such
third parties, on our Site. You acknowledge that such external sites usually have their own terms and conditions,
including privacy policies, over which we have no control and which will govern your rights and obligations with respect
to the use of our Site.`;
const COMPLIANCE_WITH_LAWS = `
You agree to comply with all applicable laws regarding your use of our Site.
`;
const MODIFICATION = `
We reserve the right at any time and from time to time to modify, suspend, discontinue, or terminate our services 
(or any part thereof) with or without notice. You agree that we will not be liable to you or to any third party for any 
modification, suspension, discontinuation, or termination of our services.
`;
const DISCLAIMER_OF_WARRANTIES = (
	<p className="text-uppercase">
		By using our services you understand and agree that: our services are provided for informational purposes only.
		We are not responsible or liable for any consequences relating directly or indirectly to any action or inaction
		you take based on the information, services or other material on our site. Your use of our services is at your
		sole risk. Our services are provided on an "As is" and "As available" basis. We expressly disclaim all warranties
		of any kind, whether express or implied, including, but not limited to, the implied warranties of title,
		merchantability, fitness for a particular purpose and non-infringement. We make no representation or warranty
		that our services will meet your requirements, that our services will be uninterrupted, secure, current or
		error-free, that the results that may be obtained from the use of our service will be accurate, timely, useful
		or reliable, or that the quality of any postings, products, services, information or other material obtained by
		you through our services will meet your needs.
	</p>
);
const LIMITATION_OF_LIABILITY = (
	<p className="text-uppercase">
		You understand and agree that we are not liable for any indirect, incidental, special, consequential, exemplary,
		or punitive damages, including, but not limited to, any loss of use, loss of profits, loss of data, loss of
		goodwill, cost of procurement of substitute services, or any other indirect, incidental, special, consequential,
		exemplary or punitive damages, howsoever caused, and on any theory of liability, whether for breach of contract,
		tort (including negligence and strict liability), or otherwise resulting from: (1) the use of, or the inability
		to use, our services, (2) the cost of procurement of substitute services and goods, (3) unauthorized access to
		or alteration of your transmissions or data, (4) the statements or conduct of any third party on our site, (5)
		reliance on content or postings on our site, or (6) any other matter relating to our site or our services
		including, without limitation you or any other person's failure to comply with any of the travel requirements.
		These limitations will apply whether or not we have been advised of the possibility of such damages and
		notwithstanding any failure of essential purpose of any limited remedy. Except as required by the indemnity
		provisions herein, in no event will our direct liability hereunder exceed the amount we actually received in the
		applicable transaction, which amount is limited to commissions, if any, payable by the applicable travel
		suppliers to us. Some jurisdictions do not allow the limitation or exclusion of liability for indirect,
		incidental, special, consequential, exemplary or punitive damages, so some of the above limitations may not
		apply to you.
	</p>
);
const INDEMNIFICATION = `
You agree to indemnify and hold us and our subsidiaries, and affiliates, and their officers, members, directors,
employees and agents harmless from and against any and all liability, losses, claims, demands, disputes, damages or 
costs of any kind, including, without limitation, reasonable attorneys' fees and costs of litigation, resulting from or 
in any way connected with (a) your use of or reliance on our services, (b) your connection to our Site, (c) your
violation of this these Terms of Use, (d) your violation of any rights of another party, or (e) your or any other 
person's failure to comply with the Travel Requirements.
`;
const LIMITATIONS_OF_ACTIONS = `You agree that any claim or cause of action arising out of your use of
our services or Site or these Terms of Use must be filed within the applicable statute of limitations for such a claim 
or cause of action or it shall forever be barred,. Within this period, any failure by us to enforce or exercise any 
provision of these Terms of Use or related right shall not constitute a waiver of that right or provision.
`;
const DISPUTES = `
The parties hereby agree that any action, dispute, claim, or controversy between or among you and us arising out of or 
in connection with your use of our services, Site, Call Service Center and/or these Terms of Use, whether sounding in 
contract, tort, or otherwise ("Dispute"), shall be resolved by binding arbitration in accordance with the Commercial 
Arbitration Rules of the American Arbitration Association, AT THE SOLE DISCRETION OF THE COMPANY. In the event of any 
inconsistency between such Rules and these arbitration provisions, these provisions shall supersede such Rules. All 
statutes of limitations, which would otherwise be applicable shall apply to any arbitration proceeding described herein.
In any arbitration proceeding subject to these provisions, the arbitrators are specifically empowered to decide
(by documents only, or with a hearing, at the arbitrators' sole discretion) pre-hearing motions which are 
substantially similar to pre-hearing motions to dismiss and motions for summary adjudication. ANY ARBITRATION 
PROCEEDING SUBJECT TO THESE PROVISIONS SHALL BE CONDUCTED IN BROWARD COUNTY, FLORIDA. In the event that the Company 
elects to resolve any Dispute through arbitration, both parties agree that such final and binding arbitration shall be
the EXCLUSIVE REMEDY in that Dispute. You understand and agree that by signing this agreement you EXPRESSLY WAIVE ANY
RIGHT TO LITIGATE IN STATE OR FEDERAL COURT, TO PURSUE ADJUDICATION BY AN ADMINISTRATIVE AGENCY, OR TO UTILIZE ANY 
OTHER MEANS OTHER THAN FINAL AND BINDING ARBITRATION CONDUCTED IN THE MANNER PROVIDED IN THESE TERMS OF USE. 
Judgment upon the award rendered may be entered in any court of appropriate subject matter jurisdiction, which 
judgment may be domesticated in any jurisdiction for purposes of enforcement. The parties to these Terms of Use 
expressly subject themselves to the personal jurisdiction of any such court for the entry of any such judgment and 
for the resolution of any dispute, action or suit arising in connection with the entry of such judgment. Any Dispute
subject to arbitration proceedings in accordance with these Terms of Use shall be decided by a majority vote of 
three arbitrators. The arbitrators shall have the power to award recovery of all costs and fees (including 
attorneys' fees, administrative fees, arbitrators' fees and court costs) to the prevailing party. In the event 
this provision is deemed unenforceable by an arbitrator or court of competent jurisdiction for any reason, then and
only then, the below provision governing venue and jurisdiction shall exclusively apply to any lawsuit involving 
claims described in this Clause.`;
const CLASS_ACTION = (
	<p className="text-uppercase">
		These terms of use provide for the exclusive resolution of disputes through individual legal action on each
		party's own behalf instead of through any class action. Even if the applicable law provides otherwise, you agree
		that any arbitration or lawsuit against company whatsoever shall be litigated by you individually and not as a
		member of any class or as part of a class action, and you expressly agree to waive any law entitling you to
		participate in a class action. Further, the parties agree that the arbitrator shall have no authority to
		consolidate proceedings for more than one person's claims and may not otherwise preside over any form of a
		representative or class proceeding. This section shall not be severable under any circumstances from the
		arbitration of disputes clause set forth above.
	</p>
);
const APPLICABLE_LAWS = (
	<p>
		OVC is a registered Seller of Travel with the States of Florida (CST-32947), Washington (WA SOT 6022583) and
		California (CST-2064227-40). Registration as a seller of Travel in California does not constitute the State's
		approval. California law requires certain sellers of travel to have a trust or bond. OVC has a trust account and
		is not a participant in the California Travel Consumer Restitution Corporation. Federal law forbids the carriage
		of certain hazardous materials, such as aerosols, fireworks, and flammable liquids, aboard the aircraft. If you
		do not understand these restrictions, contact your airline or go to {link('http://www.faa.gov/about/initiatives/hazmat_safety', LinkType.External)}.
	</p>
);
const JURISDICTION = (
	<>
		<p className="text-uppercase">
			Ovc policies shall be governed by and construed in accordance with the laws of the state of florida.
			You and ovc agree to submit to the exclusive jurisdiction over all disputes hereunder and venue in an
			appropriate state or federal court located in the state of florida in broward county.
		</p>
		<p className="text-uppercase">
			You agree that any action at law or in equity arising out of or relating to these terms shall be filed only
			in the state or federal courts located in broward county, florida and you hereby consent and submit to the
			personal jurisdiction of such courts for the purposes of litigating any such action.
		</p>
	</>
);
const FINAL_PAYMENT = (
	<p className="text-uppercase">
		I understand that submission of this final payment shall constitute my acceptance of and agreement to be bound
		by the terms and conditions provided herein. I understand and agree that if I fail to accept these terms no sale
		transaction shall be deemed to have occurred or completed and the company shall be relieved of any responsibility
		to provide any services. If I do not accept or wish to be bound by the terms and conditions, I shall contact the
		company and notify them immediately and I shall be entitled to a refund of any payments previously made.
	</p>
);
const ATTORNEYS = `
In the event of a legal action or other proceeding arising out of or in connection with your use of our services, Site,
 Call Service Center and/or these Terms of Use, regarding any alleged breach, default, claim, or misrepresentation
 arising out of such use, whether or not a lawsuit or other proceeding is filed, the prevailing party shall be entitled 
 to recover its reasonable attorneys' fees and costs, whether incurred before suit, during suit, or at the appellate level. 
 The prevailing party shall also be entitled to recover any attorneys' fees and costs incurred in litigating the entitlement 
 to attorneys' fees and costs, as well as in determining or quantifying the amount of attorneys' fees and costs due to it.
 `;
const RECOVERABLE_COSTS = `
The reasonable costs that the prevailing party shall be entitled to recover pursuant to the foregoing paragraph shall 
include any costs that are taxable pursuant to any applicable statute, rule, or guideline (including, but not limited to,
the Florida Statewide Uniform Guidelines for Taxation of Costs), as well as costs not taxable thereunder. 
Such recoverable costs shall specifically include, but not be limited to,
1) costs of investigation;
2) costs of copying documents and other materials, whether for discovery, filing with the court, internal review, or any other purpose;
3) costs for electronic discovery;
4) Westlaw, Lexis Nexis, or other electronic research service charges;
5) telephone charges;
6) mailing, commercial delivery service, and courier charges;
) travel expenses, whether for investigation, depositions, hearings, trial, or any other purpose;
8) information technology support charges;
9) any and all consultant or expert witness fees, whether or not such fees are incurred in connection with a
court-ordered report or testimony at a deposition, hearing, or trial;
10) court reporter and transcript fees, whether for deposition, trial, or an evidentiary or non-evidentiary hearing;
11) mediator fees; and
12) any other reasonable cost incurred by the prevailing party in connection with the dispute.
`;
const EXCURSIONS = (
	<>
		<p>
			In certain instances, you may purchase guided tours ("Guided Tours") from OVC, wherein OVC shall be
			the supplier and/or operator of the Guided Tour instead of a Travel Supplier. The Guided Tours that you
			may purchase from OVC may include, but are not limited to, tours involving motor coaches and/or buses,
			certain physical activities, experiences, and/or attractions (collectively "Excursions"). Excursions may
			include, but shall not be limited to, hiking, prolonged walking, and/or prolonged exposure to heat, sunlight,
			humidity, etc. To enjoy an Excursion as intended, a minimum level of health and/or fitness may be required.
			By signing up for any Excursion(s), you hereby acknowledge and agree that you are fit for such Excursion,
			that you are prepared to experience the prolonged exposure to the elements, if applicable, and that you have
			provided notice to OVC if you have any physical condition, physical disability, mental disability, and/or
			ailment that may potentially prevent you from participating or make it difficult for you to participate in
			an Excursion or create a hazard to himself, herself, or to other members of the Excursion group. You are
			solely responsible for ensuring that: (i) all individuals on your reservation participating in an Excursion
			are medically cleared and physically capable of participating; and (ii) any and all conditions are disclosed
			to OVC that may potentially prevent a participate from participating in an Excursion.
		</p>
		<p>
			Certain Excursions may have conditions that may require you to produce a doctor's certificate to OVC prior
			to travel and/or prior to the beginning of the Excursion. OVC is not required, but in its sole and absolute
			discretion, will make reasonable efforts to accommodate a disability-related request for accommodation.
			However, if OVC, in its sole and absolute discretion, is unable to properly accommodate you, or OVC believes
			that your or any other participant's health and safety may be compromised, OVC reserves the right to refuse
			participation. By signing up for any Excursion, you represent that neither you nor anyone travelling with
			you has any condition that could create a hazard to himself or herself or other members of the Excursion
			group and/or Guided Tour. OVC reserves the right to remove from the Excursion, Guided Tour, and/or trip, at
			your own expense, any participant whose physical condition, mental condition, or conduct (i) negatively
			impacts the enjoyment of the other participants, (ii) creates a hazard to himself or herself or other
			members of the Excursion group and/or Guided Tour, or (iii) disrupts the Excursion and/or Guided Tour.
		</p>
		<p>
			Neither OVC its affiliates or subsidiaries, nor any of their respective employees, shareholders, officers,
			directors, successors, representatives, agents and assigns (collectively the "OVC Parties"), owns or
			operates any entity which is to or does provide goods or services for an Excursion, with the exception of a
			limited number of vehicles. All such persons and entities are independent contractors. As a result, the
			OVC Parties are not liable for any negligent or willful act of any such person or entity or of any third
			person. In addition and without limitation, the OVC Parties are not responsible for any injury, financial
			or physical loss, death, inconvenience, delay or damage to personal property in connection with the
			provision of any goods or services whether resulting from but not limited to acts of God or force majeure,
			illness, disease, acts of war, civil unrest, insurrection or revolt, animals, strikes or other labor
			activities, criminal or terrorist activities of any kind, overbooking or downgrading of services, food poisoning,
			mechanical or other failure of aircraft or other means of transportation or for failure of any transportation
			mechanism to arrive or depart on time.
		</p>
		<p>
			Excursions are a form of adventure travel. You acknowledge that there are many inherent risks in adventure
			travel and Excursions, which can lead to illness, injury, or even death and assume these risks. Such risks
			are increased by the fact that certain Excursions take place in remote locations, far from medical facilities.
			Additionally, if you decide to participate in an Excursion involving physical activities that includes, but
			is not limited to, animals, riding on animals, scuba diving, snorkeling, boating, hot air ballooning, helicopter
			flights, zip lining, high altitude treks, climbing, quad biking, parasailing, parachuting, kayaking, whitewater
			rafting, jet boat rides, snowmobiling, primate tracking and any other activity which OVC considers to carry
			inherent risks of serious illness, injury or death, then you fully understand and acknowledge that such
			activities carry with them various inherent risks, including serious illness, injury or death. By signing
			up for any such Excursion, you are expressly acknowledging this risk and are waiving any claims against any
			OVC Parties. You acknowledge and expressly agree that your participation in any Excursion evidences that you
			agree to take complete responsibility for your own health and safety and you agree to assume all risks of injury,
			illness or death, whether foreseen or unforeseen, that may befall you as a result of participating in any such
			activities and agree to release the OVC Parties from any liability whatsoever related thereto. Although each
			participant assumes all risks associated with participating in any Excursion, you acknowledge and agree, on behalf
			of yourself and those you are traveling with, that you will abide by and comply with any and all verbal and
			written directions, rules, policies, and/or procedures (regarding an Excursion are provided by OVC. You acknowledge
			and agree that any failure to comply with any such rules or directions shall be deemed to be conduct that
			negatively impacts the enjoyment of the other participants, disrupts the Excursion, and/or creates a hazard to
			yourself or the other members of the Excursion. As such, OVC reserves the right to remove from the Excursion,
			Guided Tour, and/or trip, at your own expense, you or any participant in your travel group that fails to
			comply with these Terms.
		</p>
		<p>
			Further, as consideration for being permitted to participate in an Excursion, you release the OVC Parties,
			from, and agree not to sue or make claim against the OVC Parties for, any claims, losses, damages, actions,
			whether known or unknown, arising out of or relating to property damage, cancellation or termination of any
			Excursion for any reason, illness, negligent rescue operations or procedures, personal injury, or death
			arising out of your participation in any Excursion and any activity related thereto, including transportation
			to and from the site of an Excursion, regardless of whether such property damage, illness, personal injury,
			or death results from the negligence of the OVC Parties and/or from any defect in equipment. You further
			agree to indemnify and hold the OVC Parties harmless with respect to any claim made against the OVC Parties
			by anyone else which (a) related to your participation in any Excursion o, or (b) which would be subject to
			the above release and covenant not to sue if you had made the claim directly yourself. Upon receipt of notice
			of the assertion of a claim, the OVC Parties reserve the right to approve, or withdraw approval of, counsel,
			in its sole discretion.
		</p>
		<p>
			Unless noted on an itinerary or expressly provided by OVC, there is no minimum age that is required to
			participate in an Excursion. If a child under the age of eighteen (18) ("Child") is participating in an
			Excursion, the Child must be accompanied by an adult and the adult is fully responsible for the Child during
			such Excursion and acknowledges all of these terms on behalf of such Child by signing the Child up for the
			Excursion. A parent and/or guardian that is participating in an Excursion with a Child is recommended to carry
			documentation that proves their relationship to the Child (e.g., birth certificate) and evidence that they
			have permission to travel with the child (e.g., consent letter). Individuals that are participating in an
			Excursion with a Child that is not related to the individual are recommended to carry documentation which
			provides evidence that the individual has permission to travel with the Child. Participants are advised to
			check with the appropriate government authority.
		</p>
		<p>
			The OVC Parties are not responsible for any Force Majeure Events. "Force Majeure Events" means any unusual
			and unforeseeable circumstances beyond the control of any OVC Party including, but not limited to: (a) acts
			of God; (b) flood, fire, earthquake, drought, or other potential disasters or catastrophes or explosion or
			adverse weather conditions; (c) war, invasion, hostilities (whether war is declared or not), terrorist threats
			or acts, riot or other civil unrest; (d) government order, law, or actions; (e) embargoes or blockades in
			effect on or after the date of these Terms of Use; (f) national or regional emergency; (g) strikes, labor
			stoppages or slowdowns, or other industrial disturbances; (h) telecommunication breakdowns, power outages
			or shortages, lack of warehouse or storage space, inadequate transportation services, or inability or delay
			in obtaining supplies of adequate or suitable materials; (i) technical problems with transport, machinery or
			equipment; (j) pandemics, epidemics or outbreaks of illness; (k) ice conditions in oceans and level of water
			in rivers; and (l) other events beyond the control of any OVC Party. In the event of a cancellation or material
			alteration to an Excursion as a result of a Force Majeure Event, OVC may in its sole and absolute discretion:
			(i) offer participant(s) alternative Excursions and/or activities as may be appropriate under the circumstances
			("Replacement Activity"); or (ii) if a Replacement Activity is not offered by OVC, OVC will provide a credit
			to the participant(s) equal to the value of the Excursions less any sums OVC is not able to recover from any
			independent contractor that was to provide goods or services. In the event a Force Majeure Event cancels or
			materially alters an Excursion, OVC does not assume responsibility for any other related costs stemming from
			the cancellation or material alteration including, but not limited to, airfare, insurance, visas, and other
			travel arrangements. OVC shall not accept any liability for any claims and will not provide refunds, except
			as may be specifically allowed herein if a Force Majeure Event cancels or materially alters an Excursion.
		</p>
	</>
);

export const sections = [
	{
		title: 'OWNERSHIP OF OUR WEBSITE AND PROTECTION OF OUR INTELLECTUAL PROPERTY RIGHTS',
		description: INTELLECTUAL_PROPERTY,
	},
	{
		title: 'OUR PRIVACY POLICY',
		description: PRIVACY_POLICY,
	},
	{
		title: 'YOUR CALIFORNIA PRIVACY RIGHTS',
		description: CALIFORNIA_PRIVACY,
	},
	{
		title: 'LIMITATIONS OF RESPONSIBILITY',
		description: LIABILITY,
	},
	{
		title: 'PUBLICITY RELEASE',
		description: PUBLICITY_RELEASE,
	},
	{
		title: 'BOOKING DETAILS',
		description: BOOKING_DETAILS,
	},
	{
		title: 'ONLINE VACATION CENTER TRAVEL DECLARATION',
		description: TRAVEL_DECLARATION,
	},
	{
		title: 'PRIOR TO TRAVEL',
		description: PRIOR_TO_TRAVEL,
	},
	{
		title: 'TICKETS AND DOCUMENTS',
		description: TICKETS_DOCUMENTS,
	},
	{
		title: 'PAYMENT, CANCELLATION AND REFUND POLICIES',
		description: PAYMENT,
	},
	{
		title: 'NO AUTOMATED QUERYING',
		description: AUTOMATED_QUERYING,
	},
	{
		title: 'YOU AGREE NOT TO USE OUR SITE TO',
		description: USE_OF_SITE,
	},
	{
		title: 'LINKS TO OTHER WEBSITES',
		description: EXTERNAL_LINKS,
	},
	{
		title: 'YOUR CONTACT WITH ADVERTISERS OR THIRD-PARTY VENDORS',
		description: YOUR_CONTACT,
	},
	{
		title: 'COMPLIANCE WITH LAWS',
		description: COMPLIANCE_WITH_LAWS,
	},
	{
		title: 'MODIFICATION TO OUR SERVICES',
		description: MODIFICATION,
	},
	{
		title: 'INFORMATION DISCLAIMER AND DISCLAIMER OF WARRANTIES',
		description: DISCLAIMER_OF_WARRANTIES,
	},
	{
		title: 'LIMITATION OF LIABILITY',
		description: LIMITATION_OF_LIABILITY,
	},
	{
		title: 'INDEMNIFICATION OF OVC',
		description: INDEMNIFICATION,
	},
	{
		title: 'LIMITATIONS OF ACTIONS BROUGHT AGAINST US',
		description: LIMITATIONS_OF_ACTIONS,
	},
	{
		title: 'ARBITRATION OF DISPUTES',
		description: DISPUTES,
	},
	{
		title: 'WAIVER OF CLASS ACTION',
		description: CLASS_ACTION,
	},
	{
		title: 'APPLICABLE LAWS',
		description: APPLICABLE_LAWS,
	},
	{
		title: 'VENUE AND JURISDICTION',
		description: JURISDICTION,
	},
	{
		title: 'FINAL PAYMENT AND ACCEPTANCE OF TERMS',
		description: FINAL_PAYMENT,
	},
	{
		title: 'Attorneys\' Fees and Costs',
		description: ATTORNEYS,
	},
	{
		title: 'Recoverable Costs',
		description: RECOVERABLE_COSTS,
	},
	{
		title: 'Excursions',
		description: EXCURSIONS,
	},
];
