import * as React from 'react';

import { useData } from '@app/hooks/data/useData';
import { useListFetchStore } from '@app/hooks/data/useFetchStore/useListFetchStore';
import { FetchMoreHandler, useLoadMore } from '@app/hooks/data/useLoadMore';
import { Rows } from '@app/objects/Rows';
import { Nullable } from '@app/objects/Utility';

interface ListDataEnvelope<T> {
	fetch: FetchMoreHandler;
	list: Array<T>;
	loading: boolean;
}

function initSearchParams<TValue>(location: string, preselect?: Nullable<TValue | Array<TValue>>, count: number = 100): URL {
	const item = new URL(location, window.location.origin);
	item.searchParams.set('count', count.toFixed(0));
	item.searchParams.set('offset', (0).toFixed(0));

	if (preselect) {
		if (Array.isArray(preselect)) {
			if (preselect.length) {
				item.searchParams.set('preselect', preselect.join(','));
			}
		} else {
			item.searchParams.set('preselect', preselect.toString());
		}
	}

	return item;
}

export function useListData<T, TValue>(location: string, value?: Nullable<TValue>, count: number = 100): ListDataEnvelope<T> {
	const ref = React.useRef<URL>(initSearchParams<TValue>(location, value, count));
	const data = useData<Rows<T>>(ref.current.toString(), useListFetchStore<T>);
	const fetch = useLoadMore(data, ref.current);

	return {
		fetch,
		list: data.item?.list ?? [],
		loading: data.loading,
	};
}
