import * as React from 'react';
import { useDispatch } from 'react-redux';

import { FeaturedDeals } from '@app/pages/MainPage/FeaturedDeals/FeaturedDeals';
import { DealSections } from '@app/pages/MainPage/DealSections/DealSections';
import { WhyUs } from '@app/pages/MainPage/WhyUs/WhyUs';
import { CruiseOptions } from '@app/pages/MainPage/CruiseOptions/CruiseOptions';
import { CruiseLine } from '@app/pages/MainPage/CruiseLine/CruiseLine';
import { Subscribe } from '@app/pages/MainPage/Subscribe/Subscribe';
import { Feedbacks } from '@app/pages/MainPage/Feedback/Feedback';

import { getActionCreators } from '@app/store/MainPage/ListActions';
import { useSelector } from '@app/hooks/useSelector';
import { DispatchType, RootStore } from '@app/store';

import '@app/scss/pages/main/main.scss';

export const MainPage: React.FC = () => {
	const dispatch = useDispatch<DispatchType<'homePage'>>();
	const homeContent = useSelector((state: RootStore) => state.homePage.item);

	React.useEffect(() => {
		const factory = getActionCreators('api/pages/v1/home', 'homePage');
		dispatch(factory.request());
	}, []);

	return (
		<>
			<FeaturedDeals featuredDeals={homeContent?.featuredDeals} />
			{homeContent && homeContent.deals.length ? <DealSections dealList={homeContent.deals} /> : null}
			<WhyUs />
			<CruiseLine />
			<Feedbacks />
			<CruiseOptions />
			<Subscribe />
		</>
	);
};
