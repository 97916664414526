import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import * as Sentry from '@sentry/react';

import { App } from '@app/App';
import { store } from '@app/store';

Sentry.init({
	dsn: process.env.app.dsn,
	integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],

	// Set tracesSampleRate to 1.0 to capture 100%
	// of transactions for performance monitoring.
	tracesSampleRate: 1.0,

	// Capture Replay for 10% of all sessions,
	// plus for 100% of sessions with an error
	replaysSessionSampleRate: 0.1,
	replaysOnErrorSampleRate: 1.0,
});

// This is application that we want to render
const application = (
	<React.StrictMode>
		<Provider store={store}>
			<App />
		</Provider>
	</React.StrictMode>
);

// This is the root component that we want to inject our application into
const root = ReactDOM.createRoot(
	document.getElementById('root') as HTMLElement,
);

root.render(application);
