import * as React from 'react';

import { LiveChatWidget } from '@livechat/widget-react';

export const Chat: React.FC = () => (
	<LiveChatWidget
		license={process.env.app.chatId}
		visibility="minimized"
	/>
);
