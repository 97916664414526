import * as React from 'react';
import Tooltip from 'rc-tooltip';

import { Inclusion } from '@app/objects/List';
import { Modal } from '@app/components/Modal/Modal';

import { Icon } from '@image/icon';

interface InclusionProps {
	item: Inclusion;
}

export const InclusionItem = ({ item }: InclusionProps) => {
	const [isOpen, setIsOpen] = React.useState<boolean>(false);
	const title = item.link ? <a onClick={() => setIsOpen(true)}>{item.title}</a> : item.title;

	return (
		<>
			<span>
				{title}
				{item.details && (
					<Tooltip
						placement="top"
						trigger="hover"
						overlay={item.details}
						arrowContent={<div className="rc-tooltip-arrow-inner" />}
					>
						<Icon.Question style={{ margin: '-3px 0 0 3px' }} />
					</Tooltip>
				)}
			</span>

			{
				item.link ? (
					<Modal
						open={isOpen}
						className="search-list-card__inclusion-modal"
						style={{
							minWidth: '350px',
							width: '75%',
							maxWidth: '850px',
							padding: 12,
							boxShadow: '0 10px 25px rgba(0, 0, 0, 0.5)',
						}}
						content={(
							<iframe
								title={item.title?.toString()}
								id={item.id}
								name={item.title?.toString()}
								height="100%"
								style={{
									width: '100%',
									border: 'none',
								}}
								src={item.link}
							/>
						)}
						onClose={setIsOpen}
					/>
				) : null
			}
		</>
	);
};
