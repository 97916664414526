import { LocalSelectOptions } from '@app/objects/LocalSelectOptions';
import { SearchType } from '@app/pages/MainPage/SearchCard/services';

export const simpleChoiceOption: Array<LocalSelectOptions> = [
	{
		text: 'Yes',
		value: Number(true),
	},
	{
		text: 'No',
		value: Number(false),
	},
];

export const searchCardOptions: Array<LocalSelectOptions> = [
	{
		text: 'Cruises - Exclusive Offers',
		value: SearchType.CruisesVIP,
	},
	{
		text: 'Cruises - All',
		value: SearchType.CruisesAll,
	},
	{
		text: 'Cruise Packages',
		value: SearchType.CruisesPackages,
	},
	{
		text: 'Hotels',
		value: SearchType.Hotels,
	},
	{
		text: 'Tours',
		value: SearchType.Tours,
	},
];
