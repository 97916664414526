import { ColumnProps } from 'rc-table/lib/sugar/Column';

// eslint-disable-next-line import/no-default-export
export default class TableBuilder<TEntity> {
	private template: Array<ColumnProps<TEntity>> = Array<ColumnProps<TEntity>>();

	public static shape<TEntity>() {
		return new TableBuilder<TEntity>();
	}

	public build(): Array<ColumnProps<TEntity>> {
		const result = this.template;
		this.template = Array<ColumnProps<TEntity>>();

		return result;
	}

	public addColumn(column: ColumnProps<TEntity>, condition: boolean = true): TableBuilder<TEntity> {
		if (condition) {
			this.template.push(column);
		}

		return this;
	}
}
