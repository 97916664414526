import * as React from 'react';

import * as Yup from 'yup';
import {
	Field, FieldProps, Form, Formik, FormikValues,
} from 'formik';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

import { Radio } from '@app/components/Checkbox/Radio';
import { TextField } from '@app/components/TextField/TextField';
import { Button, ButtonVariant, ActionMode } from '@app/components/Button/Button';
import { TextAreaField } from '@app/components/TextField/TextAreaField';
import {
	Frequency, RadioItem, Reasons, TValues,
	initialValuesAddress, initialValuesUnsubscribe, UnsubscribeFormValues,
	validationSchemaAddress, validationSchemaFrequency, validationSchemaUnsubscribe, initialValuesFrequency,
} from '@app/objects/SubscribeSettings';
import { RoutePaths } from '@app/utilities/RoutePathVars';
import { wrap } from '@app/pages/MainPage/Subscribe/Subscribe';

type OnChange = (value: boolean) => void;
const message = {
	title: 'Email Preference Center',
	subtitle: `Choose from the 3 options below to change the amount of emails you receive, 
update your email address or unsubscribe from the Online Vacation Center newsletter.`,
};
const successMessage = {
	title: 'Your e-mail preferences have been updated.',
	subtitle: `Thank you for updating your Hot Vacation Deals newsletter preferences. 
	You may update your e-mail preferences again at any time.`,
};
const frequencyList: Array<RadioItem> = [
	{ id: Frequency.Daily, label: 'Daily' },
	{ id: Frequency.TreeTWeek, label: 'Three Times a Week' },
	{ id: Frequency.OnceWeek, label: 'Once a Week' },
	{ id: Frequency.Pause, label: 'NEW! Pause for 90 Days' },
];
const reasonsList: Array<RadioItem> = [
	{ id: Reasons.Frequently, label: 'Online Vacation Center emails are sent too frequently.' },
	{ id: Reasons.ManyEmails, label: 'I receive too many emails in general.' },
	{ id: Reasons.NotRelevant, label: 'The emails I receive are not relevant to me.' },
	{ id: Reasons.Other, label: 'Other' },
];

interface HeadContentProps {
	title: string;
	subtitle: string;
}

const HeadContent = ({ title, subtitle }: HeadContentProps) => (
	<div className="col-12 form content-halign_center form__head">
		<h1 className="font-family_decorative text-size_soft-large content-valign_center form__title">{title}</h1>
		<p className="col-12 col-md-7 form__subtitle">{subtitle}</p>
	</div>
);

interface PreferenceCardProps {
	title: string;
	emailLabel?: string;
	radioGroup?: {
		fieldName: string;
		label: string;
		list: Array<RadioItem>;
	};
	withNote?: boolean;
	initialValues: FormikValues;
	validationSchema: Yup.ObjectSchema<object>;
	onSubmit: (values: FormikValues, token: string) => void;
	onChange: OnChange;
}

const cards = [
	{
		title: 'Change Frequency',
		emailLabel: 'Enter your email address to change your preferences.',
		radioGroup: {
			fieldName: 'frequency',
			label: 'Please update my email frequency to:',
			list: frequencyList,
		},
		initialValues: initialValuesFrequency,
		validationSchema: validationSchemaAddress,
		onSubmit: () => console.log(),
	},
	{
		title: 'Update Email Address',
		emailLabel: 'Please change my email address to:',
		initialValues: initialValuesAddress,
		validationSchema: validationSchemaFrequency,
		onSubmit: () => console.log(),
	},
	{
		title: 'Unsubscribe',
		radioGroup: {
			fieldName: 'reason',
			label: 'Please unsubscribe me from Online Vacation Center emails because:',
			list: reasonsList,
		},
		withNote: true,
		initialValues: initialValuesUnsubscribe,
		validationSchema: validationSchemaUnsubscribe,
		onSubmit: () => console.log(),
	},
];

const PreferenceCard = <
	TFormValues extends TValues,
>
	(props: PreferenceCardProps) => {
	const { executeRecaptcha } = useGoogleReCaptcha();

	return (
		<div className="col-12 col-sm-4">
			<div className="form__email-form_container">
				<h3 className="content-valign_center form__title">{props.title}</h3>

				<Formik
					initialValues={props.initialValues}
					validationSchema={props.validationSchema}
					enableReinitialize
					onSubmit={(values, { resetForm }) => {
						wrap(executeRecaptcha?.())
							.then((token: string) => props.onSubmit(values, token))
							.then(() => {
								props.onChange(true);
								resetForm();
							})
							.catch(() => console.log('error'));
					}}
				>
					{(formikBag) => (
						<Form>
							<div>
								{props.emailLabel && (
									<Field name="email">
										{({ field, form }: FieldProps<string, TFormValues>) => (
											<div className="col-12 form-group">
												<p className="form-group">{props.emailLabel}</p>
												<TextField
													value={field.value}
													onChange={(value: string) => form.setFieldValue(field.name, value, false)}
													onBlur={field.onBlur}

													title="Email"
													inputProps={{ type: 'email' }}

													text={form.touched.email ? form.errors.email as string : undefined}
													error={Boolean(form.errors.email && form.touched.email)}
												/>
											</div>
										)}
									</Field>
								)}
								{props.radioGroup && (
									<Field name={props.radioGroup.fieldName}>
										{({ field, form }: FieldProps<number, TFormValues>) => (
											<div className="col-12">
												<p className="form-group">{props.radioGroup?.label}</p>
												{props.radioGroup?.list.map((item: RadioItem) => (
													<Radio
														key={item.id}
														label={item.label}
														checked={item.id === field.value}
														onChange={() => form.setFieldValue(field.name, item.id)}
														containerClassName="form-group text-size_small"
													/>
												))}
											</div>
										)}
									</Field>
								)}
								{props.withNote && (
									<Field name="note">
										{({ field, form }: FieldProps<string, UnsubscribeFormValues>) => (
											<div className="col-12 form-group">
												<TextAreaField
													value={field.value}
													onChange={(value: string) => form.setFieldValue(field.name, value, false)}
													onBlur={field.onBlur}

													title="Comments"

													text={form.touched.note ? form.errors.note : undefined}
													error={Boolean(form.errors.note && form.touched.note)}
												/>
											</div>
										)}
									</Field>
								)}
							</div>

							<div className="content-valign_center">
								<div className="col-12">
									<Button
										style={{ width: '100%' }}
										className="uppercase form-group"
										variant={ButtonVariant.Filled}
										onClick={formikBag.handleSubmit}
									>
										Submit
									</Button>
								</div>
							</div>
						</Form>
					)}
				</Formik>
			</div>
		</div>
	);
};

const SuccessContent = () => (
	<>
		<HeadContent {...successMessage} />
		<div className="content-valign_center">
			<div className="col-12">
				<Button
					style={{ width: '100%' }}
					className="uppercase form-group"
					variant={ButtonVariant.Filled}
					action={ActionMode.InternalLink}
					link={RoutePaths.mainPage}
				>
					Shop our latest vacation specials
				</Button>
			</div>
		</div>
		<div className="content-valign_center">
			<div className="col-12">
				<Button
					style={{ width: '100%' }}
					className="uppercase form-group"
					variant={ButtonVariant.Filled}
					action={ActionMode.InternalLink}
					link={RoutePaths.friendInvite}
				>
					Tell a friend about our deals
				</Button>
			</div>
		</div>
	</>
);

interface ContentProps {
	onChange: (value: boolean) => void;
}

const Content = (props: ContentProps) => (
	<>
		<HeadContent {...message} />
		<div className="layout-container form__email-form">
			{cards.map((card) => (
				<PreferenceCard {...card} onChange={props.onChange} key={card.title} />
			))}
		</div>
	</>
);

export const SubscribeSettings = () => {
	const [success, setSuccess] = React.useState<boolean>(false);

	return (
		<div className="col-12 col-md-10 content-halign_center form font-family_primary font-weight_regular text-size__extra-small">
			{success ? <SuccessContent /> : <Content onChange={setSuccess} />}
		</div>
	);
};
