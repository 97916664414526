import { Nullable } from '@app/objects/Utility';

import { PackageType } from '@app/objects/CruisePackages';
import { Ship } from '@app/objects/Routes/Ship';
import { CruiseLine } from '@app/objects/Routes/CruiseLine';
import { Destination, DestinationRegion } from '@app/objects/Routes/Destination';
import { RouteVoyage } from '@app/objects/Routes/Voyage';
import { RoutePort } from '@app/objects/Routes/RoutePort';
import { RouteInclusion } from '@app/objects/Routes/RouteInclusion';

export enum CoverType {
	Unknown = 0,
	Image = 1,
	Map = 2,
}

export interface Route {
	id: string;

	bookLink: string;
	detailsLink: string;

	image: string;
	coverType: CoverType;

	preDuration: number;
	duration: number;
	postDuration: number;
	type: PackageType;

	ship: Nullable<Ship>;
	shipId: string;

	cruiseLine: Nullable<CruiseLine>;
	cruiseLineId: string;
	destination: Nullable<Destination>;
	destinationId: string;

	destinationRegion: Nullable<DestinationRegion>;
	destinationRegionId: string;

	departureCountryCity: string;
	arrivalCountryCity: string;

	voyages: Array<RouteVoyage>;
	ports: Array<RoutePort>;
	inclusions: Array<RouteInclusion>;
}
