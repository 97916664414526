import moment from 'moment';

const odyServerDateFormat: string = 'DD/MMM/YYYY';

// This is a based on code that is taken from onlinevacationcenter
export class OdyManager {
	private seed: number = 0;

	private getUTCTimeStamp(seed: number): number {
		const utc = moment().utc().valueOf();
		const factor = 6e4;

		return utc + seed * factor;
	}

	private generateRandomString(len: number): string {
		let result = '';
		const range = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

		for (let i = 0; i < len; i++) {
			result += range.charAt(Math.floor(Math.random() * range.length));
		}

		return result;
	}

	private setTextContentData(content: string) {
		let result = '';
		for (let i = 0; i < content.length; i++) {
			const code = parseInt(content[i], 10);
			let tmp = null;

			if (i % 2 === 0) {
				tmp = code + 66;
			} else {
				tmp = code + 67;
			}

			result += String.fromCharCode(tmp);
		}

		return result;
	}

	public get uniquetid(): string {
		const stamp = this.getUTCTimeStamp(this.seed);
		const str = this.generateRandomString(5);
		const content = this.setTextContentData(stamp.toString());

		return `${str}${content.charAt(1)}${content}${str.charAt(2)}${content.charAt(4)}`;
	}

	public static toDateStamp(date: string): number {
		return moment(date, odyServerDateFormat).valueOf();
	}

	public static toDateString(stamp: number): string {
		return moment(stamp).format('L');
	}
}
