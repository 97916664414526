import { Action, Dispatch } from 'redux';
import { ThunkAction } from 'redux-thunk';

import { request } from '@app/services/api';
import { RootStore } from '@app/store';

export enum TypeKeys {
	REQUESTITEMS = 'REQUESTITEMS',
	RECEIVEITEMS = 'RECEIVEITEMS',
	RECEIVEERROR = 'RECEIVEERROR',
}

export interface IListAction<Type extends TypeKeys = TypeKeys, Payload = unknown> {
	type: Type;
	payload: Payload;
	store: keyof RootStore;
	items: Record<string, unknown>;
}

export type IListRequestAction = IListAction<TypeKeys.REQUESTITEMS, { path: string }>;
export type IListReceiveAction = IListAction<TypeKeys.RECEIVEITEMS, {
	item: Record<string, unknown>;
	}
>;
export type IListRequestErrorAction = IListAction<TypeKeys.RECEIVEERROR, { error: string }>;

export function isListAction(action: Action): action is IListAction {
	if (typeof action.type !== 'string') return false;

	const str = action.type as string;

	return (
		str === TypeKeys.REQUESTITEMS
		|| str === TypeKeys.RECEIVEITEMS
		|| str === TypeKeys.RECEIVEERROR
	);
}

type GetState<TKey extends keyof RootStore> = () => RootStore[TKey]

interface ListActionCreator<TKey extends keyof RootStore> {
	request: () => ThunkAction<void, RootStore[TKey], never, IListAction>
}

export function getActionCreators<TEntity, TKey extends keyof RootStore>(path: string, store: TKey): ListActionCreator<TKey> {
	const fail = (dispatch: Dispatch<Action>, error: string) => (
		dispatch({
			type: TypeKeys.RECEIVEERROR,
			store,
			payload: { error },
		})
	);

	return {
		request: () =>
			(dispatch: Dispatch<Action>, getStore: GetState<TKey>) => {
				const state = getStore().item;

				if (!path || (state && Object.keys(state).length)) return;

				dispatch({
					type: TypeKeys.REQUESTITEMS,
					store,
				});

				// Make sure that current requests cancels or pending ones - by providing tag
				request(path, {
					tag: store.toString(),
				})
					.then((item: TEntity) => {
						dispatch({
							type: TypeKeys.RECEIVEITEMS,
							store,
							payload: { item },
						});
					})
					.catch((error: string) => fail(dispatch, error));
			},
	};
}
