import { PackagesFilterKeys } from '@app/objects/Filters/Packages';

export type PackagesFilterValue = string | number;

export interface PackagesFilterOption<T extends PackagesFilterValue, TParent extends PackagesFilterValue = number> {
	id: T;
	name: string;
	count: number;
	parentId?: TParent;
}

export enum PackageType {
	ValueInclusivePackage = 0,
	EnrichmentJourney = 1,
	AirInclusive = 2,
	Tour = 3,
	CruiseOnly = 4,
	Unknown = 5,
}

export function getPackageTypeName(type: PackageType): string {
	switch (type) {
	case PackageType.ValueInclusivePackage:
		return 'Value Inclusive Package';

	case PackageType.EnrichmentJourney:
		return 'Enrichment Journey';

	case PackageType.AirInclusive:
		return 'Air Inclusive';

	case PackageType.Tour:
		return 'Tour';

	case PackageType.CruiseOnly:
		return 'Cruise Only';

	case PackageType.Unknown:
	default:
		return 'Unknown';
	}
}

export interface CruisePackages {
	[PackagesFilterKeys.TYPE]: Array<PackagesFilterOption<PackageType>>,
	[PackagesFilterKeys.DESTINATIONREGION]: Array<PackagesFilterOption<string>>,
	[PackagesFilterKeys.DESTINATION]: Array<PackagesFilterOption<string, string>>,
	[PackagesFilterKeys.DATE]: Array<PackagesFilterOption<number>>, // date within a month
	[PackagesFilterKeys.CRUISELINE]: Array<PackagesFilterOption<string>>,
	[PackagesFilterKeys.SHIP]: Array<PackagesFilterOption<string>>,
	[PackagesFilterKeys.DURATION]: Array<PackagesFilterOption<number>>,
}

export interface DeprecatedCruisePackages {
	[PackagesFilterKeys.CRUISELINE]: Array<PackagesFilterOption<number>>,
	[PackagesFilterKeys.DATE]: Array<number>, // date within a month
	[PackagesFilterKeys.DESTINATION]: Array<PackagesFilterOption<number>>,
}
