import * as React from 'react';

import { SubscribeNotification } from '@app/objects/Subscribe';
import { IBurgerContext } from '@app/objects/BurgerContext';

export function useBurger(): IBurgerContext {
	const [isShowBurger, setIsShowBurger] = React.useState<boolean>(false);
	const [isShowSubscribeForm, setIsShowSubscribeForm] = React.useState<boolean>(false);
	const [alert, setAlert] = React.useState<SubscribeNotification | undefined>(undefined);

	return React.useMemo(() => {
		return {
			isShowBurger,
			toggleShowBurger: setIsShowBurger,
			isShowSubscribeForm,
			toggleShowSubscribeForm: setIsShowSubscribeForm,
			alert,
			changeAlert: setAlert,
		};
	}, [
		isShowBurger,
		isShowSubscribeForm,
		alert,
	]);
}
