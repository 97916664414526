import * as React from 'react';

import { Port } from '@app/odesseus/types/Port';
import { Country } from '@app/odesseus/types/Country';
import { State } from '@app/odesseus/types/State';
import { CruiseLine } from '@app/odesseus/types/CruiseLine';
import { Ship } from '@app/odesseus/types/Ship';
import { Destination } from '@app/odesseus/types/Destination';
import {
	fetchCountries,
	fetchCruiselines,
	fetchDestinations,
	fetchFacets,
	fetchPorts,
	fetchShips,
	fetchStates,
} from '@app/odesseus/Requests';
import { Range } from '@app/objects/Filters/Range';
import { Facet, FacetPage } from '@app/odesseus/types/Facet';
import {
	OdesseusFilterKeys,
} from '@app/odesseus/types/Filters';
import { Nullable } from '@app/objects/Utility';
import { FacetChain } from '@app/odesseus/FacetChain';
import { OdesseusList } from '@app/odesseus/types/List';
import {
	OdesseusFilterRecord,
	OdesseusResultFilters,
	OdesseusSelectOption,
	OdesseusSelectOptionGroup, OdesseusOptions,
} from '@app/odesseus/types/Filters/Odesseus';

export interface Odesseus {
	ports: Array<Port>;
	destinations: Array<Destination>;
	countries: Array<Country>;
	states: Array<State>;
	cruiselines: Array<CruiseLine>;
	ships: Array<Ship>;
}

interface IWithName {
	name: string;
}

interface IWithActive {
	active: boolean;
}

function sorter(a: IWithName, b: IWithName): number {
	return a.name > b.name ? 1 : -1;
}

function onFetch<T extends IWithName & IWithActive>(list: Array<T>): Array<T> {
	return list
		.sort(sorter)
		.filter((a: IWithActive) => a.active);
}

const keys: Array<string> = Object.values(OdesseusFilterKeys);
function onFetchFacet(page: FacetPage): Array<Facet> {
	return page.facets.filter((q: Facet) => keys.includes(q.key));
}

function loadRawData(): Odesseus {
	const [destinations, setDestinations] = React.useState<Array<Destination>>(() => []);
	const [ports, setPorts] = React.useState<Array<Port>>(() => []);
	const [cruiselines, setCruiselines] = React.useState<Array<CruiseLine>>(() => []);
	const [ships, setShips] = React.useState<Array<Ship>>(() => []);
	const [countries, setCountries] = React.useState<Array<Country>>(() => []);
	const [states, setStates] = React.useState<Array<State>>(() => []);

	React.useEffect(() => {
		const tasks = [
			fetchDestinations(),
			fetchPorts(),
			fetchCruiselines(),
			fetchShips(),
			fetchCountries(),
			fetchStates(),
		];

		Promise.all(tasks)
			.then((values) => {
				const response = values as [
					Array<Destination>,
					Array<Port>,
					Array<CruiseLine>,
					Array<Ship>,
					Array<Country>,
					Array<State>,
				];

				setDestinations(onFetch(response[0]));
				setPorts(onFetch(response[1]));
				setCruiselines(onFetch(response[2]));
				setShips(onFetch(response[3]));
				setCountries(response[4].sort(sorter));
				setStates(response[5].sort(sorter));
			})
			.catch();
	}, []);

	return React.useMemo(() => ({
		destinations,
		ports,
		cruiselines,
		ships,
		countries,
		states,
	}), [
		destinations,
		ports,
		cruiselines,
		ships,
		countries,
		states,
	]);
}

/**
 * useOdesseus - hook that wraps all odesseus requests and provides data for filter selects
 * @param filters [Array<IFilters>] - a list of selected filters (values and ranges)
 * @param lastChange [Nullable<FilterKeys>] - last changed filter
 *                                            (its list should not be updated - otherwise multiple select wouldn't work)
 * @param page [Optional<number>] - current page
 */
export function useOdesseus(filters: Array<OdesseusFilterRecord>, lastChange: Nullable<OdesseusFilterKeys>, page?: number): OdesseusResultFilters {
	const data: Odesseus = loadRawData();
	const [loading, setLoading] = React.useState<boolean>(false);

	type SelectOptions = Array<OdesseusSelectOption>;
	type SelectOptionsGroup = Array<OdesseusSelectOptionGroup>;
	type SelectRangeOptions = Array<OdesseusSelectOption<Range<number>>>;

	const [destinations, setDestinations] = React.useState<SelectOptions>(() => []);
	const [ports, setPorts] = React.useState<SelectOptions>(() => []);
	const [cruiselines, setCruiselines] = React.useState<SelectOptions>(() => []);
	const [ships, setShips] = React.useState<SelectOptionsGroup>(() => []);
	const [dates, setDates] = React.useState<SelectRangeOptions>(() => []);
	const [durations, setDurations] = React.useState<SelectRangeOptions>(() => []);

	const [list, setList] = React.useState<Nullable<OdesseusList>>(null);

	React.useEffect(() => {
		setLoading(true);
		fetchFacets(filters, page)
			.then((res: FacetPage) => {
				setList({
					list: res.list,
					total: res.list.length ? res.total : 0,
					pageSize: res.pageSize,
					pageStart: res.pageStart,
				});
				const chain = FacetChain.spawn({
					lastChange,
					raw: data,
					setDestinations,
					setPorts,
					setCruiselines,
					setShips,
					setDates,
					setDurations,
				});
				onFetchFacet(res).forEach((facet: Facet) => chain.pass(facet));
			})
			.catch((error: Error) => console.warn('Failed to load facets: ', error))
			.finally(() => setLoading(false));
	}, [data, filters, page]);

	const options: OdesseusOptions = React.useMemo(() => ({
		destinations,
		ports,
		cruiselines,
		ships,
		dates,
		durations,

		list,
	}), [
		destinations,
		ports,
		cruiselines,
		ships,
		dates,
		durations,

		list,
	]);

	return {
		options,
		list,
		loading,
	};
}
