import * as React from 'react';

import clsx from 'clsx';

import { TextField, TextFieldProps } from '@app/components/TextField/TextField';

import '@app/scss/components/phonefield.scss';

interface PhoneFieldProps extends Omit<TextFieldProps, 'value' | 'onChange'> {
	value?: string;
	onChange?: (value: string) => void;
}

function toMask(value?: string): string | undefined {
	if (value === undefined) return undefined;
	if (!value) return '';

	const prefix = value.replaceAll(/\D/g, '');
	const suffix = new Array(Math.max(10 - prefix.length, 0)).fill('_').join('');
	const raw = `${prefix}${suffix}`;

	return `(${raw.slice(0, 3)}) ${raw.slice(3, 6)}-${raw.slice(6)}`;
}

function toValue(mask: string): string {
	return mask.replaceAll(/\D/g, '').slice(0, 10);
}

const inputProps = {
	type: 'tel',
};

export const PhoneField: React.FC<PhoneFieldProps> = (props: PhoneFieldProps) => {
	const mask = toMask(props.value);

	return (
		<TextField
			{...props}
			value={mask}
			onChange={(newMask: string) => {
				const value = toValue(newMask);

				if (mask && newMask.length < mask.length) {
					props.onChange?.(value.slice(0, -1));
				}

				if (props.value !== value) {
					props.onChange?.(value);
				}
			}}
			inputProps={inputProps}

			className={clsx(props.className, 'phone-field')}
		/>
	);
};
