import * as React from 'react';

import clsx from 'clsx';

import '@app/scss/components/modal.scss';

import { Icon } from '@image/icon';

export interface ModalProps {
	open: boolean;
	className?: string;
	style?: React.CSSProperties;
	onClose: (value: boolean) => void;
	content: React.ReactNode;
}

export const Modal: React.FC<ModalProps> = (props: ModalProps) => {
	React.useEffect(() => {
		document.body.classList.toggle('no-scroll-body', props.open);
	}, [props.open]);

	return (
		<div className={clsx('modal', props.open && 'modal-show')}>
			<div
				className="modal__overlay"
				onClick={() => props.onClose(false)}
			/>
			<div
				className={clsx('modal__container', props.open && 'modal-show', props.className)}
				style={props.style}
			>
				<button
					type="button"
					onClick={() => props.onClose(false)}
					className="modal__btn-close"
				>
					<Icon.Clear />
				</button>
				{props.content}
			</div>
		</div>
	);
};
