import * as React from 'react';
import { useDispatch } from 'react-redux';
import Countdown, { CountdownRenderProps } from 'react-countdown';

import moment from 'moment';
import clsx from 'clsx';

import { getActionCreators } from '@app/store/MainPage/ListActions';
import { useSelector } from '@app/hooks/useSelector';
import { formatPhone } from '@app/services/phone';

import { useQueryParams } from '@app/hooks/useQueryParams';
import { Nullable } from '@app/objects/Utility';
import { Anchor } from '@app/components/Anchor/Anchor';
import { Anchors } from '@app/objects/Anchors';
import { useDimensions } from '@app/hooks/useDimensions';
import { BurgerContext } from '@app/services/contexts';
import { RootStore, DispatchType } from '@app/store';
import { RoutePaths } from '@app/utilities/RoutePathVars';

import { Icon } from '@image/icon';

import '@app/scss/components/header.scss';

interface OwnProps {
	scrollToSubscribe?: boolean;
}

const rfKey = 'rf';
const cidKey = 'cid';

const timer = ({
	days, hours, minutes, seconds, completed,
}: CountdownRenderProps) => {
	if (completed) {
		return null;
	}

	return ` ${days} Day(s) : ${hours} Hour(s) : ${minutes} Minute(s) : ${seconds} Second(s) `;
};

export const InfoSection: React.FC<OwnProps> = (props: OwnProps) => {
	const phone = useSelector((state: RootStore) => state.header.item?.phone);
	const ref = React.useRef<HTMLElement>(null);
	const dimensions = useDimensions(ref);
	const {
		isShowBurger, toggleShowBurger, toggleShowSubscribeForm,
	} = React.useContext(BurgerContext);
	const dispatch = useDispatch<DispatchType<'header'>>();
	const handleQuery = React.useCallback((values: Record<string, Nullable<string>>) => {
		const rf = values[rfKey];
		const cid = values[cidKey];

		const params: Array<string> = [];

		if (rf) params.push(`rf=${rf}`);
		if (cid) params.push(`cid=${cid}`);

		const query = params.join('&');

		const factory = getActionCreators(`api/pages/v1/header?${query}`, 'header');
		dispatch(factory.request());
	}, [dispatch]);
	useQueryParams([rfKey, cidKey], handleQuery);

	const sale = useSelector((state: RootStore) => state.header.item?.sale);

	// current date and time in unix timestamp format
	const dateOff = moment().valueOf();
	const isPromotionOver = sale && sale.expirationDate && (moment(sale.expirationDate).valueOf() <= dateOff);

	return (
		<div className={clsx(
			'site-header__top-block menu font-weight_semi-bold',
			isShowBurger && 'disabled',
			(isPromotionOver || !sale) && 'site-header__top-block_hidden',
		)}
		>
			{!isPromotionOver && sale && (
				<div className="site-header__left-wrap font-weight_extra-bold">
					<span>
						{sale.text}
					</span>
					{(sale.withCountdown && sale.expirationDate) && (
						<Countdown
							date={sale.expirationDate}
							renderer={timer}
						/>
					)}
					{sale?.link	 && (
						<a href={sale.link}>
							See All Offers ›
						</a>
					)}
				</div>
			)}

			<div className="site-header__right-wrap">
				{phone && (
					<div className="menu-item__phone font-weight_extra-bold">
						<Icon.Phone title={phone} />
						<span className="icon-margin">
							{formatPhone(phone)}
						</span>
					</div>
				)}
				<a
					href={RoutePaths.requestQuotePage}
					className="menu-item__request"
				>
					<span className="font-weight_semi-bold">Request a Quote</span>
				</a>
				<div className="menu-item__authorization-block">
					<span className="menu-item__authorization-block__item">
						<a
							href="/cp/login.cfm"
							className="menu-item__authorization-block__item-content font-weight_semi-bold"
						>
							Manage Trips
						</a>
					</span>
					<span
						className="menu-item__authorization-block__item"
						onClick={() => {
							if (!props.scrollToSubscribe) {
								toggleShowBurger?.(true);
								toggleShowSubscribeForm?.(true);
								document.body.classList.add('no-scroll-body');
							}
						}}
					>
						<Anchor.Link
							anchor={Anchors.Subscribe}
							className="menu-item__authorization-block__item-content font-weight_semi-bold"
							offset={dimensions.height}
							disabled={!props.scrollToSubscribe}
						>
							Subscribe
						</Anchor.Link>
					</span>
				</div>
			</div>
		</div>
	);
};
