import * as React from 'react';

import '@app/scss/components/notFound.scss';

export const NotFound = () => (
	<div className="not-found col-12 font-family_primary font-weight_regular text-size_large content-valign_center">
		<div className="not-found__title font-family_primary condensed font-weight_semi-bold text-size_extra-large">
			<b>404</b>
		</div>
		<p>Page you are looking for is missing</p>
	</div>
);
