import * as React from 'react';
import { useLocation } from 'react-router-dom';

import { SearchPackagesURLManager } from '@app/services/searchPackages/SearchPackagesURLManager';

/**
 * Use shared instance of SearchPackagesURLManager()
 * It is convenient to use it from multiple components so that they share same state
 * @returns {SearchPackagesURLManager}
 */
export function useSearchPackagesURLManager(): SearchPackagesURLManager {
	const manager = React.useRef<SearchPackagesURLManager>(SearchPackagesURLManager.shared);
	const location = useLocation();

	React.useEffect(() => {
		manager.current.parse(location.search);
	}, [location.search]);

	return manager.current;
}
