import { SubscribeNotification } from '@app/objects/Subscribe';

export interface IBurgerContext {
  isShowBurger: boolean;
  toggleShowBurger?: (value: boolean) => void;
	isShowSubscribeForm: boolean;
	toggleShowSubscribeForm?: (value: boolean) => void,
	alert: SubscribeNotification | undefined;
	changeAlert?: (value: SubscribeNotification | undefined) => void,
}

export const defaultBurgerState = {
	isShowBurger: false,
	isShowSubscribeForm: false,
	alert: undefined,
};
