import * as React from 'react';

import clsx from 'clsx';

import '@app/scss/components/loader.scss';

export interface LoaderProps {
	small?: boolean;
	loading?: boolean;
}

export const Loader: React.FC<LoaderProps> = (props: LoaderProps) => {
	if (props.loading === false) return null;

	return (
		<div
			className={clsx('lds-ring', props.small && 'lds-ring_small')}
		>
			<div />
			<div />
			<div />
			<div />
		</div>
	);
};
