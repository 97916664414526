import * as React from 'react';
import '@app/scss/components/dataNotFound.scss';
import clsx from 'clsx';

interface DataNotFoundProps {
	message: React.ReactNode;
	className?: string
}

export const DataNotFound: React.FC<DataNotFoundProps> = ({ message, className }: DataNotFoundProps) => (
	<div className={clsx('not-found', className)}>
		<p className="col-12 content-valign_center content-halign_center">
			{message}
		</p>
	</div>
);
