import { Optional } from '@app/objects/Utility';

export function formatPhone(phone: Optional<string>) {
	if (!phone) return '';

	const tel = phone.replace(/\D/g, '');

	if (tel.length < 10) return phone;

	return `${tel.slice(0, 3)}-${tel.slice(3, 6)}-${tel.slice(6)}`;
}
