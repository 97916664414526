import { PackagesFormValues } from '@app/objects/Filters/Packages';
import { isArray } from '@app/objects/Utility';
import { ISearchPackagesURLTraverser } from '@app/services/searchPackages/SearchPackagesURLTraverser';

export class FilterURLTraverser implements ISearchPackagesURLTraverser {
	filter<TKey extends keyof PackagesFormValues>(key: TKey, value: PackagesFormValues[TKey]): boolean {
		return isArray(value) && Boolean(value.length);
	}

	map<TKey extends keyof PackagesFormValues>(key: TKey, value: PackagesFormValues[TKey]): string {
		if (!isArray(value)) {
			return `${key}=${value}`;
		}

		return `${key}=${value.join(',')}`;
	}
}
