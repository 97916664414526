/* eslint-disable max-len */
import * as React from 'react';
import { v4 } from 'uuid';

import { IListElement } from '@app/components/DropList/DropList';

const STEPHEN_RUDNER = 'Stephen A. Rudner is a Director of Online Vacation Center Holdings Corp. and also serves as the Company\'s President. Mr. Rudner has previously served as the Chief Financial Officer, Director of Information Technology, and Director of Sales of Online Vacation Center, Inc. Mr. Rudner joined the Company in November of 2011 as the Manager of Accounting. Prior to joining Online Vacation Center, Mr. Rudner worked as a senior auditor for Deloitte & Touche LLP. Mr. Rudner holds a Master of Science degree in Accounting from the University of Florida and is a Certified Public Accountant in the state of Florida.';
const DANIEL_RUDNER = 'Daniel J. Rudner is President for the OVC franchise of Expedia Cruises™. Daniel is a veteran in the travel industry and has worked for various cruise lines and tour operators. Daniel holds a bachelor’s degree in Small Business Management and Entrepreneurship from Florida Atlantic University.';
const RICHARD_MCKINNON = (
	<>
		<p>
			Richard A. McKinnon is a director of Online Vacation Center Holdings Corp.
		</p>
		<p>
			During more than thirty years in the travel industry, Mr. McKinnon is most lauded for founding Vacation.com, the nation's largest vacation selling network whose member agencies sell more than $20 billion in leisure travel annually. Following the sale of Vacation.com to Amadeus, the leading global distribution system and technology provider for the world's travel and tourism industries, Mr. McKinnon was appointed CEO of Amadeus North America. Previously, Mr. McKinnon held senior positions in several major travel businesses including American Airlines, Pan American World Airways, Delta Air Lines, Wyndham Resort Hotels and US Air. He also served as president of American Hawaiian Cruises and the Delta Queen Steamboat Company.
		</p>
		<p>
			Since 2008, Mr. McKinnon has served as President of Management Recruiters International, a worldwide executive search firm. He also serves as a director for Tauck, Inc. and GSC Acquisition Company.
		</p>
		<p>
			Mr. McKinnon is a graduate of the United States Military Academy with a Bachelor of Science degree and has a Law degree from the Emory University School of Law. He currently resides in Dallas, Texas.
		</p>
	</>
);
const EDWARD_RUDNER = (
	<>
		<p>
			Edward B. Rudner is a Director of Online Vacation Center Holdings Corp. and also serves as its Chairman and CEO.
		</p>
		<p>
			Mr. Rudner has more than 30 years of experience in the travel industry. Previously, he served as the CFO and COO of Alamo-Rent-A-Car. Under his guidance, Alamo-Rent-A-Car increased its fleet size from 400 to 50,000 automobiles, and expanded from a Florida-based company into a national car rental agency. Following his tenure at Alamo, Mr. Rudner went on to become president and CEO of Certified Vacations, where he cultivated the business from 10,000 to 250,000 vacation packages per year. More recently, he served as the CEO of Renaissance Cruises where he expanded ship assets from $60 million to over $1 billion and increased revenues from $20 million to $500 million over a nine-year period until June of 2000.
		</p>
		<p>
			In October of 2000, Mr. Rudner founded Online Vacation Center, Inc., which today is one of the largest cruise sellers in the United States.
		</p>
		<p>
			Mr. Rudner graduated cum laude with a Bachelor of Arts degree in History from the University of Massachusetts and currently resides in Fort Lauderdale, Florida.
		</p>
	</>
);
const ALLISON_SHIPLEY = `
Allison Shipley is a Director of Online Vacation Center Holdings Corp. She is a Retired Principal with
PricewaterhouseCoopers, where she led the Personal Financial Services practice for the State of Florida.
Ms. Shipley specialized in comprehensive planning for high net worth individuals and families, business
owners, and family offices. Her areas of expertise included income tax planning and compliance services,
investment planning, and wealth transfer solutions for both U.S. and foreign individuals. She is an Enrolled
Agent, authorized to practice before the Internal Revenue Service. Ms. Shipley earned an M.B.A. from the
University of Virginia's Darden Graduate School of Business and a B.A. in Economics from Kenyon College.
She also holds the Certificate of Language and Civilization of French Studies from La Sorbonne University
(Paris, France). Ms. Shipley serves on the boards of Goodwill Industries of South Florida and The Miami
Foundation.
`;
const BRENDA_JOSOWITZ = `
Brenda Josowitz is a Director of Online Vacation Center Holdings Corp. She is Co-Owner and Vice
President of Lift Mate, LLC, a company providing security technology for the marine industry. Previously, Ms. Josowitz
worked in executive sales and finance positions in the travel, oil and gas, and power industries. She earned
a bachelor’s degree in accounting from the University of Tampa.
`;
export const directorsList: Array<IListElement> = [
	{
		title: 'Stephen A. Rudner, Director and President',
		text: STEPHEN_RUDNER,
		id: v4(),
	},
	{
		title: 'Daniel J. Rudner, Director',
		text: DANIEL_RUDNER,
		id: v4(),
	},
	{
		title: 'Richard A. McKinnon, Director',
		text: RICHARD_MCKINNON,
		id: v4(),
	},
	{
		title: 'Edward B. Rudner, Director, Chairman, and CEO',
		text: EDWARD_RUDNER,
		id: v4(),
	},
	{
		title: 'Allison Shipley, Director',
		text: ALLISON_SHIPLEY,
		id: v4(),
	},
	{
		title: 'Brenda Josowitz, Director',
		text: BRENDA_JOSOWITZ,
		id: v4(),
	},
];
