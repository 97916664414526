import * as React from 'react';
import { v4 } from 'uuid';

import { LinkType, link } from '@app/pages/StaticPages/utils';

export const INTRO = (
	<>
		<p>
			The best reason to purchase travel insurance is the same reason you have for any other insurance:
			you cannot plan for accidents, delays or illness, nor can you schedule your vacation around them. Accidents,
			emergencies and unforeseen circumstances can happen anywhere at any time, to anyone.
			Travel insurance can give you peace of mind by protecting your vacation investment, health and belongings.
			Nearly 70% of all travelers choose to protect their vacation investment with travel insurance.

		</p>
		<p>
			Why take a chance? Remember, no one can plan for accidents. Your health, belongings,
			vacation and peace of mind are easy to insure with our comprehensive Go Ready Travel Protection Plan by Aegis General.
		</p>
	</>
);
const benefitsList: Array<{ id: number; text: string; }> = [
	'Reimbursement of penalties should you have to cancel for a covered reason.',
	'Missed cruise connection assistance will make arrangements for you to meet the ship should you miss your departure.',
	'24-hour emergency medical assistance during travel.',
	'Reimbursement of unexpected travel expenses you may incur due to an emergency, travel delay, or lost luggage/documents.',
	'Emergency travel assistance 24 hours a day world-wide with Aegis’s Travel Protection Help Line.',
	`
	Cashless Benefits are available on certain portions of the program which eliminates the hassle of claim forms and
	fronting your own money for in-transit issues.
	`,
].map((value: string, index: number) => ({ id: index, text: value }));
export const WHY_PURCHASE_TEXT = (
	<p>
		<span className="font-weight_bold">Protecting Your Investment </span>
		The amount of money one risks on their vacation in cancellation penalties charged by the cruise line or tour
		company are usually more than the insurance coverage itself. Pre-existing conditions can be covered.
		You may not have a pre-existing condition, or know you do,
		but you may have family members who do and who may need you.
	</p>
);
const BENEFITS_TEXT = (
	<ul className="travel-insurance__list">
		{benefitsList.map((item) => <li key={item.id}>{item.text}</li>)}
	</ul>
);
const servicesList = [
	'Medical Assistance, Medical Consultation and Monitoring',
	'Telephone Interpretation Services',
	'Emergency Cash Transfers',
	'Travel Document and Ticket Replacement',
	'Hospital Admissions Assistance',
	'Legal Assistance',
];
const SERVICES_TEXT = (
	<>
		<p>
			Aegis Travel Protection also provides Assistance Services that are included with your insurance coverage.
			All services are available 24 hours a day, 7 days a week, 365 days a year, from anywhere in the world.
			Call Aegis Travel Protection at {link('855-277-4587', LinkType.Phone)} with any questions:
		</p>
		<ul className="travel-insurance__list">
			{servicesList.map((item: string) => <li key={v4()}>{item}</li>)}
		</ul>
	</>
);
export const DISCLAIMER = (
	<p>
		These plans are underwritten by Starr Indemnity & Liability Company, a Texas domiciled corporation
		(NAIC Company Code: 38318: TX license number: 93542) with its principal place of business at 399 Park Avenue,
		8th Floor, New York, NY 10022. Starr Indemnity & Liability Company is currently authorized to do business in all
		states, the District of Columbia and Puerto Rico. This brochure contains only a brief description
		of the coverages available under each plan and not all coverages may be available in all states.
		These plans provide insurance coverage during the covered trip. You may have coverage from other sources that
		provide you with similar benefits but may be subject to different restrictions depending upon your other coverage.
		You may wish to compare the terms of these policies with your existing life, health, home, and automobile
		insurance policies. If you have any questions about your current coverage, call your insurer or insurance agent
		or broker. Plans are administered by Starr Insure, LLC, a Delaware limited liability company
		(FL license number: L093030; www.starrassist.com) with its principal place of business at 399 Park Avenue,
		9th Floor, New York, NY 10022. Starr Insure, LLC is doing business as Starr Assist Insurance Agency,
		LLC in California (CA license number: 0F95103). If you have any questions about the plans offered on this website
		you may contact Starr Insure, LLC at the following: Toll-free customer hotline: {link('8664776741', LinkType.Phone)} or email at:
		{link('info@starrassist.com', LinkType.Mail)}.
	</p>
);

export const travelInsuranceList = [
	{
		title: 'Length of Travel',
		text: 'Up to 90 Days',
	},
	{
		title: 'Pre-Existing Conditions Waiver',
		text: 'If insurance purchased within 14 days of deposit',
	},
	{
		title: 'Additional Benefits',
		text: BENEFITS_TEXT,
	},
	{
		title: 'Assistance Services',
		text: SERVICES_TEXT,
	},
];

interface TableData {
	name: React.ReactNode;
	value: React.ReactNode;
}

export const travelData: Array<TableData> = [
	{
		name: 'Trip Cancellation',
		value: <span>Trip Cost<br /> <i>MA - Up to 100%</i></span>,
	},
	{
		name: 'Trip Interruption',
		value: <span>150% Trip Cost<br /> <i>MA - Up to 150%</i></span>,
	},
	{
		name: 'Single Occupancy Supplement',
		value: <span>Up to Trip Cost<br /> <i>MA - Not Applicable</i></span>,
	},
	{
		name: 'Trip Delay - Min 6 hours Delay',
		value: <span>$250 / day, Max: $2,000<br /> <i>MA - $200 / day, Max: $2,000</i></span>,
	},
	{
		name: 'Missed Connection Min 3 hours Delay',
		value: '$500',
	},
	{
		name: 'Airline Ticket Change Fee',
		value: '$200',
	},
	{
		name: 'Frequent Travel Loyalty',
		value: <span>$200<br /> <i>MA - Not Applicable</i></span>,
	},
	{
		name: <p>Itinerary Change -<i> Massachusetts Residents Only</i></p>,
		value: 'Included',
	},
];

export const emergencyData: Array<TableData> = [
	{
		name: 'Emergency Medical Evacuation & Repatriation',
		value: <span>$500,000<br /> <i>MA - $100,000</i></span>,
	},
	{
		name: 'Repatriation of Remains',
		value: '$500,000',
	},
];

export const healthData: Array<TableData> = [
	{
		name: 'Accident & Sickness Medical Expense',
		value: <span>$100,000<br /> <i>MA - Call for Details</i></span>,
	},
	{
		name: 'Emergency Dental | Deductible',
		value: <span>$750 | $0<br /> <i>MA - $500 | $0</i></span>,
	},
	{
		name: 'A D & D - Entire Trip',
		value: '$25,000',
	},
];

export const propertyData: Array<TableData> = [
	{
		name: 'Baggage Delay - Min 8 hours Delay MA - Min 12 hours Delay',
		value: '$200',
	},
	{
		name: '"Baggage & Personal Effects incl. Passport & Visa Replacement Expense"',
		value: <span>$1,000 -or- $1,500<br /> <i>MA - Up to $1,500</i></span>,
	},
	{
		name: 'Baggage & PE - Per Item | Valuables',
		value: <span>$250 | $500<br /> <i>MA - Up to $250 | Up to $750</i></span>,
	},
];
