import * as React from 'react';

interface ImageProps extends React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement> {
	fallback?: string;
}

export const Image: React.FC<ImageProps> = ({ fallback, ...props }: ImageProps) => {
	const [image, setImage] = React.useState<string | undefined>(() => props.src);

	React.useEffect(() => {
		setImage(props.src);
	}, [props.src]);

	return (
		<img
			{...props}

			src={image}
			alt={props.alt}

			onErrorCapture={(event: React.SyntheticEvent<HTMLImageElement>) => {
				if (image !== fallback) {
					setImage(fallback);
				}

				props.onErrorCapture?.(event);
			}}
		/>
	);
};
