import * as React from 'react';

import clsx from 'clsx';

import '@app/scss/pages/documents-pages.scss';
import {
	accoladesList,
	brands,
	COMMITMENT,
	communityOutreachList,
	COMMUNITY_OUTREACH,
	ENVIRONMENTAL_IMPACT, ETHICS,
	getIconSite, impactsIcon, getIntro,
	leaderShips, OUR_ACCOLADES, OUR_PARTNERS, OUR_PEOPLE,
	partners, SOCIAL_RESPONSIBILITY,
} from '@app/pages/StaticPages/AboutUs/text';
import { BurgerContext } from '@app/services/contexts';
import {
	ILeaderShip,
	IBrand,
	ICommunityOutreach,
	IAccolades,
	IPartners,
} from '@app/objects/AboutUsPage';

import { price } from '@image/aboutUsPage';

const titleColor = 'about-us__color font-family_primary condensed font-weight_semi-bold';

const IntroSection: React.FC = () => {
	const { toggleShowBurger, toggleShowSubscribeForm } = React.useContext(BurgerContext);

	return (
		<div className="documentation__section">
			<h1 className="documentation__title text-size_soft-large content-valign_center">
				About Us
			</h1>
			<div className="about-us__intro">
				<div className="about-us__intro__image">
					<img src={price} alt="Low price" />
				</div>
				{getIntro(toggleShowBurger, toggleShowSubscribeForm)}
			</div>
		</div>
	);
};

const LeadershipSection: React.FC = () => (
	<div className="about-us__leadership documentation__section">
		<h2 className="documentation__title text-size_soft-large content-valign_center">
			Our Leadership
		</h2>
		{leaderShips.map((item: ILeaderShip) => (
			<div className="about-us__leadership-row col-12" key={item.name}>
				<div className="layout-container">
					<div className="about-us__leadership-ship col-12 col-md-4">
						<h3 className="about-us__leadership-name text-size_soft-large">{item.name}</h3>
						<p className="about-us__leadership-grade">{item.grade}</p>
					</div>
					<p className="about-us__leadership-row__text col-12 col-md-8">{item.text}</p>
				</div>
			</div>
		))}
	</div>
);

export const BrandsSection: React.FC = () => (
	<div className="about-us__brands documentation__section">
		<h2 className="documentation__title text-size_soft-large content-valign_center">
			Our Brands
		</h2>
		<div className="about-us__brands-cards layout-container">
			{brands.map((item: IBrand) => (
				<div key={item.logo} className={item.className}>
					<div className="about-us__brands-card">
						<img src={item.logo} alt="Brand logo" />
						<p className="about-us__brands-text">{item.description}</p>
						<div className="about-us__brands__footer">
							{item.social.map((site) => (
								<a href={site.link} key={site.link} target="_blank" rel="noreferrer">
									{getIconSite(site.key)}
								</a>
							))}
						</div>
					</div>
				</div>
			))}
		</div>
	</div>
);

const CommitmentSection: React.FC = () => (
	<div className="documentation__section">
		<h2 className="documentation__title text-size_soft-large content-valign_center">
			Our Commitment
		</h2>
		<p>{COMMITMENT}</p>
	</div>
);

const SocialResponsibilitySection: React.FC = () => (
	<div className="documentation__section">
		<h2 className="documentation__title text-size_soft-large content-valign_center">
			Corporate Social Responsibility Pledge
		</h2>
		<p>{SOCIAL_RESPONSIBILITY}</p>
		<p>
			<ul className="about-us__pledge-cards-container layout-container">
				<li className="col-12 col-md-4">
					<div className="about-us__pledge-cards-container__card">
						<h2 className={titleColor}>Ethics</h2>
						<p>{ETHICS}</p>
					</div>
				</li>
				<li className="col-12 col-md-8">
					<div className="about-us__pledge-cards-container__card">
						<h2 className={titleColor}>Environmental Impact</h2>
						{ENVIRONMENTAL_IMPACT}
						<p className="about-us__pledge-cards-container__image-list">
							{impactsIcon.map((icon: string) => <img src={icon} alt="Company icon" key={icon} />)}
						</p>
					</div>
				</li>
				<li className="col-12 col-md-4">
					<div className="about-us__pledge-cards-container__card">
						<h2 className={titleColor}>Our People</h2>
						<p>{OUR_PEOPLE}</p>
					</div>
				</li>
				<li className="col-12 col-md-8">
					<div className="about-us__pledge-cards-container__card">
						<h2 className={titleColor}>Community Outreach</h2>
						<p>{COMMUNITY_OUTREACH}</p>
						<p>
							<ul className="about-us__pledge-cards-container__community-outreach">
								{communityOutreachList.map((item: ICommunityOutreach) => (
									<li key={item.icon}>
										<figure>
											<img src={item.icon} alt="Brand icon" />
											<figcaption className="text-size_xx-small font-weight_semi-bold">
												{item.text}
											</figcaption>
										</figure>
									</li>
								))}
							</ul>
						</p>
					</div>
				</li>
			</ul>
		</p>
	</div>
);

const PartnersSection: React.FC = () => (
	<div className="documentation__section">
		<h2 className="documentation__title text-size_soft-large content-valign_center">
			Our Partners
		</h2>
		<p>{OUR_PARTNERS}</p>
		<p className="partners__brands">
			<div className="partners__brands__content">
				{partners.map((item: IPartners) => (
					<a
						href={item?.link}
						key={item.link}
						target="_blank"
						rel="noreferrer"
						className={clsx(!item.link && 'disabled')}
					>
						<img src={item.icon} alt="Partners brand" />
					</a>
				))}
			</div>
		</p>
	</div>
);

const AccoladesSection: React.FC = () => (
	<div className="documentation__section">
		<h2 className="documentation__title text-size_soft-large content-valign_center">
			Our Accolades
		</h2>
		{OUR_ACCOLADES}
		<p className="accolades-links">
			{
				accoladesList.map((item: IAccolades) => (
					<a
						href={item.link}
						key={item.link}
						target="_blank"
						rel="noreferrer"
					>
						<div className="accolades-link" style={item.style}>
							<img
								src={item.icon}
								alt="Social icon"
							/>
							{item.text}
						</div>
					</a>
				))
			}
		</p>
	</div>
);

export const AboutUs: React.FC = () => (
	<div className="documentation about-us font-family_primary font-weight_regular text-size_extra-small">
		<IntroSection />
		<LeadershipSection />
		<BrandsSection />
		<CommitmentSection />
		<SocialResponsibilitySection />
		<PartnersSection />
		<AccoladesSection />
	</div>
);
