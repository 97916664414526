import * as React from 'react';

import { Rows } from '@app/objects/Rows';
import { Nullable } from '@app/objects/Utility';
import { FetchStore } from '@app/services/fetch/FetchStore';

export function useListFetchStore<T>(): FetchStore<Rows<T>> {
	const [loading, setLoading] = React.useState<boolean>(() => false);
	const [error, setError] = React.useState<Nullable<string>>(() => null);
	const [item, setItem] = React.useState<Nullable<Rows<T>>>(null);

	return {
		get loading(): boolean {
			return loading;
		},
		set loading(value: boolean) {
			setLoading(value);
		},

		get error(): Nullable<string> {
			return error;
		},
		set error(value: Nullable<string>) {
			setError(value);
		},

		get item(): Nullable<Rows<T>> {
			return item;
		},
		set item(value: Nullable<Rows<T>>) {
			let result = value;

			if (item !== null && result !== null) {
				result = {
					list: [...item.list, ...result.list],
					offset: item.offset,
					total: result.total,
				};
			}

			setItem(result);
		},
	};
}
