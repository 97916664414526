export class RequestError extends Error {
	private readonly _code: number;

	constructor(code: number, message: string) {
		super();

		this.message = message;
		this.name = 'Request Error';
		this._code = code;
	}

	public get code(): number {
		return this._code;
	}
}
