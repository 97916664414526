import * as React from 'react';

import { FilterHead } from '@app/components/Filters/FilterComponents/FilterComponents';
import { SorterOption } from '@app/objects/Sorter';
import { Radio } from '@app/components/Checkbox/Radio';
import { BaseSorterProps } from '@app/components/Sorter/Sorter';

import { Icon } from '@image/icon';

export const MobileSorter: React.FC<BaseSorterProps> = (props: BaseSorterProps) => {
	if (!props.open) return null;

	return (
		<div className="mobile-filters col-12">
			<FilterHead
				title="Sort by"
				titleIcon={<Icon.Sort fill="white" />}
				extraButton={(
					<button
						type="button"
						onClick={() => {
							props.onClose(false);
							document.body.classList.remove('no-scroll-body');
						}}
						className="mobile-filters__btn-close"
					>
						<Icon.Clear />
					</button>
				)}
			/>

			<div className="mobile-filters__content sorter">
				{
					props.options.map((item: SorterOption) => (
						<Radio
							key={item.value}
							label={item.label}
							checked={item.value === props.value}
							onChange={() => {
								props.onChange(item.value);
								props.onClose(false);
							}}
							containerClassName="form-group text-size_small"
						/>
					))
				}
			</div>
		</div>
	);
};
