import * as React from 'react';

import clsx from 'clsx';

import { Button, ButtonVariant } from '@app/components/Button/Button';

import { Icon } from '@image/icon/index';
import '@app/scss/components/carousel.scss';

interface PaginationProps {
	className?: string;

	total: number;
	hideOnSinglePage?: boolean;
	pageSize: number;
	current: number;
	goToNext?: () => void;
	goToPrev?: () => void;
	infinite?: boolean;
}

export const Pagination: React.FC<PaginationProps> = (props: PaginationProps) => {
	const {
		total, pageSize, current, hideOnSinglePage = true, infinite = false,
	} = props;

	const totalPage = Math.ceil(total / pageSize);

	if (hideOnSinglePage && pageSize >= total) return null;

	return (
		<div className={clsx('content-halign_center', props.className)}>
			<Button
				onClick={props.goToPrev}
				variant={ButtonVariant.Underlined}
				className="carousel__button"
				disabled={!infinite && current === 1}
			>
				<Icon.SliderArrowLeft />
			</Button>
			<p
				className="carousel__pagination-text font-family_primary font-weight_regular text-size_small"
			>
				{current} / {totalPage}
			</p>
			<Button
				onClick={props.goToNext}
				variant={ButtonVariant.Underlined}
				className="carousel__button"
				disabled={!infinite && current === totalPage}
			>
				<Icon.SliderArrowRight />
			</Button>
		</div>
	);
};
