import * as React from 'react';

import { Option as CoreOption } from 'rc-select';

export interface OptionProps {
	value: string | number | null;
	children: React.ReactNode;
}

export const Option: React.FC<OptionProps> = (props: OptionProps) => {
	return (
		<CoreOption value={props.value}>
			{props.children}
		</CoreOption>
	);
};
