import { isNumber } from '@app/objects/Utility';

export interface ISorter {
	options: Array<SorterOption>;

	value: Order;
	onChange: (value: Order) => void;
}

export interface Order {
	tag: Tag;
	direction: Direction;
}

export enum Direction {
	Ascending = 1,
	Descending = -1
}

export enum Tag {
	Price = 'Price',
	Date = 'Date',
	DeparturePort = 'Departure Port',
	CruiseLine = 'CruiseLine',
	Ship = 'Ship',
	Duration = 'Duration',
}

export interface SorterOption {
	value: number;
	label: string;
}

export function isTagKey(key: unknown | Tag): key is Tag {
	if (typeof key !== 'string') return false;

	return (Object.keys(Tag) as Array<string>).includes(key);
}

export function isTagValue(value: unknown | Tag): value is Tag {
	if (typeof value !== 'string') return false;

	return (Object.values(Tag) as Array<string>).includes(value);
}

export function isDirectionKey(key: unknown | Direction): key is Direction {
	if (typeof key !== 'string') return false;

	return (Object.keys(Direction) as Array<string>).includes(key);
}

export function isDirectionValue(value: unknown | Direction): value is Direction {
	if (!isNumber(value)) return false;

	return (Object.values(Direction)).includes(value);
}
