import * as React from 'react';
import { Link } from 'react-router-dom';
import clsx from 'clsx';

import { LinkType, MenuOption } from '@app/components/Header/Menu';

export const NavBarItem: React.FC<MenuOption> = (props: MenuOption) => {
	if (props.type === LinkType.External) {
		return (
			<a
				href={props.link}
				className={clsx('menu-item__link', props.className)}
			>
				{props.title}
			</a>
		);
	}

	return (
		<Link
			to={props.link}
			className={clsx('menu-item__link', props.className)}
		>
			{props.title}
		</Link>
	);
};
