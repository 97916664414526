import * as React from 'react';

import {
	Field,
	Form,
	Formik,
	FieldProps,
} from 'formik';
import * as Yup from 'yup';
import moment from 'moment';
import { OptGroup } from 'rc-select';

import { Select } from '@app/components/Selects/Select';
import { request, RequestConfig } from '@app/services/api';

import { Icon } from '@app/image/icon';
import { ActionMode, Button, ButtonVariant } from '@app/components/Button/Button';
import { Nullable } from '@app/objects/Utility';

import { Option } from '@app/components/Selects/Option';
import { removeDiacritics } from '@app/utilities/string';
import { DatePicker } from '@app/components/DatePicker/DatePicker';

import { AutoComplete } from '@app/components/AutoComplete/AutoComplete';
import { ActiveTabKey } from '@app/pages/MainPage/SearchCard/services';

import '@app/scss/layout.scss';

interface SearchHotel {
	activeKey: ActiveTabKey;
}

interface HotelForm {
	keyword: Nullable<string>;
	from: Nullable<number>;
	to: Nullable<number>;
	guests: number;
}

const initialValues: HotelForm = {
	keyword: null,
	from: null,
	to: null,
	guests: 2,
};

interface PlaceList {
	label: string;
	value: string | number;
	category: string;
}

interface PlaceListOptionGroup {
	category: string;
	options: Array<PlaceList>;
}

const validationSchema = Yup.object().shape({
	keyword: Yup.string()
		.required('Keyword is required')
		.typeError('Keyword is required'),
	from: Yup.number()
		.min(moment().startOf('day').valueOf(), 'Checkin date cannot be one in the past')
		.max(moment().startOf('day').add(310, 'days').valueOf(), 'Checkin date should not be more than 310 days in the future')
		.nullable(),
	to: Yup.number()
		.nullable()
		.when(
			'from',
			(from) => Yup.number()
				.min(moment(from[0]).startOf('day').add(1, 'days').valueOf(), 'Checkout date should be at least one day after checkin')
				.max(moment().startOf('day').add(310, 'days').valueOf(), 'Checkout date should not be more than 310 days in the future')
				.nullable(),
		),
	guests: Yup.number()
		.min(1, 'At least 1 guest should be selected')
		.max(5, 'At most 5 guests should be selected')
		.required('Please, pick guests count')
		.typeError('Please, pick guests count'),
});

const guests = Array(5)
	.fill(null)
	.map((item, index) => {
		const count = index + 1;
		const title = count > 1 ? `${count} people` : '1 person';

		return (
			<Option value={index + 1} key={count}>
				{title}
			</Option>
		);
	});

const base = 'https://hotels.onlinevacationcenter.com/5star/hotel-deals';
const dateFormat = 'YYYY-MM-DD';

function convertKeyword(keyword: string): string {
	return removeDiacritics(keyword)
		.toLowerCase()
		.replaceAll(' ', '-')
		.replaceAll(',', '');
}

function generateLink(form: HotelForm): string {
	const keyword: string = convertKeyword(form.keyword ?? '');
	const params: Array<string> = [
		`guests=${form.guests}`,
		'rooms=1',
		`room_1_adults=${form.guests}`,
		'room_1_children=0',
	];

	if (form.from) {
		params.push(`dtin=${moment(form.from).format(dateFormat)}`);
	}
	if (form.to) {
		params.push(`dtout=${moment(form.to).format(dateFormat)}`);
	}

	return `${base}/${keyword}/?${params.join('&')}`;
}

function onFetch(list: Array<PlaceList>): Array<PlaceListOptionGroup> {
	const groupList: Array<PlaceListOptionGroup> = [];
	list.forEach((item: PlaceList) => {
		if (!item.category) return;

		let group = groupList.find((elem) => elem.category === item.category);
		if (group) {
			group.options.push(item);
		} else {
			group = {
				category: item.category,
				options: [item],
			};
			groupList.push(group);
		}
	});

	return groupList;
}

const useRequest = (value: string, setPlace: (value: Array<PlaceListOptionGroup>) => void) => {
	if (value) {
		const path = `ajax/search.php?term=${value}&limit=10&json=true&inv=1`;
		const config: RequestConfig = {
			externalUrl: 'https://hotels.onlinevacationcenter.com',
		};

		request(path, config)
			.then((item: { data: Array<PlaceList> }) => setPlace(onFetch(item.data)))
			.catch((error: string) => console.log(error));
	} else {
		setPlace([]);
	}
};

export const SearchHotel: React.FC<SearchHotel> = (props: SearchHotel) => {
	const [place, setPlace] = React.useState<Array<PlaceListOptionGroup>>(() => []);
	const handleSubmit = (values: HotelForm) => {
		const link = generateLink(values);
		window.open(link);
	};

	return (
		<Formik
			initialValues={initialValues}
			onSubmit={handleSubmit}
			validationSchema={validationSchema}
		>
			{(formikBag) => (
				<Form id="hotel-search-form">
					<div className="layout-container">
						<div className="col-12 col-lg-6 col-xl-12">
							<div className="layout-container content-halign_center">
								<Field name="keyword">
									{({ field, form }: FieldProps<string, HotelForm>) => (
										<div className="col-12 col-xl-4">
											<AutoComplete
												value={field.value}
												onChange={(value?: string | number | null) => form.setFieldValue(field.name, value, false)}
												onSearch={(query: string) => useRequest(query, setPlace)}
												Icon={Icon.Location}

												placeholder="Where to*"
												text={form.errors.keyword}
												error={Boolean(form.errors.keyword)}
											>
												{
													place.map((item) => (
														<OptGroup label={item.category} key={item.category}>
															{item.options.map((option) => (
																<Option value={option.value} key={option.value}>
																	{option.label}
																</Option>
															))}
														</OptGroup>
													))
												}
											</AutoComplete>
										</div>
									)}
								</Field>
								<Field name="from">
									{({ form }: FieldProps<number, HotelForm>) => (
										<div className="col-12 col-xl-4">
											<DatePicker
												from={form.values.from}
												to={form.values.to}
												onChange={(from: Nullable<number>, to: Nullable<number>) => {
													form.setFieldValue('from', from, false);
													form.setFieldValue('to', to, false);
												}}
												shouldClose={props.activeKey !== ActiveTabKey.Hotels}

												min={moment().startOf('day').valueOf()}
												max={moment().add(310, 'day').valueOf()}

												text={form.errors.from || form.errors.to}
												error={Boolean(form.errors.from || form.errors.to)}

												Prefix={Icon.Calendar}
												title="Check-in / Check-out"
											/>
										</div>
									)}
								</Field>
								<Field name="guests">
									{({ field, form }: FieldProps<number, HotelForm>) => (
										<div className="col-12 col-xl-4">
											<Select
												value={field.value}
												onChange={(value: number) => form.setFieldValue(field.name, value, false)}
												allowClear={false}

												placeholder="Guests*"
												Icon={Icon.User}
												text={form.errors.guests}
												error={Boolean(form.errors.guests)}
											>
												{guests}
											</Select>
										</div>
									)}
								</Field>
							</div>
						</div>

						<div className="col-12 col-lg-6 col-xl-12 content-valign_center">
							<div className="layout-container reverse content-valign_top content-halign_center">
								<div className="col-12 col-xl-4">
									<Button
										className="uppercase"
										variant={ButtonVariant.Filled}
										onClick={formikBag.handleSubmit}
									>
										Search Hotels
									</Button>
								</div>
								<div className="col-12 col-xl-4">
									<Button
										variant={ButtonVariant.Underlined}
										action={ActionMode.Button}
										onClick={formikBag.resetForm}
									>
										Reset All
									</Button>
								</div>
							</div>
						</div>

					</div>
				</Form>
			)}
		</Formik>
	);
};
