import * as React from 'react';

import clsx from 'clsx';

import { LoaderProps, Loader } from '@app/components/Loader/Loader';

type OwnProps = React.PropsWithChildren<LoaderProps>;

export const LoaderOverlay: React.FC<OwnProps> = (props: OwnProps) => {
	return (
		<div className="loader-overlay__container">
			{props.children}
			<div className={clsx('loader-overlay', props.loading && 'loader-overlay_visible')}>
				<Loader {...props} />
			</div>
		</div>
	);
};
