import * as React from 'react';

import { RoutePaths } from '@app/utilities/RoutePathVars';
import { LinkType, link } from '@app/pages/StaticPages/utils';

import partners from '@image/partnersOfYear.png';

export const INTRO = `
We know you have many choices when it comes to planning your next vacation. Learn about what makes us different and why
you should choose Online Vacation Center to help craft your latest travel experience.
`;
const SUPERIOR_SERVICE = (
	<p>
		<span>
			Our expert team of &nbsp;
		</span>
		{link(RoutePaths.managers, LinkType.Internal, 'Personal Vacation Managers')}
		<span>
			<sup>SM</sup> will handle every aspect of your cruise vacation, helping to ensure you a fun and hassle-free
			travel experience. Your friendly and knowledgeable Personal Vacation Manager will work closely with you to
			identify your cruise interests and preferences and will help you find the best sailing at the best price.
		</span>
	</p>
);
const TRAVEL_INDUSTRY = `
Online Vacation Center is a registered seller of travel with the States of Florida (ST-32947),
California (CST-2064227-40) and Washington (WA SOT 602250083) with an A+ rating from the Better Business Bureau.
We are a family run business and have had the pleasure to plan vacations for thousands of clients since 1972.
`;
const EXCLUSIVE_SAVINGS = (
	<div className="choose-ovc__partners-block">
		<img src={partners} alt="Partners of year (2017)" className="choose-ovc__partners-img" />
		<p>
			Because of our preferred relationship with popular suppliers, Online Vacation Center is able to bring you
			exclusive offers you will not find anywhere else. We are proud to hold high-producing agent status with
			every major cruise line and were honored to be recognized as Azamara Club Cruises' Overall Account of the
			Year in recent years. We have also been awarded as a top-producing Platinum Circle agency with Viking River
			Cruises each year since 2010, and in 2018, 2017, 2016 and 2013 we were named Celebrity Cruises' top
			Strategic Partner of the Year.
		</p>
	</div>
);
const LOW_PRICE = `
Whether you're booking a cruise, tour or other vacation package, we promise that at the time of booking,
our offer will beat or match the lowest available price advertised by competitors. Online Vacation Center guarantees
its quoted price will match the cruise line's lowest available price available directly to the public at the time of
booking. If the cruise line has availability on the same sailing and category for less, let us know at the time of
booking, and we will match the price. Rates change daily. Guarantee applies to new bookings and on direct rates
offered to the public.`;
const PACKAGES = `
No matter where or how you want to travel, Online Vacation Centerм can take you there for less! Check out our latest
offers on cruises, vacation packages with air, hotel and more, and escorted tours. Your complete vacation awaits:
Airfare and taxes to and/or from destination, deluxe hotel stay with daily breakfast at hotel, cruise fare with exclusive
amenities and no hidden fees.`;
const CONVENIENCE = `
Online Vacation Center provides the convenience of allowing you to book online any time! Plus, our Personal Vacation
Managers are available to serve you Monday through Friday from 9 am to 6 pm ET.`;
const HIGH_RATING = `
	From exquisitely personalized service during the booking process to incredible overseas staff on your voyage,
	Online Vacation Center prides itself in its staff and ability to wow our guests.
	Read what some of our satisfied clients say about Online Vacation Center and the vacations
		they booked through us.
`;

export const sections = [
	{
		title: 'Superior Service from Dedicated Travel Professionals',
		description: SUPERIOR_SERVICE,
	},
	{
		title: '50 Years of Travel Industry Expertise',
		description: TRAVEL_INDUSTRY,
	},
	{
		title: 'Exclusive Savings on Major Suppliers + Accolades',
		description: EXCLUSIVE_SAVINGS,
	},
	{
		title: 'Low Price Guarantee',
		description: LOW_PRICE,
	},
	{
		title: 'Value Inclusive Packages',
		description: PACKAGES,
	},
	{
		title: 'Convenience of 24/7 Travel Reservations',
		description: CONVENIENCE,
	},
	{
		title: 'High Customer Satisfaction Rating',
		description: HIGH_RATING,
	},
];
