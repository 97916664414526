import * as React from 'react';

import { useNavigate, useLocation } from 'react-router-dom';

import Cookies from 'js-cookie';

const aokey = 'aotracker';

/**
 * Copy aotracker cookies to query params
 */
export function useAoTracker() {
	const navigate = useNavigate();
	const location = useLocation();

	React.useEffect(() => {
		const value = Cookies.get(aokey);
		if (!value) return;

		const map = new Map<string, string>();
		location.search
			.replace('?', '')
			.split('&')
			.filter((q: string) => Boolean(q))
			.forEach((q: string) => {
				const [key, value] = q.split('=');
				map.set(key, value);
			});
		value
			.split('&')
			.filter((q: string) => Boolean(q))
			.forEach((q: string) => {
				const [key, value] = q.split('=');
				map.set(key, value);
			});

		const list: Array<string> = [];
		map.forEach((value, key) => {
			if (value) {
				list.push(`${key}=${value}`);
			} else {
				list.push(key);
			}
		});

		const query = list.join('&');
		const path = `${location.pathname}?${query}${location.hash}`;

		navigate(path);
	}, []);
}
