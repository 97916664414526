import * as React from 'react';

import { FilterHead, FilterBody } from '@app/components/Filters/FilterComponents/FilterComponents';
import { BaseFiltersProps } from '@app/components/Filters/Filters';

import '@app/scss/components/filters.scss';

import { Icon } from '@image/icon';

export const MobileFilters: React.FC<BaseFiltersProps> = (props: BaseFiltersProps) => {
	if (!props.open) return null;

	return (
		<div className="mobile-filters col-12">
			<FilterHead
				title="Filter Your Search"
				titleIcon={<Icon.Filter />}
				extraButton={(
					<button
						type="button"
						onClick={() => {
							props.onClose(false);
							document.body.classList.remove('no-scroll-body');
						}}
						className="mobile-filters__btn-close"
					>
						<Icon.Clear />
					</button>
				)}
			/>

			<div className="mobile-filters__content">
				<FilterBody
					extra={props.extra}
					canReset={props.canReset}
					onReset={props.onReset}
				>
					{props.children}
				</FilterBody>
			</div>
		</div>
	);
};
