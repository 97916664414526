/* eslint-disable max-len */
import * as React from 'react';
import { v4 } from 'uuid';

import { LinkType, link } from '@app/pages/StaticPages/utils';

import recognitionImg from '@app/image/careers/recognition.png';
import healthImg from '@app/image/careers/health.png';
import discountsImg from '@app/image/careers/discounts.png';

export interface BenefitWrap {
	id: string;
	list: Array<Benefit>;
}

export interface Benefit {
	id: string;
	value: string;
}

export interface Benefits {
	icon: string;
	title: string;
	benefits: Array<BenefitWrap>;
	className?: string;
}

const hrEmail = link('hr@onlinevacationcenter.com', LinkType.Mail);

export const INTRO_I = 'Online Vacation Center Holdings Corp\'s (ONVC) 11,000 square-foot office is located in beautiful Fort Lauderdale, Florida, just minutes from the downtown area—and a hub of the cruise line industry. At ONVC, we thrive in a fast-paced entrepreneurial environment that is performance driven. We work in a professional, fun and family friendly atmosphere where employees are treated with the highest level of respect. We recognize hard work and great achievements with awards, rewards and incentives. We love what we do and are passionate about travel and exploring the world. We lift each other up with peer-to-peer recognition rewards and we celebrate each other\'s milestones. Our employees are smart, hard-working, dedicated and strive for excellence every day.';
export const INTRO_II = 'ONVC is dedicated to taking care of our clients and employees at every stage of their experience with us. We always strive for excellence in service. What makes this easy is loving what we do—planning amazing cruise vacations and making dreams come true! Our people have a passion for cruise travel, love planning new adventures, and sharing their experiences with other cruise lovers or the cruise-curious. They are passionate about sharing their cruise knowledge with each other and their clients in order to create the cruise vacation experiences you have dreamed about. We act honestly, respectfully, with integrity and an attitude of collaboration and service at every turn.';
export const OPPORTUNITIES = (
	<p>
		Whether you would like to begin an exciting career in the travel industry, or you are a seasoned travel professional seeking a new career opportunity, you may be the perfect fit for Online Vacation Center Holdings Corporation! Our Company is expanding, and we are looking for candidates who are seeking a rewarding career in the travel industry. We also have excellent entry-level, fulltime opportunities for recent college graduates. To apply, please send an email to {hrEmail}
	</p>
);
const compensations: Array<string> = [
	'Competitive Salaries',
	'Generous PTO Policy',
	'401(k) Retirement Savings Plan',
	'Flexible Spending Account',
	'Performance Based Bonus Program',
	'Employee Referral Program',
	'Recognition Rewards',
];
const health: Array<string> = [
	'Medical Insurance',
	'Dental & Vision Insurance',
	'Health Savings Account',
	'Healthcare Concierge Services',
	'Accident Insurance 100% Employer Paid',
	'Life Insurance 100% Employer Paid',
	'Employee Assistance Program',
];
const perks: Array<string> = [
	'Travel Discounts',
	'Training & Development Opportunities',
	'Adoption Financial Assistance',
	'Commuter Benefits',
	'Monthly Birthday Celebrations',
];
const discounts: Array<string> = [
	'Pet Services Discount Program',
	'Casual Dress Code',
	'Gym Membership Discounts',
	'Cell Phone Discount Programs',
	'Employee Appreciation Events',
];

function toBenefit(item: string): Benefit {
	return {
		id: v4(),
		value: item,
	};
}

export const benefitsList: Array<Benefits> = [
	{
		icon: recognitionImg,
		title: 'Compensation & Recognition',
		benefits: [{
			id: v4(),
			list: compensations.map(toBenefit),
		}],
		className: 'col-md-6 compensation',
	},
	{
		icon: healthImg,
		title: 'Health & Wellbeing',
		benefits: [{
			id: v4(),
			list: health.map(toBenefit),
		}],
		className: 'col-md-6 health',
	},
	{
		icon: discountsImg,
		title: 'Perks & Discounts',
		benefits: [{
			id: v4(),
			list: perks.map(toBenefit),
		}, {
			id: v4(),
			list: discounts.map(toBenefit),
		}],
		className: 'discounts',
	},
];
