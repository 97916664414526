import React, { useEffect, useState } from 'react';

import batchlogo from '../../../../image/valueinclusive/best-sellers.png';

import calendar from '../../../../image/valueinclusive/calander.svg';

import location from '../../../../image/valueinclusive/location.svg';

import {
	CardImages,
	DefaultCardImages,
	SearchValuInclusiveUrl,
	SpecialDealURL,
} from '../../../../constants/url.constants';

import { ITopPackages } from '../ValueInclusive';

interface IProps {
	TopPackages: Array<ITopPackages>;
}

export const TopPackges: React.FC<IProps> = (props) => {
	function redirectToAnotherUrl() {
		window.location.href = `${SearchValuInclusiveUrl}`;
	}
	const [topPackages, settopPackages] = useState<Array<ITopPackages>>([]);
	useEffect(() => {
		settopPackages(props.TopPackages);
	}, [props]);

	return (
		<>
			<div className="about-main">
				<div className="aboutValueInclusive">
					<div className="aboutUs">
						<h1><b>Value</b> <b>Inclusive</b> <span className="aboutText">Package® (VIP)</span></h1>
					</div>
					<div className="abouttextItem">
						<p>Our exclusive Value Inclusive Packages offer an all-in-one approach to your cruise vacation,
							combining convenience, affordability and industry expertise into one enticing bundle.
							These packages include popular cruise itineraries, airfare, handpicked hotels, transportation,
							and special amenities at a discounted rate compared to booking each component separately.
							Plus, our dedicated team takes care of the details to make your experience streamlined and
							hassle-free. Explore the world with ease—see our best-selling packages below!
						</p>
						<div className="mainItemCard-inner">
							<div className="aboutImageBg">
								<div className="inclusive-img">
									<img src="https://www.onlinevacationcenter.com/images/icons/cruise-blue.png" alt="" />
									<h4>Cruise</h4>
									<p>Advantage Cruise Fares</p>
								</div>
								<div className="inclusive-img">
									<img src="https://www.onlinevacationcenter.com/images/icons/air-blue.png" alt="" />
									<h4>Airfare</h4>
									<p>
										Economy Air & Transfers
									</p>
								</div>
								<div className="inclusive-img">
									<img src="https://www.onlinevacationcenter.com/images/icons/hotels-blue.png" alt="" />
									<h4>Hotel</h4>
									<p>4★ Central Hotels</p>
								</div>
								<div className="inclusive-img">
									<img src="https://www.onlinevacationcenter.com/images/icons/obc-blue.png" alt="" />
									<h4>Perks</h4>
									<p>Exclusive Onboard Extras</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="mainItem">
				<div className="imageTopPackges">
					<h1 className="">OUR TOP <span className="setting-hed">Selling</span> Packages</h1>
					<div className="mainItemCard ">
						<div className="mainItemCard-inner">
							{
								topPackages && topPackages.map((value) => {
									return (
										<div className="packages-card" key={value.saildate}>
											<a href={`${SpecialDealURL}${value.specialsID}`}>
												<div className="imageCardItem">
													<div className="cardimage">
														{value.imagePath ? (
															<img src={`${CardImages}${value.imagePath}${value.imageName}`} alt="" />
														) : (
															<img src={`${DefaultCardImages}${value.image}`} alt="" />
														)}
													</div>
													<div className="logoGiftImage" />
													<div className="batclogo-sec">
														<div className="logoOceania">
															<img src={value.supplierLogo} alt="" />
														</div>
														<div className="batchlogo">
															<img className="" src={batchlogo} alt="" />
														</div>
													</div>
													<div className="companyName">
														<p>{value.countryCityEmb} to {value.countryCityDeb} <br /> {value.ship}</p>
													</div>
													<div className="iconCalLocation align-items-center">
														<div className="pakeges-icon">
															<span><img src={calendar} alt="" /></span> <span className="mx-2">{value.saildate}</span>
														</div>
														<div className="pakeges-icon">
															<span><img src={location} alt="" /></span> <span className="mx-2">{value.product}</span>
														</div>
													</div>
												</div>
											</a>
										</div>
									);
								})
							}
						</div>
					</div>
					<button
						className="btnWarning"
						type="button"
						onClick={redirectToAnotherUrl}
					>
						{`View ${topPackages[0]?.vipDealsCount} Exclusive Packages`}
					</button>
				</div>
			</div>
		</>
	);
};
