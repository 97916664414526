import * as React from 'react';

import { BaseSelectPropsWithoutPrivate } from 'rc-select/lib/BaseSelect';
import { SelectProps as CoreSelectProps, BaseSelectRef } from 'rc-select';

import { TIcon } from '@app/objects/Utility';
import { OptionProps } from '@app/components/Selects/Option';

export type TValue = number | string | null;

export enum SelectMode {
	Multiple = 'multiple',
	ComboBox = 'combobox',
	Tags = 'tags',
}

interface BaseSelectProps<T extends TValue> extends Omit<BaseSelectPropsWithoutPrivate, 'mode'> {
	disabled?: boolean;
	loading?: boolean;
	allowClear?: boolean;
	showSearch?: boolean;
	filterOption?: (inputValue: string, option?: OptionProps) => boolean;

	Icon?: TIcon;
	placeholder?: string;
	optionFilterProp?: string;

	tabIndex?: number;

	// Style
	className?: string;
	style?: React.CSSProperties;

	// Handlers
	onSelect?: (value: T) => void;
	onDeselect?: (value: T) => void;
	onSearch?: (query: string) => void;
	onBlur?: () => void;
	onFocus?: () => void;
	onLoadMore?: () => void;
	onInputKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
	onPopupScroll?: React.UIEventHandler<HTMLDivElement>;
	onMouseEnter?: (e: React.MouseEvent<HTMLInputElement>) => void;
	onMouseLeave?: (e: React.MouseEvent<HTMLInputElement>) => void;

	children?: React.ReactNode;

	text?: string; // This is an additional description text
	error?: boolean; // If true - component is marked as 'with error'
}

export interface SingleSelectProps<T extends TValue> extends BaseSelectProps<T> {
	value?: T;
	onChange?: (value: T) => void;

	multiple?: false;
}

export interface MultipleSelectProps<T extends TValue> extends BaseSelectProps<T> {
	value?: Array<T>;
	onChange?: (value: Array<T>) => void;

	multiple: true;
	maxTagCount?: number;	// how many tags will be shown
	maxTagTextLength?: number; // how many symbols will be shown in the name tag
}

export type SelectProps<T extends TValue> = SingleSelectProps<T> | MultipleSelectProps<T>;

export interface UseSelectProps<T> {
	properties: CoreSelectProps<T> | CoreSelectProps<Array<T>>;
	ref: React.RefObject<BaseSelectRef>;
	focus: boolean;
	active: boolean;
}

export interface SingleHooksProps<T> {
	singleProperties: CoreSelectProps<T>;
	hasValue?: boolean;
}

export interface MultipleHooksProps<T> {
	multipleProperties: CoreSelectProps<Array<T>>;
	hasValue?: boolean;
}
