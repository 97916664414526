import {
	SorterOption,
	Order,
	Tag,
	Direction,
} from '@app/objects/Sorter';
import { Nullable } from '@app/objects/Utility';

export const sorterMapper: Array<Order> = [
	{
		tag: Tag.Price,
		direction: Direction.Ascending,
	},
	{
		tag: Tag.Price,
		direction: Direction.Descending,
	},
	{
		tag: Tag.Date,
		direction: Direction.Ascending,
	},
	{
		tag: Tag.Date,
		direction: Direction.Descending,
	},
	{
		tag: Tag.DeparturePort,
		direction: Direction.Ascending,
	},
	{
		tag: Tag.DeparturePort,
		direction: Direction.Descending,
	},
	{
		tag: Tag.CruiseLine,
		direction: Direction.Ascending,
	},
	{
		tag: Tag.CruiseLine,
		direction: Direction.Descending,
	},
	{
		tag: Tag.Ship,
		direction: Direction.Ascending,
	},
	{
		tag: Tag.Ship,
		direction: Direction.Descending,
	},
	{
		tag: Tag.Duration,
		direction: Direction.Ascending,
	},
	{
		tag: Tag.Duration,
		direction: Direction.Descending,
	},
];

export const sorterOptions: Array<SorterOption> = [
	{
		value: 0,
		label: 'Price: Low to High',
	},
	{
		value: 1,
		label: 'Price: High to Low',
	},
	{
		value: 2,
		label: 'Departure Date: First to Last',
	},
	{
		value: 3,
		label: 'Departure Date: Last to First',
	},
	{
		value: 4,
		label: 'Departing From: Alphabetical A to Z',
	},
	{
		value: 5,
		label: 'Departing From: Alphabetical Z to A',
	},
	{
		value: 6,
		label: 'Cruise Line: Alphabetical Z to A',
	},
	{
		value: 7,
		label: 'Cruise Line: Alphabetical A to Z',
	},
	{
		value: 8,
		label: 'Cruise Ship: Alphabetical A to Z',
	},
	{
		value: 9,
		label: 'Cruise Ship: Alphabetical Z to A',
	},
	{
		value: 10,
		label: 'Duration: Shortest to Longest',
	},
	{
		value: 11,
		label: 'Duration: Longest to Shortest',
	},
];

export function getOrder(id: number): Nullable<Order> {
	if (id < 0 || id >= sorterMapper.length) return null;

	return sorterMapper[id];
}

export function getOption(tag: Tag, direction: Direction): SorterOption {
	const id = sorterMapper.findIndex((item: Order) => item.tag === tag && item.direction === direction);

	if (id < 0) return sorterOptions[0];

	return sorterOptions[id];
}
