import * as React from 'react';

import { PackageType, getPackageTypeName } from '@app/objects/CruisePackages';

import ValueInclusivePackage from '@app/image/brands/value-inclusive-package.png';
import EnrichmentJourney from '@app/image/brands/enrichment-journey.png';
import AirInclusive from '@app/image/brands/air-inclusive-package.png';
import CruiseOnly from '@app/image/brands/cruise.png';

interface BrandLogoProps {
	type: PackageType;

	className?: string;
	style?: React.CSSProperties;
}

const logos: Record<PackageType, string> = {
	[PackageType.ValueInclusivePackage]: ValueInclusivePackage,
	[PackageType.EnrichmentJourney]: EnrichmentJourney,
	[PackageType.AirInclusive]: AirInclusive,
	[PackageType.Tour]: '',
	[PackageType.CruiseOnly]: CruiseOnly,
	[PackageType.Unknown]: '',
};

function getImage(type: PackageType): string {
	return logos[type];
}

export const BrandLogo: React.FC<BrandLogoProps> = (props: BrandLogoProps) => (
	<img
		src={getImage(props.type)}
		alt={`${getPackageTypeName(props.type)} logo`}
		className={props.className}
		style={props.style}
	/>
);
