/* eslint-disable max-len */
import * as React from 'react';

import { v4 } from 'uuid';

import { link, LinkType } from '@app/pages/StaticPages/utils';
import { RoutePaths } from '@app/utilities/RoutePathVars';

const CIBTvisasLink = link('https://cibtvisas.com/?login=onlinevacationcenter', LinkType.External, 'Click Here');

interface KeyedListItem {
	id: number;
	text: string;
}
const domesticTravelList: Array<KeyedListItem> = [
	{
		id: 0,
		text: `
			For domestic travel which includes: the Bahamas, Bermuda, Canada, the Caribbean and Mexico, a passport
			(valid or expired) is highly recommended.
		`,
	},
	{
		id: 1,
		text: `
			US citizens on closed-loop cruises (cruises that begin and end at the same port in the US) will be able to
			enter or depart the country with a birth certificate (original or a certified copy), plus a picture ID card
			issued by a federal, state or local government agency.
		`,
	},
	{
		id: 2,
		text: `
			In the absence of a passport, a birth certificate (original or a certified copy), plus a picture ID card
			issued by a federal, state or local government agency is required.
		`,
	},
	{
		id: 3,
		text: 'A voter registration card or Social Security Card are not considered to be proof of citizenship.',
	},
	{
		id: 4,
		text: `
			A US citizen under the age of 16 will be able to present either an original or notarized or certified
			copy of his or her birth certificate, a Consular Report of Birth Abroad issues by DOS, or Certificate of
			Naturalization issued by US Citizenship and Immigration Services.
		`,
	},
	{
		id: 5,
		text: `
			US lawful permanent residents will continue to be able to use their Alien Registration Card issued by the
			Department of Homeland Security or other valid evidence of permanent residence status.
		`,
	},
];
const VWPList: Array<KeyedListItem> = [
	{
		id: 0,
		text: `
			Machine-readable passports issued or renewed/extended on or after 10/26/06: e-Passports,
			otherwise a visa will be required.
		`,
	},
	{
		id: 1,
		text: `
			Machine-readable passports issued or renewed/extended between 10/26/05 and 10/25/06: e-Passports or
			a passport with a digital photograph printed on the data page, otherwise a visa will be required.
		`,
	},
	{
		id: 2,
		text: 'Machine-readable passports issued or renewed/extended before 10/26/05: no further requirements.',
	},
];

export const INTRO = (
	<>
		<p>
			All guests traveling across any international boundary are solely responsible for obtaining all necessary
			travel documents, including any required visas, and for complying with the laws of each country flown from
			(the departure country), through (any transit country) and into (the destination country).
			Travel documentation requirements and rules can be confusing and can change without notice.
		</p>
		<p>
			It is <span className="font-weight_bold">STRONGLY RECOMMENDED</span> that all guests travel with a passport
			that is valid for at least six (6) months after their cruise. This greatly assists guests who may need to
			fly out of the United States to meet their ship at the next available port should they miss their scheduled
			embarkation in a US port; guests entering the US at the end of their cruise; and guests needing to fly
			to the US before their cruise ends, because of medical, family, personal or business emergencies, missing
			a ship's departure from a port of call, involuntary disembarkation from a ship due to misconduct,
			or other reasons.
		</p>
		<p className="font-weight_bold">
			Guests who do not possess the proper documentation may be prevented from boarding their flight or ship
			or from entering a country and may be subject to fines. No refunds will be given to individuals who fail to
			bring proper documentation.
		</p>
		<p>
			For additional passport information, visit the US Department of State ({link('http://www.travel.state.gov/', LinkType.External, 'www.travel.state.gov')}).
			<br />
			We recommend CIBTvisas for Visa and Passport processing: {CIBTvisasLink}.
			<br />
			View Online Vacation Center's General Terms and Conditions:  {link(RoutePaths.generalTerms, LinkType.Internal, 'Click Here')}.
		</p>
	</>
);
const AIR_TRAVEL = `
ALL persons, including US citizens, traveling internationally by air are required to present a valid passport to enter
(or re-enter) the United States. This includes travel between the United States and Canada, Mexico, Central and
South America, the Caribbean and Bermuda.
`;
const DOMESTIC_TRAVEL = (
	<ul className="'doc-requirements__list doc-requirements__list-without-padding">
		{domesticTravelList.map((item: KeyedListItem) => <li key={item.id}>{item.text}</li>)}
	</ul>
);
const INTERNATIONAL_TRAVEL = `
A valid passport is required; visas are required where they apply. This includes Europe, Asia, Central and South America.
In addition to your passport, it is recommended that you bring with you a second official form of identification,
such as a driver's license or identification card.
`;
const NON_US = `
You will need a valid passport and, in some cases, a visa. If you live in the US, you will also need the original
copy of your Alien Registration Card (ARC or "Green Card") and any other documentation the countries on your itinerary
require due to your alien status.
`;
const VISAS = (
	<p>
		It is the customer's responsibility to identify and obtain the required visas before travel.
		<br />
		We recommend CIBTvisas for Visa and Passport processing: {CIBTvisasLink}.
	</p>
);
const VISA_WAIVER = (
	<>
		<p>
			Citizens from the Visa Waiver Program countries of: Andorra, Australia, Austria, Belgium, Brunei,
			Czech Republic, Denmark, Estonia, Finland, France, Germany, Greece, Hungary, Iceland, Ireland, Italy, Japan,
			Liechtenstein, Lithuania, Luxembourg, Malta, Monaco, the Netherlands, New Zealand, Norway, Portugal,
			San Marino, Singapore, Slovakia, Slovenia, South Korea, Spain, Sweden, Switzerland, and the United Kingdom
			seeking to enter the United States will be required to have in their possession a machine readable passport
			valid for the duration of the voyage. A machine readable passport is one having an alphanumeric code on the
			bottom of the picture page and contains certain biographical data. Travelers from the VWP countries seeking
			to enter the United States will be required to have in their possession the following:
		</p>
		<p>
			<span className="font-weight_bold">
				Nationals of the Czech Republic, Estonia, Greece, Hungary, the Republic of Korea, Latvia, Lithuania,
				Malta, and Slovakia require e-Passports. Nationals of other VWP countries:
			</span>
			<ul className="doc-requirements__list">
				{VWPList.map((item: KeyedListItem) => <li key={item.id}>{item.text}</li>)}
			</ul>
		</p>
		<p>
			Effective July 1, 2009, all Visa Waiver Program (VWP) emergency or temporary passports must be electronic
			passports (e-Passports) to be eligible for travel to the United States without a visa under the VWP.
		</p>
	</>
);
const IMMUNIZATION = `
Vaccinations are recommended in some countries; in others, they are required. The Center for Disease Control and the
World Health Organization provide guidelines for each country's specific immunization requirements. Please be sure to
check with your health-care professional for guidance regarding vaccinations.
`;

interface Requirement {
	id: string;
	title: string;
	text: React.ReactNode;
}

export const requirements: Array<Requirement> = [
	{
		id: v4(),
		title: 'AIR TRAVEL',
		text: AIR_TRAVEL,
	},
	{
		id: v4(),
		title: 'DOMESTIC TRAVEL',
		text: DOMESTIC_TRAVEL,
	},
	{
		id: v4(),
		title: 'INTERNATIONAL TRAVEL',
		text: INTERNATIONAL_TRAVEL,
	},
	{
		id: v4(),
		title: 'NON US-CITIZENS',
		text: NON_US,
	},
	{
		id: v4(),
		title: 'VISAS',
		text: VISAS,
	},
	{
		id: v4(),
		title: 'VISA WAIVER PROGRAM (VWP)',
		text: VISA_WAIVER,
	},
	{
		id: v4(),
		title: 'IMMUNIZATION',
		text: IMMUNIZATION,
	},
];
