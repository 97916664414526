import * as React from 'react';

import '@app/scss/components/cookies.scss';
import { ActionMode, Button, ButtonVariant } from '@app/components/Button/Button';
import { Notification } from '@app/components/Notification/Notification';

const acceptCookies = 'acceptCookies';

export const CookiesAlert: React.FC = () => {
	const [isCookiesAccept, setIsCookiesAccept] = React.useState<boolean>(() => Boolean(window.localStorage.getItem(acceptCookies)));

	if (isCookiesAccept) return null;

	return (
		<Notification
			content={(
				<div className="cookies">
					<p className="font-family_primary text-size_small line-height_large">
						We use cookies to enhance your experience. By continuing to visit this site you agree to our use of cookies.
					</p>
					<div className="cookies__actions">
						<a
							href="/privacy_policy.cfm"
							className="cookies__link font-family_primary text-size_small line-height_large"
						>
							More info
						</a>
						<Button
							variant={ButtonVariant.Filled}
							className="cookies__accept"
							action={ActionMode.Button}
							onClick={() => {
								window.localStorage.setItem(acceptCookies, 'true');
								setIsCookiesAccept(true);
							}}
						>
							Got it!
						</Button>
					</div>
				</div>
			)}
			className="col-8 col-sm-4 col-md-3"
		/>
	);
};
