import * as React from 'react';

import {
	Form,
	Formik,
	Field,
	FieldProps,
} from 'formik';

import { Select } from '@app/components/Selects/Select';
import { Option } from '@app/components/Selects/Option';
import { Icon } from '@app/image/icon';
import { ActionMode, Button, ButtonVariant } from '@app/components/Button/Button';

import { useOdesseus } from '@app/odesseus/useOdesseus';
import {
	OdesseusFilterKeys,
} from '@app/odesseus/types/Filters';
import { Nullable } from '@app/objects/Utility';
import { OdyManager } from '@app/odesseus/OdyManager';

import {
	OdesseusFilterRecord,
	OdesseusSearchFormValues,
	OdesseusSelectOption,
} from '@app/odesseus/types/Filters/Odesseus';
import { Range } from '@app/objects/Filters/Range';
import {
	addOdesseusRanges,
	addOdesseusValues,
	getSearchURL,
} from '@app/odesseus/FilterServices';

import '@app/scss/layout.scss';
import { getReferralCookie } from '@app/services/formData';

const initialValues: OdesseusSearchFormValues = {
	[OdesseusFilterKeys.DURATION]: [],
	[OdesseusFilterKeys.DEPARTUREDATE]: [],
	[OdesseusFilterKeys.DESTINATION]: [],
	[OdesseusFilterKeys.CRUISELINE]: [],
	[OdesseusFilterKeys.SHIP]: [],
	[OdesseusFilterKeys.DEPARTUREPORT]: [],
};

export const SearchCruises: React.FC = () => {
	const [filters, setFilters] = React.useState<Array<OdesseusFilterRecord>>([]);
	const [lastChange, setLastChange] = React.useState<Nullable<OdesseusFilterKeys>>(() => null);
	const { options, loading } = useOdesseus(filters, lastChange);

	return (
		<Formik
			initialValues={initialValues}
			onSubmit={(values: OdesseusSearchFormValues) => {
				const params = getReferralCookie();
				const url = params ? `${getSearchURL(values, options)}&rf=${params.rf}&cid=${params.cid}` : getSearchURL(values, options);
				window.open(url);
			}}
		>
			{
				(formikBag) => (
					<Form id="cruises-search-form" className="layout-container content-halign_center">
						<div className="col-12 col-lg-6 col-xl-12">
							<div className="layout-container content-halign_center">
								<Field name={OdesseusFilterKeys.DESTINATION}>
									{({ field, form }: FieldProps) => (
										<div className="col-12 col-xl-4 content-valign_bottom">
											<Select<number>
												placeholder="Destination/River"
												Icon={Icon.Location}
												tabIndex={1}
												multiple
												loading={loading}
												maxTagCount={6}
												value={field.value}
												onChange={(values: Array<number>) => {
													form.setFieldValue(field.name, values);
													setFilters(addOdesseusValues(filters, OdesseusFilterKeys.DESTINATION, values));
													setLastChange(OdesseusFilterKeys.DESTINATION);
												}}
											>
												{
													options?.destinations.map((option: OdesseusSelectOption) => (
														<Option key={option.value} value={option.value}>
															{option.name}
														</Option>
													))
												}
											</Select>
										</div>
									)}
								</Field>
								<Field name={OdesseusFilterKeys.CRUISELINE}>
									{({ field, form }: FieldProps) => (
										<div className="col-12 col-xl-4 content-valign_bottom">
											<Select<number>
												placeholder="Cruise Line"
												Icon={Icon.Helm}
												tabIndex={4}
												multiple
												loading={loading}
												maxTagCount={6}
												value={field.value}
												onChange={(values: Array<number>) => {
													form.setFieldValue(field.name, values);
													setFilters(addOdesseusValues(filters, OdesseusFilterKeys.CRUISELINE, values));
													setLastChange(OdesseusFilterKeys.CRUISELINE);
												}}
											>
												{
													options?.cruiselines.map((option: OdesseusSelectOption) => (
														<Option key={option.value} value={option.value}>
															{option.name}
														</Option>
													))
												}
											</Select>
										</div>
									)}
								</Field>
								<Field name={OdesseusFilterKeys.DEPARTUREDATE}>
									{({ field, form }: FieldProps) => (
										<div className="col-12 col-xl-4 content-valign_bottom">
											<Select<number>
												placeholder="Travel Dates"
												Icon={Icon.Calendar}
												tabIndex={5}
												multiple
												loading={loading}
												maxTagCount={6}
												value={field.value}
												onChange={(values: Array<number>) => {
													form.setFieldValue(field.name, values);
													const ranges = (
														values.map(
															(item: number) =>
																options.dates.find(
																	(date: OdesseusSelectOption<Range<number>>) => date.value === item,
																)?.raw,
														).filter((item: Range<number> | undefined) => item) as Array<Range<number>>
													).map((item: Range<number>) => ({
														from: OdyManager.toDateString(item.from),
														to: item.to ? OdyManager.toDateString(item.to) : undefined,
													}));
													setFilters(addOdesseusRanges(filters, OdesseusFilterKeys.DEPARTUREDATE, ranges));
													setLastChange(OdesseusFilterKeys.DEPARTUREDATE);
												}}
											>
												{
													options?.dates.map((option: OdesseusSelectOption<Range<number>>) => (
														<Option key={option.value} value={option.value}>
															{option.name}
														</Option>
													))
												}
											</Select>
										</div>
									)}
								</Field>
							</div>
						</div>

						<div className="button-container col-12 col-lg-6 col-xl-12 content-valign_center">
							<div className="layout-container reverse content-valign_top content-halign_center">
								<div className="col-12 col-xl-4">
									<Button
										className="uppercase"
										variant={ButtonVariant.Filled}
										onClick={formikBag.handleSubmit}
									>
										Search Cruises
									</Button>
								</div>
								<div className="col-12 col-xl-4 btn-reset">
									<Button
										variant={ButtonVariant.Underlined}
										action={ActionMode.Button}
										onClick={() => {
											formikBag.resetForm();
											setFilters([]);
											setLastChange(null);
										}}
									>
										Reset All
									</Button>
								</div>
							</div>
						</div>
					</Form>
				)
			}
		</Formik>
	);
};
