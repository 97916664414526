export const RoutePaths = {
	mainPage: '/',
	notFound: '*',

	packagesSearchPage: '/search',
	cruiseSearchPage: '/search-cruises',

	moreDetailsPage: '/more-details',

	generalTerms: '/general-terms',
	privacyPolicy: '/privacy-policy',
	whyOvc: '/why-ovc',
	passportRequirements: '/passport-requirements',
	faqs: '/faqs',
	managers: '/managers',
	investors: '/investors',
	travelInsurance: '/travel-insurance',
	aboutPage: '/about',
	careers: '/careers',

	contactPage: '/contact',
	requestQuotePage: '/request-quote',
	friendInvite: '/friend-invite',
	friendForward: '/FriendForward',
	subscribe: '/subscribe',
	subscribeSettings: '/subscribe-settings',
	valueInclusivePages: '/ValueInclusive',
	airGateway: '/AirGateway',
	booking: '/Booking',
	bookingForm: '/BookingForm',
};
