import * as React from 'react';

import Slider, { Settings, ResponsiveObject } from 'react-slick';

import '@app/scss/components/carousel.scss';
import '@app/scss/pages/main/feedback.scss';

import { Pagination } from '@app/components/Pagination/Pagination';
import { FEATURED_DEAL } from '@app/pages/MainPage/FeaturedDeals/FeaturedDeals';

export interface CarouselProps {
	children?: Array<React.ReactElement>;
	className?: string;
	total: number;
	settings?: Settings;

	cardRef?: React.RefObject<HTMLDivElement>;
}

const defaultSettings: Settings = {
	className: 'slider variable-width',
	variableWidth: true,
	infinite: true, // Boolean to determine if the carousel continues in a loop when the last item is reached
	speed: 1500,
	slidesToShow: 3, // number to determine the number of slides to keep in view
	slidesToScroll: 3, // number to determine the number of slides to move when navigating the carousel
	arrows: false,
};

const getCount = (width: number, id: string, cardWidth: number) => {
	const containerWidth = window.innerWidth >= 1440 && id === FEATURED_DEAL ? width - cardWidth : window.innerWidth;
	const count = Math.floor(containerWidth / cardWidth);

	return { count, containerWidth };
};

const get = (list: Array<ResponsiveObject>, width: number): ResponsiveObject | null => {
	const ordered = [...list].sort((a: ResponsiveObject, b: ResponsiveObject) => a.breakpoint - b.breakpoint);
	for (let i = 0; i < ordered.length; i++) {
		const cur = ordered[i];

		if (cur.breakpoint >= width) return cur;
	}

	return ordered[ordered.length - 1];
};

const getSetting = (setting: Settings, width?: number, cardRef?: React.RefObject<HTMLDivElement>) => {
	if (!cardRef || !cardRef.current || !width) return setting;

	const { count, containerWidth } = getCount(width, cardRef.current.id, cardRef.current?.clientWidth);

	if (containerWidth <= 480) {
		return {
			...setting,
			centerMode: true,
			slidesToShow: 1,
			slidesToScroll: 1,
		};
	}

	return {
		...setting,
		slidesToShow: count,
		slidesToScroll: count,
		centerMode: count === 1,
	};
};

export const Carousel: React.FC<CarouselProps> = (props: CarouselProps) => {
	const settings = props.settings ?? defaultSettings;

	const [sliderRef, setSliderRef] = React.useState<Slider | null>(null);
	const [page, setPage] = React.useState<number>(1);
	const [count, setCount] = React.useState<number>(1);
	const [isInfinite, setIsInfinite] = React.useState<boolean | undefined>(settings.infinite);

	const resize = () => {
		if (settings.responsive !== undefined) {
			const current = get(settings.responsive, window.innerWidth)?.settings as Settings;
			if (current.slidesToScroll !== undefined) setCount(current.slidesToScroll);

			const infinite = current.infinite;
			if (infinite !== undefined) setIsInfinite(infinite);
		}

		if (!props.cardRef || !props.cardRef.current) return;
		const { count } = getCount(
			sliderRef?.innerSlider?.list?.clientWidth ?? window.innerWidth,
			props.cardRef.current.id,
			props.cardRef.current?.clientWidth,
		);

		setCount(count);
	};

	React.useEffect(() => {
		window.addEventListener('resize', resize);
		resize();

		return () => window.removeEventListener('resize', resize);
	}, []);

	return (
		<div
			className="slider-container"
		>
			<Slider
				{...getSetting(settings, sliderRef?.innerSlider?.list?.clientWidth, props.cardRef)}
				className={props.className}
				ref={setSliderRef}
				afterChange={(item: number) => {
					if (count === 1) setPage(item);
					setPage(Math.ceil((item + 1) / count));
				}}
			>
				{props.children}
			</Slider>
			<Pagination
				className="carousel__pagination"
				goToNext={sliderRef?.slickNext}
				goToPrev={sliderRef?.slickPrev}
				infinite={isInfinite}
				total={props.total}
				current={page}
				pageSize={count}
			/>
		</div>
	);
};
