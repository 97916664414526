import * as React from 'react';

import { Rows } from '@app/objects/Rows';
import { DataEnvelope } from '@app/hooks/data/useData';

export type FetchMoreHandler = (event: React.UIEvent) => void;

function shouldFetch<T>(data: DataEnvelope<Rows<T>>): boolean {
	if (data.loading) return false;
	if (data.item === null) return false;
	if (data.item.list === null) return false;

	return data.item.list.length < data.item.total;
}

export function useLoadMore<T>(data: DataEnvelope<Rows<T>>, params: URL): FetchMoreHandler {
	return (event: React.UIEvent) => {
		if (!shouldFetch(data)) return;

		const target = event.target as HTMLElement;
		const blockHeight = target.offsetHeight;
		const position = target.scrollTop;
		const scrolled = target.scrollHeight;

		const offset = (data.item?.list?.length ?? 0);
		params.searchParams.set('offset', offset.toFixed(0));

		if ((position + blockHeight) === scrolled) data.load(params.toString());
	};
}
