import * as React from 'react';

import { SearchListCard } from '@app/components/Cards/SearchListCard/SearchListCard';
import { Pagination } from '@app/components/Pagination/Pagination';

import { Nullable } from '@app/objects/Utility';
import { Loader } from '@app/components/Loader/Loader';

import { DataNotFound } from '@app/components/Message/DataNotFound';
import { List, ListItem } from '@app/objects/List';
import { Sorter } from '@app/components/Sorter/Sorter';
import { sorterOptions } from '@app/pages/SearchPage/SorterOptions';

import '@app/scss/pages/search/searchList.scss';
import 'rc-dropdown/assets/index.css';

interface SearchListProps {
	items: Nullable<List>;

	loading: boolean;

	currentPage: number;
	onChangePage: (page: number) => void;

	sorter: number;
	onChangeSorter: (value: number) => void;

	open: boolean;
	onClose: (value: boolean) => void;
}

const message = 'Sorry! Nothing was found for your query. Change the request parameters and try again.';

export const SearchList: React.FC<SearchListProps> = (props: SearchListProps) => {
	const list = props.items?.list;

	if (props.loading) return <Loader />;
	if (!props.items) return null;
	if (!list?.length) return <DataNotFound message={message} className="col-12 col-md-9" />;

	const pageSize = props.items.pageSize ?? 10;
	const pageStart = props.items.pageStart ?? props.currentPage;

	return (
		<div className="search-list col-12 col-md-9">
			<div className="search-list__counter-block font-family_primary font-weight_bold text-size_medium">
				<ul className="search-list__counter-list">
					<li>
						<b>{props.items?.total.items} </b>
						<span className="font-weight_regular">Itineraries Found</span>
					</li>
					<Sorter
						options={sorterOptions}
						value={props.sorter}
						onChange={props.onChangeSorter}

						open={props.open}
						onClose={props.onClose}
					/>
				</ul>
			</div>

			<div className="search-list__container">
				{list?.map((item: ListItem) => <SearchListCard key={item.id} {...item} />)}
			</div>
			<Pagination
				total={props.items.total.items}
				pageSize={pageSize}
				current={pageStart}
				className="content-valign_center"
				goToNext={() => {
					props.onChangePage(pageStart + 1);
				}}
				goToPrev={() => {
					props.onChangePage(pageStart - 1);
				}}
			/>
		</div>
	);
};
