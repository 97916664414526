import * as React from 'react';

import {
	IManager,
	INTRO, managerList,
} from '@app/pages/StaticPages/Managers/managers-text';
import { useDimensions } from '@app/hooks/useDimensions';
import { Anchor } from '@app/components/Anchor/Anchor';
import { Anchors } from '@app/objects/Anchors';

import '@app/scss/pages/documents-pages.scss';

const baseUrl = 'https://www.onlinevacationcenter.com';

export const Managers: React.FC = () => {
	const ref = React.useRef<HTMLElement>(null);
	const dimensions = useDimensions(ref);

	return (
		<Anchor.Tag anchor={Anchors.Subscribe}>
			<div className="documentation managers font-family_primary font-weight_regular text-size_small">
				<h1 className="documentation__title text-size_soft-large content-valign_center">
					Meet Our Personal Vacation Managers
				</h1>
				<p>{INTRO}</p>

				<ul className="managers__list">
					{managerList.map((item: IManager) => (
						<li key={item.name}>
							<div className="manager-image">
								<img src={`${baseUrl}${item.photo}`} alt="Manager" />
							</div>

							<div>
								<h2 className="font-weight_bold managers__content-title">{item.name}</h2>
								<div className="text-margin managers__text-content">
									<p>
										<span className="font-weight_bold">Education: </span>
										{item.education}
									</p>
									<p>
										<span className="font-weight_bold">Travel Industry Experience: </span>
										{item.experience}
									</p>
									{item.languages && (
										<p>
											<span className="font-weight_bold">Additional Languages: </span>
											{item.languages}
										</p>
									)}
								</div>

								<p className="text-size_extra-small">{item.description}</p>
							</div>
						</li>
					))}
				</ul>

				<Anchor.Link
					anchor={Anchors.Subscribe}
					className="col-12 content-halign_center text-size_small"
					offset={dimensions.height}
				>
					Top of Page
				</Anchor.Link>
			</div>
		</Anchor.Tag>
	);
};
