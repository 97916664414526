import { ISearchPackagesURLTraverser } from '@app/services/searchPackages/SearchPackagesURLTraverser';
import { PackagesFormValues, PackagesFilterKeys } from '@app/objects/Filters/Packages';
import { isArray, isNumber } from '@app/objects/Utility';

export class ListURLTraverser implements ISearchPackagesURLTraverser {
	private readonly pageSize: number;

	constructor(pageSize: number) {
		this.pageSize = pageSize;
	}

	filter<TKey extends keyof PackagesFormValues>(key: TKey, value: PackagesFormValues[TKey]): boolean {
		return !isArray(value) || Boolean(value.length);
	}

	map<TKey extends keyof PackagesFormValues>(key: TKey, value: PackagesFormValues[TKey]): string {
		if (key === PackagesFilterKeys.PAGE) {
			const page = isNumber(value) ? value : 1;
			const offset = (page - 1) * this.pageSize;

			return `offset=${offset}&count=${this.pageSize}`;
		}

		if (!isArray(value)) {
			return `${key}=${value}`;
		}

		return `${key}=${value.join(',')}`;
	}
}
