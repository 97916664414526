import * as React from 'react';

import '@app/scss/components/tag.scss';
import clsx from 'clsx';

export interface TagProps {
	children: React.ReactNode;
	style?: React.CSSProperties;
	className?: string;
}

export const Tag: React.FC<TagProps> = (props: TagProps) => (
	<div className={clsx('tag font-family_primary condensed font-weight_bold', props.className)} style={props.style}>
		{props.children}
	</div>
);
