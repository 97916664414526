import * as React from 'react';

import clsx from 'clsx';

import { TIcon } from '@app/objects/Utility';
import '@app/scss/components/bullet.scss';

export interface BulletProps {
	Icon: TIcon;
	title: string;
	description: string;
	className?: string;
}

export const Bullet: React.FC<BulletProps> = (props: BulletProps) => {
	const {
		title,
		description,
		className,
		Icon,
	} = props;

	return (
		<div className={clsx('bullet-container', className)}>
			<Icon />
			<h4 className="bullet-container__title font-family_primary condensed font-weight_bold text-size_medium">{title}</h4>
			<p className="bullet-container__description font-family_primary font-weight_regular text-size_extra-small line-height_large">
				{description}
			</p>
		</div>
	);
};
