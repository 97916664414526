import React from 'react';

import quote1 from '../../../../image/valueinclusive/quote-left.svg';

export const ClientFeedback: React.FC = () => {
	return (
		<div className="client-feedback">
			<div className="testimonial-hed">
				<h1>
					<span className="font-w">WHAT OUR</span>
					<span className="font-style"> Clients</span>
					<span className="font-w"> ARE</span>
					<span className="font-style"> Saying</span>
				</h1>
				<img src={quote1} alt="" />
			</div>
			<div className="feedback__container">
				<div
					className="trustpilot-widget"
					data-locale="en-US"
					data-template-id="53aa8912dec7e10d38f59f36"
					data-businessunit-id="6037f8ef3217ab0001f8e69e"
					data-style-height="300px"
					data-style-width="100%"
					data-theme="light"
					data-tags="vip"
					data-stars="4,5"
					data-review-languages="en"
					data-font-family="Fira Sans"
					data-text-color="#090e2c"
				>
					<a
						href="https://www.trustpilot.com/review/onlinevacationcenter.com"
						target="_blank"
						rel="noopener noreferrer"
					>
						Trustpilot Reviews
					</a>
				</div>
			</div>
		</div>
	);
};
