import * as React from 'react';
import { Link } from 'react-router-dom';

import {
	alreadyBookList,
	beforeBookList,
	customerPortalList,
	inclusivePackagesList,
	otherQuestionsList,
} from '@app/pages/StaticPages/FAQ/text';
import { DropList } from '@app/components/DropList/DropList';

import '@app/scss/pages/documents-pages.scss';

export const FAQ = () => {
	return (
		<div className="documentation faq font-family_primary font-weight_regular text-size_extra-small">
			<h1 className="documentation__title text-size_soft-large content-valign_center">
				Frequently Asked Questions
			</h1>

			<DropList title="BEFORE YOU BOOK" items={beforeBookList} />
			<DropList title="ALREADY BOOKED" items={alreadyBookList} />
			<DropList title="CUSTOMER PORTAL" items={customerPortalList} />
			<DropList title="VALUE INCLUSIVE PACKAGES" items={inclusivePackagesList} />
			<DropList title="OTHER QUESTIONS" items={otherQuestionsList} />

			<p>
				View Online Vacation Center's General Terms and Conditions:
				<Link to="/general-terms"> Click Here</Link>
			</p>
		</div>
	);
};
